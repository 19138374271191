import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../../store/actions';
import * as reducers from '../../../store/reducers';
import { CustomerExtractsType } from '../../../types';
import { getGenetik } from '../../../utils/getGenetik';
import { getImageUrl } from '../../../utils/getImageUrl';

import './ExtractsTable.scss';

import DeleteIcon from '../../../images/symbols/delete.svg';
import CloseIcon from '../../../images/symbols/close.svg';
import ExtractIcon from '../../../images/extract-icon.svg';
import { ReactComponent as DropdownIcon } from '../../../images/symbols/picture-green-dropdown.svg';
import Pagination from '../Pagination';
import Modal from '../Modal';

interface Props {
  view: string;
  switchButtons: string;
}

const ExtractsTable: React.FC<Props> = ({
  view,
  switchButtons,
}): JSX.Element => {
  const dispatch: any = useDispatch();
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [tableView, setTableView] = useState(view);
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);
  const [isAll, setIsAll] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');

  const [removeExtractPopupInfo, setRemoveExtractPopupInfo] = useState({
    id: 0,
    item: '',
    isOpen: false,
  });
  const [removeExtractsPopupInfo, setRemoveExtractsPopupInfo] = useState(false);
  const [selectedExtracts, setSelectedExtracts] = useState<number[]>([]);

  const getExtracts = useCallback(
    (start, limit, searchQuery) =>
      dispatch(
        actions.request.customersActions.extracts.getExtracts.get(
          start,
          limit,
          searchQuery,
        ),
      ),
    [dispatch],
  );
  const deleteExtractsID = useCallback(
    (id) =>
      dispatch(
        actions.request.customersActions.extracts.deleteExtractsID.delete(id),
      ),
    [dispatch],
  );
  const deleteExtracts = useCallback(
    (data) =>
      dispatch(
        actions.request.customersActions.extracts.deleteExtracts.delete(data),
      ),
    [dispatch],
  );

  const extracts = useSelector(
    reducers.request.customersReducers.extractsState.get.value,
  );
  const extractsTotal = useSelector(
    reducers.request.customersReducers.extractsState.get.total,
  );

  useEffect(() => {
    getExtracts(start, limit, searchQuery);
  }, [start, limit, searchQuery]);

  useEffect(() => {
    setStart(currentPage * limit - limit);
  }, [currentPage]);

  useEffect(() => {
    const limit = localStorage.getItem(`extracts_${tableView}_limit`);
    if (!limit) {
      setLimit(tableView === 'table' ? 10 : 9);
      setIsAll(false);
    } else if (limit === 'all') {
      setLimit(extractsTotal);
      setIsAll(true);
    } else {
      setLimit(Number(limit));
      setIsAll(false);
    }
  }, [extractsTotal, tableView]);

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSelect = (event: any) => {
    const { value } = event.target;
    localStorage.setItem(`extracts_${tableView}_limit`, value);
    if (value === 'all') {
      setLimit(extractsTotal);
      setIsAll(true);
    } else {
      setLimit(value);
      setIsAll(false);
    }
  };

  const handleInput = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const onToggleSelectedExtracts = (id: number) => {
    if (selectedExtracts.includes(id)) {
      setSelectedExtracts(selectedExtracts.filter((d: number) => d !== id));
    } else {
      setSelectedExtracts([id, ...selectedExtracts]);
    }
  };

  const tableViewRedirectFactory = (id: number) => {
    return (event: any) => {
      if (event.target.tagName === 'TD')
        history.push(`/customer/extracts/${id}`);
    };
  };

  const listViewRedirectFactory = (id: number) => {
    return (event: any) => {
      /*if (event.target.tagName === 'DIV')*/
      history.push(`/customer/extracts/${id}`);
    };
  };

  return (
    <div className='customer-extracts__table-wrapper'>
      <div
        className={`customer-extracts__table-switch customer-extracts__table-switch--${switchButtons}`}
      >
        <div
          className='customer-extracts__table-switch-button customer-extracts__table-switch-button--list'
          onClick={() => {
            setTableView('list');
            localStorage.setItem('extractsTableView', 'list');
            setIsAll(false);
          }}
        >
          <div
            className={`customer-extracts__table-switch-button-square ${
              tableView === 'list' &&
              'customer-extracts__table-switch-button-square--active'
            }`}
          />
          <div
            className={`customer-extracts__table-switch-button-square ${
              tableView === 'list' &&
              'customer-extracts__table-switch-button-square--active'
            }`}
          />
          <div
            className={`customer-extracts__table-switch-button-square ${
              tableView === 'list' &&
              'customer-extracts__table-switch-button-square--active'
            }`}
          />
          <div
            className={`customer-extracts__table-switch-button-square ${
              tableView === 'list' &&
              'customer-extracts__table-switch-button-square--active'
            }`}
          />
        </div>
        <div
          className='customer-extracts__table-switch-button customer-extracts__table-switch-button--table'
          onClick={() => {
            setTableView('table');
            localStorage.setItem('extractsTableView', 'table');
            setIsAll(false);
          }}
        >
          <div
            className={`customer-extracts__table-switch-button-line ${
              tableView === 'table' &&
              'customer-extracts__table-switch-button-line--active'
            }`}
          />
          <div
            className={`customer-extracts__table-switch-button-line ${
              tableView === 'table' &&
              'customer-extracts__table-switch-button-line--active'
            }`}
          />
          <div
            className={`customer-extracts__table-switch-button-line ${
              tableView === 'table' &&
              'customer-extracts__table-switch-button-line--active'
            }`}
          />
          <div
            className={`customer-extracts__table-switch-button-line ${
              tableView === 'table' &&
              'customer-extracts__table-switch-button-line--active'
            }`}
          />
        </div>
      </div>
      <div
        className={`customer-extracts__table-filter customer-extracts__table-filter--${switchButtons}`}
      >
        <div className='customer-extracts__table-select-wrapper'>
          <label
            htmlFor='customer-extracts-number'
            className='customer-extracts__table-select-label'
          >
            Einträge anzeigen:
          </label>
          <select
            name='customer-extracts-number'
            id='customer-extracts-number'
            className='customer-extracts__table-select'
            value={isAll ? 'all' : limit}
            onChange={handleSelect}
          >
            <option value={tableView === 'table' ? 5 : 6}>
              {tableView === 'table' ? 5 : 6}
            </option>
            <option value={tableView === 'table' ? 10 : 9}>
              {tableView === 'table' ? 10 : 9}
            </option>
            <option value={tableView === 'table' ? 15 : 12}>
              {tableView === 'table' ? 15 : 12}
            </option>
            <option value={tableView === 'table' ? 20 : 15}>
              {tableView === 'table' ? 20 : 15}
            </option>
            {tableView === 'table' && <option value='all'>Alles</option>}
          </select>
        </div>
        <form
          action='#'
          className='customer-extracts__table-search'
          onSubmit={(event) => event.preventDefault()}
        >
          <div className='customer-extracts__table-field'>
            <input
              type='text'
              placeholder='Suchen'
              className='customer-extracts__table-input'
              value={searchQuery}
              onChange={handleInput}
            />
          </div>
        </form>
      </div>
      {tableView === 'table' && (
        <table className='customer-extracts__table'>
          <thead>
            <tr>
              <th />
              <th className='customer-extracts__table-title'>Extrakt</th>
              <th className='customer-extracts__table-title'>
                Sorte / Genetik
              </th>
              <th className='customer-extracts__table-title'>TCH / CBD</th>
              <th className='customer-extracts__table-title'>Hersteller</th>
              <th className='customer-extracts__table-title'>Status</th>
              <th className='customer-extracts__table-title'>Bild</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {extracts.map((extract: CustomerExtractsType, index: number) => (
              <tr
                key={extract.id}
                className='customer-extracts__table-row'
                onClick={tableViewRedirectFactory(extract.id)}
              >
                <td className='customer-extracts__table-row-index'>
                  <input
                    checked={selectedExtracts.includes(extract.id)}
                    type='checkbox'
                    className='customer-extracts__table-row-checkbox'
                    onChange={() => onToggleSelectedExtracts(extract.id)}
                  />
                  {index + 1 + start}.
                </td>
                <td className='customer-extracts__table-row-item'>
                  {extract.name}
                </td>
                <td className='customer-extracts__table-row-item'>
                  {extract.kultivar || 'k.A.'} / {getGenetik(extract.genetik)}
                </td>
                <td className='customer-extracts__table-row-item'>
                  {`${
                    extract.isConcentration ? `1 - ${extract.thc}` : extract.thc
                  } / ${
                    extract.isConcentration ? `1 - ${extract.cbd}` : extract.cbd
                  } ${extract.unit}`}
                </td>
                <td className='customer-extracts__table-row-item'>
                  {extract.companyName}
                </td>
                <td
                  className={`customer-extracts__table-row-item  ${
                    extract.active
                      ? 'customer-extracts__table-row-item--active'
                      : 'customer-extracts__table-row-item--inactive'
                  }`}
                >
                  {extract.active ? 'Aktiv' : 'Inaktiv'}
                </td>
                <td className='customer-extracts__table-row-item customer-extracts__table-row-item--picture'>
                  <DropdownIcon />
                  <div className='customer-extracts__table-row-item-popup'>
                    <img
                      src={getImageUrl(extract.image) || ExtractIcon}
                      alt=''
                    />
                    <div className='triangle' />
                  </div>
                </td>
                <td className='customer-extracts__table-row-button'>
                  <button
                    className='button'
                    onClick={() => {
                      setRemoveExtractPopupInfo({
                        id: extract.id,
                        item: extract.name,
                        isOpen: true,
                      });
                    }}
                  >
                    <img src={DeleteIcon} alt='delete' />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {tableView === 'list' && (
        <div className='customer-extracts__list'>
          {extracts.map((extract: CustomerExtractsType) => (
            <div
              className='customer-extracts__list-item'
              key={extract.id}
              onClick={listViewRedirectFactory(extract.id)}
            >
              <div className='customer-extracts__list-image-wrapper'>
                <input
                  checked={selectedExtracts.includes(extract.id)}
                  type='checkbox'
                  className='customer-extracts__list-item-checkbox'
                  onChange={() => onToggleSelectedExtracts(extract.id)}
                />
                <div className='customer-extracts__list-image-head'>
                  <img
                    src={getImageUrl(extract.companyImage)}
                    alt=''
                    className='customer-extracts__list-image-logo'
                  />
                  <p className='customer-extracts__list-image-text'>
                    <span>{extract.name}</span>
                    <br />
                    {extract.companyName}
                  </p>
                </div>
                <img
                  src={getImageUrl(extract.image) || ExtractIcon}
                  alt=''
                  className='customer-extracts__list-image'
                />
                <div className='customer-extracts__list-image-description'>
                  <span>{extract.kultivar || 'k.A.'}</span>
                  <br />
                  <p>{getGenetik(extract.genetik)}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {!isAll && (
        <Pagination
          itemsPerPage={limit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalItems={extractsTotal || 1}
          paginate={paginate}
        />
      )}

      {!!selectedExtracts.length && (
        <div className='customer-extracts__table-buttons'>
          <button
            className='button button--dark customer-extracts__table-button'
            onClick={() => setRemoveExtractsPopupInfo(true)}
          >
            Ausgewählte löschen {selectedExtracts.length}
          </button>
        </div>
      )}

      {removeExtractPopupInfo.isOpen && (
        <Modal>
          <div className='customer-extracts__table-modal'>
            <button
              className='customer-extracts__table-modal-close'
              onClick={() =>
                setRemoveExtractPopupInfo({ id: 0, item: '', isOpen: false })
              }
            >
              <img src={CloseIcon} alt='close' />
            </button>
            <p className='customer-extracts__table-modal-title'>
              Sorte löschen?
            </p>
            <p className='customer-extracts__table-modal-paragraph'>
              Sie haben vor, den Extract "{removeExtractPopupInfo.item}" zu
              löschen.
            </p>
            <p className='customer-extracts__table-modal-paragraph'>
              Diese Änderungen kann nicht rückgängig gemacht werden.
            </p>
            <div className='customer-extracts__table-modal-buttons'>
              <button
                className='button customer-extracts__table-modal-button customer-extracts__table-modal-button--delete'
                onClick={() =>
                  deleteExtractsID(removeExtractPopupInfo.id).then(() => {
                    setRemoveExtractPopupInfo({
                      id: 0,
                      item: '',
                      isOpen: false,
                    });
                    getExtracts(start, limit, searchQuery);
                  })
                }
              >
                Löschen
              </button>
              <button
                className='button customer-extracts__table-modal-button customer-extracts__table-modal-button--cancel'
                onClick={() =>
                  setRemoveExtractPopupInfo({ id: 0, item: '', isOpen: false })
                }
              >
                Abbrechen
              </button>
            </div>
          </div>
        </Modal>
      )}

      {removeExtractsPopupInfo && (
        <Modal>
          <div className='customer-extracts__table-modal'>
            <button
              className='customer-extracts__table-modal-close'
              onClick={() => setRemoveExtractsPopupInfo(false)}
            >
              <img src={CloseIcon} alt='close' />
            </button>
            <p className='customer-extracts__table-modal-title'>
              {selectedExtracts.length} Extracts löschen?
            </p>
            <p className='customer-extracts__table-modal-paragraph'>
              Sie haben vor, {selectedExtracts.length} ausgewählte Extracte zu
              löschen.
            </p>
            <p className='customer-extracts__table-modal-paragraph'>
              Nach dem Entfernen können Sie diese Änderungen nicht mehr
              rückgängig machen.
            </p>
            <div className='customer-extracts__table-modal-buttons'>
              <button
                className='button customer-extracts__table-modal-button customer-extracts__table-modal-button--delete'
                onClick={() =>
                  deleteExtracts({ extractsIds: selectedExtracts }).then(() => {
                    setRemoveExtractsPopupInfo(false);
                    setSelectedExtracts([]);
                    getExtracts(start, limit, searchQuery);
                  })
                }
              >
                Löschen
              </button>
              <button
                className='button customer-extracts__table-modal-button customer-extracts__table-modal-button--cancel'
                onClick={() => setRemoveExtractsPopupInfo(false)}
              >
                Abbrechen
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ExtractsTable;
