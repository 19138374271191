import React from 'react';

import './ExtractsHead.scss';

interface Props {
  buttons: {
    title: string;
    onClick: any;
  }[];
}

const ExtractsHead: React.FC<Props> = ({ buttons }): JSX.Element => {
  return (
    <div className='customer-extracts__head'>
      <h1 className='customer-extracts__title'>Extrakte</h1>
      <div className='customer-extracts__buttons'>
        {buttons.map((button, index) => (
          <button
            className='button button--green customer-extracts__button'
            key={`${button}${index}`}
            type='button'
            onClick={button.onClick}
          >
            {button.title}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ExtractsHead;
