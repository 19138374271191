import * as api from '../../../../api';
import * as types from '../../../types/request';

export const login = {
  post: function (data: {}) {
    return (dispatch: any) => {
      dispatch(this.postPending());

      return api.authApi
        .login(data)
        .then((response) => {
          if (response.status === 201) {
            return response.json().then((json) => {
              localStorage.setItem('accessToken', json.accessToken);
              localStorage.setItem('refreshToken', json.refreshToken);
              dispatch(this.postSuccess(json));
            });
          } else {
            return response.json().then((json) => {
              dispatch(this.postFailed(json));
            });
          }
        })
        .catch((error) => {
          dispatch(this.postFailed(error));
        });
    };
  },

  postSuccess: (response: any) => ({
    type: types.authTypes.AUTH_LOGIN_SUCCESS,
    payload: response,
  }),

  postFailed: (error: any) => ({
    type: types.authTypes.AUTH_LOGIN_FAILED,
    payload: error,
  }),

  postPending: () => ({
    type: types.authTypes.AUTH_LOGIN_PENDING,
  }),
};
