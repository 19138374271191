const baseURL = process.env.REACT_APP_BASE_URL;

export const dosagesApi = {
  putDosages: (type: string, data: { dosagesIds: number[] }) => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/customers/dosages?type=${type}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify(data),
    });
  },

  deleteDosagesID: (id: number) => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/customers/dosages/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getDosages: (type: string, search?: string) => {
    const token = localStorage.getItem('accessToken');
    const searchParam = search ? `&search=${search}` : '';

    return fetch(`${baseURL}/customers/dosages?type=${type}${searchParam}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};
