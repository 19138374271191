import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../../store/actions';
import * as reducers from '../../../store/reducers';

import SettingsEdit from '../SettingsEdit';

import './Settings.scss';

import ArrowDown from '../../../images/symbols/next-page.svg';
import SettingsTabs from '../SettingsTabs';
import SettingsPrescription from '../SettingsPrescription';

const Settings: React.FC = (): JSX.Element => {
  const dispatch: any = useDispatch();
  const history = useHistory();

  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  const [dataItem, setDataItem] = useState({
    email: '',
    company: '',
    bookkeepingName: '',
    bookkeepingEmail: '',
    bookkeepingPhone: '',
    technicalName: '',
    technicalEmail: '',
    technicalPhone: '',
    image: '',
    color: '',
    css: '',
    doctorsToken: '',
  });

  const [tabNumber, setTabNumber] = useState(0);

  const location = useLocation<typeof dataItem>();

  const getInfo = useCallback(
    () =>
      dispatch(
        actions.request.customersActions.customers.getCustomersInfo.get(),
      ),
    [dispatch],
  );
  const getCustomer = useCallback(
    () =>
      dispatch(actions.request.customersActions.customers.getCustomers.get()),
    [dispatch],
  );
  const putCustomer = useCallback(
    (data) =>
      dispatch(
        actions.request.customersActions.customers.putCustomers.put(data),
      ),
    [dispatch],
  );

  const customer = useSelector(
    reducers.request.customersReducers.customersState.get.value,
  );

  useEffect(() => {
    getCustomer();
  }, []);

  useEffect(() => {
    if (location.state) setDataItem(location.state);
    else setDataItem(customer);
  }, [customer]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    putCustomer(dataItem).then(() => getInfo());
  };

  return (
    <section className='customer-settings'>
      <div className='container'>
        <h1 className='customer-settings__title'>Einstellungen</h1>
        <SettingsTabs tabNumber={tabNumber} setTabNumber={setTabNumber} />
        {tabNumber === 0 && (
          <form className='customer-settings__info' onSubmit={handleSubmit}>
            <div className='customer-settings__info-row'>
              <p className='customer-settings__info-title'>
                Allgemeine Informationen
              </p>
              <div className='customer-settings__info-row-fields'>
                <div className='customer-settings__info-row-field'>
                  <label
                    className='customer-settings__info-row-label'
                    htmlFor='name'
                  >
                    Name
                  </label>
                  <input
                    type='text'
                    className='customer-settings__info-row-input'
                    id='name'
                    placeholder='Geben Sie Ihren Unternehmensnamen ein'
                    value={dataItem.company}
                    onChange={(event) => {
                      setDataItem({ ...dataItem, company: event.target.value });
                    }}
                  />
                </div>
                <div className='customer-settings__info-row-field'>
                  <label
                    className='customer-settings__info-row-label'
                    htmlFor='email'
                  >
                    E-Mail für die Anmeldung
                  </label>
                  <input
                    type='email'
                    className='customer-settings__info-row-input'
                    id='email'
                    placeholder='E-Mail für die Anmeldung eingeben'
                    value={dataItem.email}
                    onChange={(event) => {
                      setDataItem({ ...dataItem, email: event.target.value });
                    }}
                  />
                </div>
                <div className='customer-settings__info-row-field'>
                  {/* <button
                    className='button button--green customer-settings__edit'
                    type='button'
                    onClick={() => {
                      history.push('/customer/settings/edit', dataItem);
                    }}
                  >
                    Beschreibungstext bearbeiten
                  </button> */}
                </div>
              </div>
            </div>
            <div className='customer-settings__info-row'>
              <p className='customer-settings__info-title'>
                Ansprechpartner Buchhaltung
              </p>
              <div className='customer-settings__info-row-fields'>
                <div className='customer-settings__info-row-field'>
                  <label
                    className='customer-settings__info-row-label'
                    htmlFor='bookkeeping-name'
                  >
                    Name
                  </label>
                  <input
                    type='text'
                    className='customer-settings__info-row-input'
                    id='bookkeeping-name'
                    placeholder='Name für Rechnungen'
                    value={dataItem.bookkeepingName}
                    onChange={(event) => {
                      setDataItem({
                        ...dataItem,
                        bookkeepingName: event.target.value,
                      });
                    }}
                  />
                </div>
                <div className='customer-settings__info-row-field'>
                  <label
                    className='customer-settings__info-row-label'
                    htmlFor='bookkeeping-email'
                  >
                  E-Mail
                  </label>
                  <input
                    type='email'
                    className='customer-settings__info-row-input'
                    id='bookkeeping-email'
                    placeholder='E-Mail eingeben'
                    value={dataItem.bookkeepingEmail}
                    onChange={(event) => {
                      setDataItem({
                        ...dataItem,
                        bookkeepingEmail: event.target.value,
                      });
                    }}
                  />
                </div>
                <div className='customer-settings__info-row-field'>
                  <label
                    className='customer-settings__info-row-label'
                    htmlFor='bookkeeping-phone'
                  >
                    Telefon
                  </label>
                  <input
                    type='tel'
                    className='customer-settings__info-row-input'
                    id='bookkeeping-phone'
                    placeholder='Telefonnummer eingeben'
                    value={dataItem.bookkeepingPhone}
                    onChange={(event) => {
                      setDataItem({
                        ...dataItem,
                        bookkeepingPhone: event.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='customer-settings__info-row'>
              <p className='customer-settings__info-title'>
                Technischer Ansprechpartner
              </p>
              <div className='customer-settings__info-row-fields'>
                <div className='customer-settings__info-row-field'>
                  <label
                    className='customer-settings__info-row-label'
                    htmlFor='technical-name'
                  >
                    Name
                  </label>
                  <input
                    type='text'
                    className='customer-settings__info-row-input'
                    id='technical-name'
                    placeholder='Name des Ansprechpartners'
                    value={dataItem.technicalName}
                    onChange={(event) => {
                      setDataItem({
                        ...dataItem,
                        technicalName: event.target.value,
                      });
                    }}
                  />
                </div>
                <div className='customer-settings__info-row-field'>
                  <label
                    className='customer-settings__info-row-label'
                    htmlFor='technical-email'
                  >
                    Technischer Ansprechpartner
                  </label>
                  <input
                    type='email'
                    className='customer-settings__info-row-input'
                    id='technical-email'
                    placeholder='Email eingeben'
                    value={dataItem.technicalEmail}
                    onChange={(event) => {
                      setDataItem({
                        ...dataItem,
                        technicalEmail: event.target.value,
                      });
                    }}
                  />
                </div>
                <div className='customer-settings__info-row-field'>
                  <label
                    className='customer-settings__info-row-label'
                    htmlFor='technical-phone'
                  >
                    Telefon
                  </label>
                  <input
                    type='tel'
                    className='customer-settings__info-row-input'
                    id='technical-phone'
                    placeholder='Telefonnummer eingeben'
                    value={dataItem.technicalPhone}
                    onChange={(event) => {
                      setDataItem({
                        ...dataItem,
                        technicalPhone: event.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='customer-settings__info-row customer-settings__info-row--more'>
              <p
                className='customer-settings__info-title'
                onClick={() => {
                  isAdvancedOpen
                    ? setIsAdvancedOpen(false)
                    : setIsAdvancedOpen(true);
                }}
              >
                Erweiterte Einstellungen
              </p>
              <div
                className='customer-settings__info-row-arrow'
                onClick={() => {
                  isAdvancedOpen
                    ? setIsAdvancedOpen(false)
                    : setIsAdvancedOpen(true);
                }}
              >
                <img
                  src={ArrowDown}
                  alt='arrow-down'
                  className={isAdvancedOpen ? 'img-advanced-open' : ''}
                />
              </div>
            </div>
            {isAdvancedOpen && (
              <div className='customer-settings__info-advanced'>
                <label
                  className='customer-settings__info-row-label'
                  htmlFor='accent-color'
                >
                  Akzentfarbe
                </label>
                <input
                  type='color'
                  id='accent-color'
                  className='customer-settings__info-accent-color'
                  value={dataItem.color}
                  onChange={(event) => {
                    setDataItem({ ...dataItem, color: event.target.value });
                  }}
                />
                <label
                  htmlFor='custom-css'
                  className='customer-settings__info-row-label'
                >
                  Benutzerdefinierte CSS
                </label>
                <textarea
                  name='custom-css'
                  id='custom-css'
                  className='customer-settings__info-textarea'
                  value={dataItem.css}
                  onChange={(event) => {
                    setDataItem({ ...dataItem, css: event.target.value });
                  }}
                />
                <p className='customer-settings__info-row-label'>
                  Passworteinstellungen
                </p>
                <button
                  className='button button--green customer-settings__info-reset'
                  type='button'
                  onClick={() => history.push('/customer/change', dataItem)}
                >
                  Kennwort ändern
                </button>
                <p className='customer-settings__info-row-label'>Token</p>
                <div className='customer-settings__info-token'>
                  <p className='customer-settings__info-token-text'>
                    {dataItem.doctorsToken}
                  </p>
                  <div
                    className='customer-settings__info-token-copy'
                    onClick={() =>
                      navigator.clipboard.writeText(dataItem.doctorsToken)
                    }
                  >
                    Kopieren
                  </div>
                </div>
              </div>
            )}
            <button
              className='button button--green customer-settings__submit'
              type='submit'
            >
              Speichern
            </button>
          </form>
        )}
        {tabNumber === 1 && <SettingsEdit />}
        {tabNumber === 2 && <SettingsPrescription />}
      </div>
    </section>
  );
};

export default Settings;
