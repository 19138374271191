import * as API from '../../../../../api';
import * as types from '../../../../types/request';

export const postExtracts = {
  post: function (data: any) {
    return (dispatch: any) => {
      dispatch(this.postPending());

      return API.adminApi.extractsApi
        .postExtracts(data)
        .then((response: any) => {
          if (response.status === 201) {
            return response
              .json()
              .then((json: any) => dispatch(this.postSuccess(json)));
          } else {
            return response
              .json()
              .then((json: any) => dispatch(this.postFailed(json)));
          }
        })
        .catch((response: any) => {
          return dispatch(this.postFailed(response));
        });
    };
  },

  postSuccess: (response: any) => ({
    type: types.adminTypes.extracts.POST_EXTRACTS_SUCCESS,
    payload: response,
  }),

  postFailed: (response: any) => ({
    type: types.adminTypes.extracts.POST_EXTRACTS_FAILED,
    payload: response,
  }),

  postPending: () => ({
    type: types.adminTypes.extracts.POST_EXTRACTS_PENDING,
  }),
};
