import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../../store/actions';
import * as reducers from '../../../store/reducers';
import { getGenetik } from '../../../utils/getGenetik';

import './BudsTable.scss';

import DeleteIcon from '../../../images/symbols/delete.svg';
import CloseIcon from '../../../images/symbols/close.svg';
import Pagination from '../Pagination';
import Modal from '../Modal';

const BudsTable: React.FC = (): JSX.Element => {
  const dispatch: any = useDispatch();
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(9);

  const [searchQuery, setSearchQuery] = useState('');

  const [removeBudPopupInfo, setRemoveBudPopupInfo] = useState({
    id: 0,
    item: '',
    isOpen: false,
  });
  const [removeBudsPopupInfo, setRemoveBudsPopupInfo] = useState(false);
  const [selectedBuds, setSelectedBuds] = useState<number[]>([]);

  const getBudsID = useCallback(
    (id) => dispatch(actions.request.adminActions.buds.getBudsID.get(id)),
    [dispatch],
  );
  const getBuds = useCallback(
    (start, limit, search) =>
      dispatch(
        actions.request.adminActions.buds.getBuds.get(start, limit, search),
      ),
    [dispatch],
  );
  const postBuds = useCallback(
    (data) => dispatch(actions.request.adminActions.buds.postBuds.post(data)),
    [dispatch],
  );
  const deleteBudsID = useCallback(
    (id) => dispatch(actions.request.adminActions.buds.deleteBudsID.delete(id)),
    [dispatch],
  );
  const deleteBuds = useCallback(
    (data) =>
      dispatch(actions.request.adminActions.buds.deleteBuds.delete(data)),
    [dispatch],
  );

  const buds = useSelector(reducers.request.adminReducers.budsState.get.value);
  const budsTotal = useSelector(
    reducers.request.adminReducers.budsState.get.total,
  );

  useEffect(() => {
    getBuds(start, limit, searchQuery);
  }, [start, limit, searchQuery]);

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setStart(currentPage * limit - limit);
  }, [currentPage]);

  const handleSelect = (event: any) => {
    setLimit(event.target.value);
  };

  const handleInput = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const onToggleSelectedBuds = (id: number) => {
    if (selectedBuds.includes(id)) {
      setSelectedBuds(selectedBuds.filter((d: number) => d !== id));
    } else {
      setSelectedBuds([id, ...selectedBuds]);
    }
  };

  const redirect = (event: any) => {
    if (event.target.tagName === 'TD') {
      const path = `/admin/buds/${event.target.closest('tr').dataset.url}`;

      history.push(path);
    }
  };

  const duplicate = async (ids: number[]) => {
    const singleBudRequests = ids.map((id) => getBudsID(id));
    const budsResponses = await Promise.all(singleBudRequests);
    const budCreateRequests = budsResponses.map((response) => {
      const { id, ...budWithouId } = response.payload;
      return postBuds(budWithouId);
    });
    await Promise.all(budCreateRequests);
  };

  return (
    <div className='buds__table-wrapper'>
      <div className='buds__table-filter'>
        <div className='buds__table-select-wrapper'>
          <label htmlFor='buds-number' className='buds__table-select-label'>
            Einträge anzeigen:
          </label>
          <select
            name='buds-number'
            id='buds-number'
            className='buds__table-select'
            value={limit}
            onChange={handleSelect}
          >
            <option value='6'>6</option>
            <option value='9'>9</option>
            <option value='12'>12</option>
            <option value='15'>15</option>
          </select>
        </div>
        <form action='#' className='buds__table-search'>
          <div className='buds__table-field'>
            <input
              type='text'
              placeholder='Suchen'
              className='buds__table-input'
              value={searchQuery}
              onChange={handleInput}
            />
          </div>
        </form>
      </div>

      <table className='buds__table'>
        <thead>
          <tr>
            <th />
            <th className='buds__table-title'>Bud</th>
            <th className='buds__table-title'>Kultivar / Genetik</th>
            <th className='buds__table-title'>TCH / CBD (%)</th>
            <th className='buds__table-title'>Unternehmen</th>
            <th />
          </tr>
        </thead>

        <tbody>
          {buds.map((bud: any, index: number) => (
            <tr
              key={bud.id}
              className='buds__table-row'
              onClick={redirect}
              data-url={bud.id}
            >
              <td className='buds__table-row-index'>
                <input
                  checked={selectedBuds.includes(bud.id)}
                  type='checkbox'
                  className='buds__table-row-checkbox'
                  onChange={() => onToggleSelectedBuds(bud.id)}
                />
                {index + 1 + start}.
              </td>
              <td className='buds__table-row-item'>{bud.name}</td>
              <td className='buds__table-row-item'>
                {bud.kultivar || 'k.A.'} / {getGenetik(bud.genetik)}
              </td>
              <td className='buds__table-row-item'>
                {bud.thc} / {bud.cbd}
              </td>
              <td className='buds__table-row-item'>{bud.companyName}</td>
              <td className='buds__table-row-button'>
                <button
                  className='button'
                  onClick={() => {
                    setRemoveBudPopupInfo({
                      id: bud.id,
                      item: bud.name,
                      isOpen: true,
                    });
                  }}
                >
                  <img src={DeleteIcon} alt='delete' />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Pagination
        itemsPerPage={limit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalItems={budsTotal}
        paginate={paginate}
      />

      {!!selectedBuds.length && (
        <div className='buds__table-buttons'>
          <button
            className='button button--green buds__table-button'
            onClick={() => {
              duplicate(selectedBuds).then(() => {
                setSelectedBuds([]);
                getBuds(start, limit, searchQuery);
              });
            }}
          >
            Ausgewählte duplizieren {selectedBuds.length}
          </button>
          <button
            className='button button--dark buds__table-button'
            onClick={() => setRemoveBudsPopupInfo(true)}
          >
            Ausgewählte löschen {selectedBuds.length}
          </button>
        </div>
      )}

      {removeBudPopupInfo.isOpen && (
        <Modal>
          <div className='buds__table-modal'>
            <button
              className='buds__table-modal-close'
              onClick={() =>
                setRemoveBudPopupInfo({ id: 0, item: '', isOpen: false })
              }
            >
              <img src={CloseIcon} alt='close' />
            </button>
            <p className='buds__table-modal-title'>Sorte löschen?</p>
            <p className='buds__table-modal-paragraph'>
              Sie haben vor, den Bud "{removeBudPopupInfo.item}" zu löschen.
            </p>
            <p className='buds__table-modal-paragraph'>
              Diese Änderungen kann nicht rückgängig gemacht werden.
            </p>
            <div className='buds__table-modal-buttons'>
              <button
                className='button buds__table-modal-button buds__table-modal-button--delete'
                onClick={() =>
                  deleteBudsID(removeBudPopupInfo.id).then(() => {
                    setRemoveBudPopupInfo({ id: 0, item: '', isOpen: false });
                    getBuds(start, limit, searchQuery);
                  })
                }
              >
                Löschen
              </button>
              <button
                className='button buds__table-modal-button buds__table-modal-button--cancel'
                onClick={() =>
                  setRemoveBudPopupInfo({ id: 0, item: '', isOpen: false })
                }
              >
                Abbrechen
              </button>
            </div>
          </div>
        </Modal>
      )}

      {removeBudsPopupInfo && (
        <Modal>
          <div className='buds__table-modal'>
            <button
              className='buds__table-modal-close'
              onClick={() => setRemoveBudsPopupInfo(false)}
            >
              <img src={CloseIcon} alt='close' />
            </button>
            <p className='buds__table-modal-title'>
              {selectedBuds.length} Blüten löschen?
            </p>
            <p className='buds__table-modal-paragraph'>
              Sie haben vor, {selectedBuds.length} ausgewählte Blüten zu löschen.
            </p>
            <p className='buds__table-modal-paragraph'>
              Nach dem Entfernen können Sie diese Änderungen nicht mehr
              rückgängig machen.
            </p>
            <div className='buds__table-modal-buttons'>
              <button
                className='button buds__table-modal-button buds__table-modal-button--delete'
                onClick={() =>
                  deleteBuds({ budsIds: selectedBuds }).then(() => {
                    setRemoveBudsPopupInfo(false);
                    setSelectedBuds([]);
                    getBuds(start, limit, searchQuery);
                  })
                }
              >
                Löschen
              </button>
              <button
                className='button buds__table-modal-button buds__table-modal-button--cancel'
                onClick={() => setRemoveBudsPopupInfo(false)}
              >
                Abbrechen
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default BudsTable;
