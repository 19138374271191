import React, { useCallback } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as utils from '../utils';
import * as reducers from '../store/reducers';
import * as actions from '../store/actions';

interface Props {
  component?: any;
  role: string;
  [propName: string]: any;
}

export const PrivateRoute: React.FC<Props> = ({
  component: Component,
  role,
  children,
  ...rest
}) => {
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem('accessToken');

  const getInfo = useCallback(
    () =>
      dispatch(
        actions.request.customersActions.customers.getCustomersInfo.get(),
      ),
    [dispatch],
  );
  const info = useSelector(
    reducers.request.customersReducers.customersState.getInfo.value,
  );

  const render = (props: any) => {
    // If access token is expired or not exist redirect to login
    if (!(accessToken && utils.checkExpToken(accessToken)))
      return <Redirect to='/' />;

    const userRole = utils.checkRoleToken(accessToken);
    // If role is customer and info is not loaded do not render
    if (userRole === 'customer' && Object.keys(info).length === 0) {
      getInfo();
      return;
    }

    return role === userRole ? (
      children || <Component {...props} />
    ) : (
      <Redirect to={`/${userRole}`} />
    );
  };

  return <Route {...rest} render={render} />;
};
