import React from 'react';

import Header from '../components/login/Header';
import ForgotComponent from '../components/Forgot';

const Forgot: React.FC = (): JSX.Element => {
  return (
    <>
      <Header page='forgot' />
      <ForgotComponent />
    </>
  );
};

export default Forgot;
