import * as types from '../../../types/request';

const initialState = {
  getAll: {
    value: [],
    status: null,
    error: null,
  },

  getID: {
    value: {},
    status: null,
    error: null,
  },

  putID: {
    value: {},
    status: null,
    error: null,
  },

  deleteID: {
    status: null,
    error: null,
  },

  get: {
    value: [],
    total: 0,
    status: null,
    error: null,
  },

  delete: {
    status: null,
    error: null,
  },
};

export const extractsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    // ----- GET_EXTRACTS_ALL_SUCCESS
    case types.customersTypes.extracts.GET_EXTRACTS_ALL_SUCCESS:
      return {
        ...state,
        getAll: { ...state.getAll, status: 'success', value: action.payload },
      };
    case types.customersTypes.extracts.GET_EXTRACTS_ALL_FAILED:
      return {
        ...state,
        getAll: { ...state.getAll, status: 'failed', error: action.payload },
      };
    case types.customersTypes.extracts.GET_EXTRACTS_ALL_PENDING:
      return {
        ...state,
        getAll: { ...state.getAll, status: 'pending', error: null },
      };

    // ----- GET_EXTRACTS_ID_SUCCESS
    case types.customersTypes.extracts.GET_EXTRACTS_ID_SUCCESS:
      return {
        ...state,
        getID: { ...state.getID, status: 'success', value: action.payload },
      };
    case types.customersTypes.extracts.GET_EXTRACTS_ID_FAILED:
      return {
        ...state,
        getID: { ...state.getID, status: 'failed', error: action.payload },
      };
    case types.customersTypes.extracts.GET_EXTRACTS_ID_PENDING:
      return {
        ...state,
        getID: { ...state.getID, status: 'pending', error: null },
      };

    // ----- PUT_EXTRACTS_ID_SUCCESS
    case types.customersTypes.extracts.PUT_EXTRACTS_ID_SUCCESS:
      return {
        ...state,
        putID: { ...state.putID, status: 'success', value: action.payload },
      };
    case types.customersTypes.extracts.PUT_EXTRACTS_ID_FAILED:
      return {
        ...state,
        putID: { ...state.putID, status: 'failed', error: action.payload },
      };
    case types.customersTypes.extracts.PUT_EXTRACTS_ID_PENDING:
      return {
        ...state,
        putID: { ...state.putID, status: 'pending', error: null },
      };

    // ----- DELETE_EXTRACTS_ID_SUCCESS
    case types.customersTypes.extracts.DELETE_EXTRACTS_ID_SUCCESS:
      return { ...state, deleteID: { ...state.deleteID, status: 'success' } };
    case types.customersTypes.extracts.DELETE_EXTRACTS_ID_FAILED:
      return {
        ...state,
        deleteID: {
          ...state.deleteID,
          status: 'failed',
          error: action.payload,
        },
      };
    case types.customersTypes.extracts.DELETE_EXTRACTS_ID_PENDING:
      return {
        ...state,
        deleteID: { ...state.deleteID, status: 'pending', error: null },
      };

    // ----- GET_EXTRACTS_SUCCESS
    case types.customersTypes.extracts.GET_EXTRACTS_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          status: 'success',
          value: action.payload.extracts,
          total: action.payload.total,
        },
      };
    case types.customersTypes.extracts.GET_EXTRACTS_FAILED:
      return {
        ...state,
        get: { ...state.get, status: 'failed', error: action.payload },
      };
    case types.customersTypes.extracts.GET_EXTRACTS_PENDING:
      return {
        ...state,
        get: { ...state.get, status: 'pending', error: null },
      };

    // ----- DELETE_EXTRACTS_SUCCESS
    case types.customersTypes.extracts.DELETE_EXTRACTS_SUCCESS:
      return { ...state, delete: { ...state.delete, status: 'success' } };
    case types.customersTypes.extracts.DELETE_EXTRACTS_FAILED:
      return {
        ...state,
        delete: { ...state.delete, status: 'failed', error: action.payload },
      };
    case types.customersTypes.extracts.DELETE_EXTRACTS_PENDING:
      return {
        ...state,
        delete: { ...state.delete, status: 'pending', error: null },
      };

    default: {
      return state;
    }
  }
};

export const extractsState = {
  getAll: {
    value: (state: any) => state.requestData.customers.extracts.getAll.value,
    status: (state: any) => state.requestData.customers.extracts.getAll.status,
    error: (state: any) => state.requestData.customers.extracts.getAll.error,
  },

  getID: {
    value: (state: any) => state.requestData.customers.extracts.getID.value,
    status: (state: any) => state.requestData.customers.extracts.getID.status,
    error: (state: any) => state.requestData.customers.extracts.getID.error,
  },

  putID: {
    value: (state: any) => state.requestData.customers.extracts.putID.value,
    status: (state: any) => state.requestData.customers.extracts.putID.status,
    error: (state: any) => state.requestData.customers.extracts.putID.error,
  },

  deleteID: {
    status: (state: any) =>
      state.requestData.customers.extracts.deleteID.status,
    error: (state: any) => state.requestData.customers.extracts.deleteID.error,
  },

  get: {
    value: (state: any) => state.requestData.customers.extracts.get.value,
    total: (state: any) => state.requestData.customers.extracts.get.total,
    status: (state: any) => state.requestData.customers.extracts.get.status,
    error: (state: any) => state.requestData.customers.extracts.get.error,
  },

  delete: {
    status: (state: any) => state.requestData.customers.extracts.delete.status,
    error: (state: any) => state.requestData.customers.extracts.delete.error,
  },
};
