import * as interfaces from '../../interfaces';
const baseURL = process.env.REACT_APP_BASE_URL;

export const customersApi = {
  getCustomersID: (id: number) => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/admin/customers/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  putCustomersID: (id: number, data: interfaces.adminCustomersApi) => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/admin/customers/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify(data),
    });
  },

  deleteCustomersID: (id: number) => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/admin/customers/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getCustomers: (start: number, limit: number, search?: string) => {
    const token = localStorage.getItem('accessToken');
    const searchParam = search ? `&search=${search}` : '';

    return fetch(
      `${baseURL}/admin/customers?start=${start}&limit=${limit}${searchParam}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  postCustomers: (data: interfaces.adminCustomersApi) => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/admin/customers`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify(data),
    });
  },

  deleteCustomers: (data: { customersIds: number[] }) => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/admin/customers`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify(data),
    });
  },
};
