const baseURL = process.env.REACT_APP_BASE_URL;

export const statisticsApi = {
  getPrescriptionsByOutput: (interval: string) => {
    const token = localStorage.getItem('accessToken');

    return fetch(
      `${baseURL}/customers/statistics/prescriptions-by-output?interval=${interval}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  getPrescriptionsByAmount: (interval: string) => {
    const token = localStorage.getItem('accessToken');

    return fetch(
      `${baseURL}/customers/statistics/prescriptions-by-amount?interval=${interval}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  getProductsAndCount: (interval: string, type: string) => {
    const token = localStorage.getItem('accessToken');

    return fetch(
      `${baseURL}/customers/statistics/products-and-count?interval=${interval}&type=${type}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  getProductsAndAmount: (interval: string, type: string) => {
    const token = localStorage.getItem('accessToken');

    return fetch(
      `${baseURL}/customers/statistics/products-and-amount?interval=${interval}&type=${type}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },
};
