import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as reducers from '../../../store/reducers';
import * as actions from '../../../store/actions';
import { FormsType } from '../../../types';

import './BudsFormEdit.scss';

interface Props {
  id: number;
}

const BudsFormEdit: React.FC<Props> = ({ id }): JSX.Element => {
  const history = useHistory();
  const dispatch: any = useDispatch();

  const [fields, setFields] = useState({
    name: '',
    receipe: '',
  });

  const putFormsID = useCallback(
    (id, data) =>
      dispatch(actions.request.adminActions.forms.putFormsID.put(id, data)),
    [dispatch],
  );

  const postForms = useCallback(
    (data) => dispatch(actions.request.adminActions.forms.postForms.post(data)),
    [dispatch],
  );

  const formsID: FormsType = useSelector(
    reducers.request.adminReducers.formsState.getID.value,
  );

  useEffect(() => {
    id && Object.keys(formsID).length && setFields(formsID);
  }, [formsID]);

  return (
    <div className='form-edit'>
      <div className='container'>
        <h1 className='form-edit__title'>Form Erstellen</h1>
        <form action='#' className='form-edit__form'>
          <div className='form-edit__form-inputs'>
            <div className='form-edit__form-input-wrapper'>
              <label htmlFor='name' className='form-edit__form-label'>
                Name der Abgabeform
              </label>
              <input
                type='text'
                className='form-edit__form-input'
                id='name'
                value={fields.name}
                onChange={(event) => {
                  setFields({ ...fields, name: event.target.value });
                }}
              />
            </div>
            <div className='form-edit__form-input-wrapper'>
              <label htmlFor='recipe' className='form-edit__form-label'>
                Wert auf dem Rezept
              </label>
              <input
                type='text'
                className='form-edit__form-input'
                id='recipe'
                value={fields.receipe}
                onChange={(event) => {
                  setFields({ ...fields, receipe: event.target.value });
                }}
              />
            </div>
          </div>

          <div className='form-edit__form-buttons'>
            <button
              className='button form-edit__form-button form-edit__form-button--cancel'
              type='button'
              onClick={() => {
                history.push('/admin/buds', { tab: 2 });
              }}
            >
              Abbrechen
            </button>
            <button
              className='button form-edit__form-button form-edit__form-button--save'
              type='button'
              onClick={() =>
                id
                  ? putFormsID(id, fields).then(() =>
                      history.push('/admin/buds', { tab: 2 }),
                    )
                  : postForms(fields).then(() =>
                      history.push('/admin/buds', { tab: 2 }),
                    )
              }
            >
              Speichern
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BudsFormEdit;
