import React from 'react';

import Header from '../../components/customer/Header';
import Main from '../../components/customer/dashboard/Main';

const Dashboard: React.FC = (): JSX.Element => {
  return (
    <>
      <Header />
      <Main />
    </>
  );
};

export default Dashboard;
