import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as utils from '../utils';
import * as reducers from '../store/reducers';

import Header from '../components/login/Header';
import Main from '../components/login';

const Login: React.FC = (): JSX.Element => {
  const history = useHistory();
  const accessToken = localStorage.getItem('accessToken');

  const loginValue = useSelector(reducers.request.authState.login.value);
  const loginStatus = useSelector(reducers.request.authState.login.status);

  useEffect(() => {
    if (loginStatus === 'success' || accessToken) {
      if (
        loginValue.role === 'admin' ||
        utils.checkRoleToken(accessToken) === 'admin'
      ) {
        history.push('/admin/buds');
      } else if (
        loginValue.role === 'customer' ||
        utils.checkRoleToken(accessToken) === 'customer'
      ) {
        history.push('/customer');
      }
    }
  }, [loginStatus]);

  return (
    <>
      <Header page='forgot' />
      <Main />
    </>
  );
};

export default Login;
