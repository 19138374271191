import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import * as reducers from '../../../store/reducers';
import * as actions from '../../../store/actions';

import './ExtractsDosageEdit.scss';

import ExtractsDosageEditFields from '../ExtractsDosageEditFields';

interface Props {
  id: number;
}

const ExtractsDosageEdit: React.FC<Props> = ({ id }): JSX.Element => {
  const [fields, setFields] = useState({
    name: '',
    type: 'extract',
    dosageFields: [],
  });

  const history = useHistory();
  const dispatch: any = useDispatch();

  const pudDosagesID = useCallback(
    (id, data) =>
      dispatch(actions.request.adminActions.dosages.putDosagesID.put(id, data)),
    [dispatch],
  );
  const postDosages = useCallback(
    (data) =>
      dispatch(actions.request.adminActions.dosages.postDosages.post(data)),
    [dispatch],
  );

  const dosagesID = useSelector(
    reducers.request.adminReducers.dosagesState.getID.value,
  );

  useEffect(() => {
    id && Object.keys(dosagesID).length && setFields(dosagesID);
  }, [dosagesID]);

  return (
    <div className='dosage-edit'>
      <div className='container'>
        <h1 className='dosage-edit__title'>Bud-Dosierungsanleitung</h1>
        <form action='#' className='dosage-edit__form'>
          <div className='dosage-edit__form-inputs'>
            <div className='dosage-edit__form-title-wrapper'>
              <div className='dosage-edit__form-title'>
                <label htmlFor='name' className='dosage-edit__form-label'>
                  Name der Anleitung
                </label>
                <input
                  type='text'
                  className='dosage-edit__form-input'
                  id='name'
                  value={fields.name}
                  onChange={(event) => {
                    setFields({ ...fields, name: event.target.value });
                  }}
                />
              </div>
            </div>

            {!fields.dosageFields.length && (
              <p className='message'>
                Felder hinzufügen, um Ihre Dosierungsanleitung zu erstellen
                <br /> (bis zu 4 Felder)
              </p>
            )}

            {Object.keys(fields).length && (
              <ExtractsDosageEditFields fields={fields} setFields={setFields} />
            )}
          </div>

          <div className='dosage-edit__form-buttons'>
            <button
              className='button dosage-edit__form-button dosage-edit__form-button--cancel'
              type='button'
              onClick={() => {
                history.push('/admin/extracts', { tab: 1 });
              }}
            >
              Abbrechen
            </button>
            <button
              className='button dosage-edit__form-button dosage-edit__form-button--save'
              type='button'
              onClick={() =>
                id
                  ? pudDosagesID(id, fields).then(() =>
                      history.push('/admin/extracts', { tab: 1 }),
                    )
                  : postDosages(fields).then(() =>
                      history.push('/admin/extracts', { tab: 1 }),
                    )
              }
            >
              Speichern
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ExtractsDosageEdit;
