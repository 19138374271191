import * as types from '../../../types/request';

const initialState = {
  put: {
    status: null,
    error: null,
  },

  deleteID: {
    status: null,
    error: null,
  },

  get: {
    value: [],
    status: null,
    error: null,
  },
};

export const dosagesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    // ----- PUT_DOSAGES_SUCCESS
    case types.customersTypes.dosages.PUT_DOSAGES_SUCCESS:
      return { ...state, put: { ...state.put, status: 'success' } };
    case types.customersTypes.dosages.PUT_DOSAGES_FAILED:
      return {
        ...state,
        put: { ...state.put, status: 'failed', error: action.payload },
      };
    case types.customersTypes.dosages.PUT_DOSAGES_PENDING:
      return {
        ...state,
        put: { ...state.put, status: 'pending', error: null },
      };

    // ----- DELETE_DOSAGES_ID_SUCCESS
    case types.customersTypes.dosages.DELETE_DOSAGES_ID_SUCCESS:
      return { ...state, deleteID: { ...state.deleteID, status: 'success' } };
    case types.customersTypes.dosages.DELETE_DOSAGES_ID_FAILED:
      return {
        ...state,
        deleteID: {
          ...state.deleteID,
          status: 'failed',
          error: action.payload,
        },
      };
    case types.customersTypes.dosages.DELETE_DOSAGES_ID_PENDING:
      return {
        ...state,
        deleteID: { ...state.deleteID, status: 'pending', error: null },
      };

    // ----- GET_DOSAGES_SUCCESS
    case types.customersTypes.dosages.GET_DOSAGES_SUCCESS:
      return {
        ...state,
        get: { ...state.get, status: 'success', value: action.payload },
      };
    case types.customersTypes.dosages.GET_DOSAGES_FAILED:
      return {
        ...state,
        get: { ...state.get, status: 'failed', error: action.payload },
      };
    case types.customersTypes.dosages.GET_DOSAGES_PENDING:
      return {
        ...state,
        get: { ...state.get, status: 'pending', error: null },
      };

    default: {
      return state;
    }
  }
};

export const dosagesState = {
  put: {
    status: (state: any) => state.requestData.customers.dosages.putID.status,
    error: (state: any) => state.requestData.customers.dosages.putID.error,
  },

  deleteID: {
    status: (state: any) => state.requestData.customers.dosages.deleteID.status,
    error: (state: any) => state.requestData.customers.dosages.deleteID.error,
  },

  get: {
    value: (state: any) => state.requestData.customers.dosages.get.value,
    status: (state: any) => state.requestData.customers.dosages.get.status,
    error: (state: any) => state.requestData.customers.dosages.get.error,
  },
};
