import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import * as actions from '../../../store/actions';

import './Change.scss';

const Change: React.FC = (): JSX.Element => {
  const dispatch: any = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [password, setPassword] = useState({ old: '', new: '', repeat: '' });
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [repeatPasswordError, setRepeatPasswordError] = useState(false);

  const change = useCallback(
    (data) => dispatch(actions.request.authActions.change.post(data)),
    [dispatch],
  );

  const handleChange = () => {
    if (password.new === password.repeat) {
      change({ oldPassword: password.old, newPassword: password.new })
        .then(() => history.push('/customer/settings', location.state))
        .catch(() => setOldPasswordError(true));
    } else {
      setRepeatPasswordError(true);
    }
  };

  return (
    <section className='customer-change'>
      <div className='customer-change__container container'>
        <div className='customer-change__inner'>
          <h1 className='customer-change__title'>Kennwort ändern</h1>
          <div className='customer-change__content'>
            <form action='#' className='customer-change__form'>
              <div className='customer-change__field'>
                <label
                  htmlFor='old-password'
                  className='customer-change__label'
                >
                  Altes Passwort
                </label>
                <input
                  type='password'
                  className={`customer-change__input ${
                    oldPasswordError && 'border-error'
                  }`}
                  id='old-password'
                  placeholder='Altes Passwort'
                  value={password.old}
                  onChange={(event) => {
                    setOldPasswordError(false);
                    setPassword({ ...password, old: event.target.value });
                  }}
                />
              </div>
              <div className='customer-change__field'>
                <label
                  htmlFor='new-password'
                  className='customer-change__label'
                >
                  Neues Passwort
                </label>
                <input
                  type='password'
                  className={`customer-change__input ${
                    repeatPasswordError && 'border-error'
                  }`}
                  id='new-password'
                  placeholder='Neues Passwort'
                  value={password.new}
                  onChange={(event) => {
                    setRepeatPasswordError(false);
                    setPassword({ ...password, new: event.target.value });
                  }}
                />
              </div>
              <div className='customer-change__field'>
                <label htmlFor='confirm' className='customer-change__label'>
                  Wiederhole das neue Passwort
                </label>
                <input
                  type='password'
                  className={`customer-change__input ${
                    repeatPasswordError && 'border-error'
                  }`}
                  id='confirm'
                  placeholder='Wiederhole das neue Passwort'
                  value={password.repeat}
                  onChange={(event) => {
                    setRepeatPasswordError(false);
                    setPassword({ ...password, repeat: event.target.value });
                  }}
                />
              </div>
              <div className='customer-change__buttons'>
                <button
                  className='customer-change__button customer-change__button--back'
                  type='button'
                  onClick={() => {
                    history.push('/customer/settings', location.state);
                  }}
                >
                  Zurück
                </button>
                <button
                  className='button button--green customer-change__button'
                  type='button'
                  onClick={handleChange}
                >
                  Anwenden
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Change;
