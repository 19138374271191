import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import * as actions from '../../store/actions';

import './Forgot.scss';

const Forgot: React.FC = (): JSX.Element => {
  const dispatch: any = useDispatch();
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);

  const forgot = useCallback(
    (data) => dispatch(actions.request.authActions.forgot.post(data)),
    [dispatch],
  );

  const handleSubmit = (event: any) => {
    event.preventDefault();

    forgot({ email })
      .then(() => history.push('/'))
      .catch(() => setError(true));
  };

  return (
    <section className='forgot'>
      <div className='container'>
        <button
          className='forgot__back'
          type='button'
          onClick={() => history.push('/')}
        >
          Zurück
        </button>
        <div className='forgot__content'>
          <h1 className='forgot__title'>Passwort zurücksetzen</h1>
          <form action='#'>
            <div className='forgot__field'>
              <label htmlFor='email' className='forgot__label'>
                E-Mail
              </label>
              <input
                type='email'
                id='email'
                className={`forgot__input ${error && 'border-error'}`}
                placeholder='Email eingeben'
                value={email}
                onChange={(event) => {
                  setError(false);
                  setEmail(event.target.value);
                }}
              />
              {error && <p className='field-error'>Die E-Mail ist falsch</p>}
            </div>
            <button
              className='button button--green forgot__submit'
              onClick={(event) => handleSubmit(event)}
            >
              Nächster
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Forgot;
