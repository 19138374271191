export const authTypes = {
  AUTH_LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
  AUTH_LOGIN_FAILED: 'AUTH_LOGIN_FAILED',
  AUTH_LOGIN_PENDING: 'AUTH_LOGIN_PENDING',

  AUTH_REFRESH_SUCCESS: 'AUTH_REFRESH_SUCCESS',
  AUTH_REFRESH_FAILED: 'AUTH_REFRESH_FAILED',
  AUTH_REFRESH_PENDING: 'AUTH_REFRESH_PENDING',

  AUTH_LOGOUT_SUCCESS: 'AUTH_LOGOUT_SUCCESS',
  AUTH_LOGOUT_FAILED: 'AUTH_LOGOUT_FAILED',
  AUTH_LOGOUT_PENDING: 'AUTH_LOGOUT_PENDING',

  AUTH_CHANGE_SUCCESS: 'AUTH_CHANGE_SUCCESS',
  AUTH_CHANGE_FAILED: 'AUTH_CHANGE_FAILED',
  AUTH_CHANGE_PENDING: 'AUTH_CHANGE_PENDING',

  AUTH_FORGOT_SUCCESS: 'AUTH_FORGOT_SUCCESS',
  AUTH_FORGOT_FAILED: 'AUTH_FORGOT_FAILED',
  AUTH_FORGOT_PENDING: 'AUTH_FORGOT_PENDING',

  AUTH_RESET_SUCCESS: 'AUTH_RESET_SUCCESS',
  AUTH_RESET_FAILED: 'AUTH_RESET_FAILED',
  AUTH_RESET_PENDING: 'AUTH_RESET_PENDING',
};
