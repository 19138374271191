import * as API from '../../../../../api';
import * as types from '../../../../types/request';

export const deleteBuds = {
  delete: function (data: any) {
    return (dispatch: any) => {
      dispatch(this.deletePending());

      return API.customersApi.budsApi
        .deleteBuds(data)
        .then((response: any) => {
          if (response.status === 200) {
            return response
              .json()
              .then((json: any) => dispatch(this.deleteSuccess(json)));
          } else {
            return response
              .json()
              .then((json: any) => dispatch(this.deleteFailed(json)));
          }
        })
        .catch((response: any) => {
          return dispatch(this.deleteFailed(response));
        });
    };
  },

  deleteSuccess: (response: any) => ({
    type: types.customersTypes.buds.DELETE_BUDS_SUCCESS,
    payload: response,
  }),

  deleteFailed: (response: any) => ({
    type: types.customersTypes.buds.DELETE_BUDS_FAILED,
    payload: response,
  }),

  deletePending: () => ({
    type: types.customersTypes.buds.DELETE_BUDS_PENDING,
  }),
};
