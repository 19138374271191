import React from 'react';

import './BudsHead.scss';

interface Props {
  buttons: {
    title: string;
    onClick: any;
  }[];
}

const BudsHead: React.FC<Props> = ({ buttons }): JSX.Element => {
  return (
    <div className='customer-buds__head'>
      <h1 className='customer-buds__title'>Blüten</h1>
      <div className='customer-buds__buttons'>
        {buttons.map((button, index) => (
          <button
            className='button button--green customer-buds__button'
            key={`${button}${index}`}
            type='button'
            onClick={button.onClick}
          >
            {button.title}
          </button>
        ))}
      </div>
    </div>
  );
};

export default BudsHead;
