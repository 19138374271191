import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../../store/actions';
import * as reducers from '../../../store/reducers';
import { getGenetik } from '../../../utils/getGenetik';
import { getImageUrl } from '../../../utils/getImageUrl';

import './BudDetails.scss';

import NavIcon from '../../../images/symbols/next-page.svg';
import BudsIcon from '../../../images/bud-icon.svg';
import Modal from '../../customer/Modal';

interface Props {
  id: number;
}

const BudDetails: React.FC<Props> = ({ id }): JSX.Element => {
  const dispatch: any = useDispatch();
  const history = useHistory();

  const [removeBudPopupInfo, setRemoveBudPopupInfo] = useState(false);
  const [selectedImage, setSelectedImage] = useState<any>({});

  const getBudsAll = useCallback(
    () => dispatch(actions.request.customersActions.buds.getBudsAll.get()),
    [dispatch],
  );
  const getBudsID = useCallback(
    (id) => dispatch(actions.request.customersActions.buds.getBudsID.get(id)),
    [dispatch],
  );
  const deleteBudsID = useCallback(
    (id) =>
      dispatch(actions.request.customersActions.buds.deleteBudsID.delete(id)),
    [dispatch],
  );

  const budsAll: number[] = useSelector(
    reducers.request.customersReducers.budsState.getAll.value,
  );
  const bud = useSelector(
    reducers.request.customersReducers.budsState.getID.value,
  );

  const previousId = budsAll[budsAll.indexOf(id) - 1];
  const nextId = budsAll[budsAll.indexOf(id) + 1];

  useEffect(() => {
    getBudsID(id);
    getBudsAll();
  }, [id]);

  useEffect(() => {
    if (Object.keys(bud).length === 0) return;

    if (bud.images.length) setSelectedImage({ index: 0, src: bud.images[0] });
    else setSelectedImage({ index: -1, src: bud.image });
  }, [bud]);

  return (
    <>
      <section className='customer-bud'>
        <div className='container'>
          <button
            className='customer-bud__back'
            type='button'
            onClick={() => history.push('/customer/buds')}
          >
            Zurück
          </button>
          <h1 className='customer-bud__title'>Blüten Infos</h1>
          <div className='customer-bud__info'>
            <div className='customer-bud__info-navigation'>
              {previousId ? (
                <div
                  className='customer-bud__info-previous'
                  onClick={() => history.push(`/customer/buds/${previousId}`)}
                >
                  <img src={NavIcon} alt='previous-page' />
                </div>
              ) : (
                <div className='customer-bud__info-previous-disabled'>
                  <img src={NavIcon} alt='previous-page' />
                </div>
              )}
              {nextId ? (
                <div
                  className='customer-bud__info-next'
                  onClick={() => history.push(`/customer/buds/${nextId}`)}
                >
                  <img src={NavIcon} alt='next-page' />
                </div>
              ) : (
                <div className='customer-bud__info-next-disabled'>
                  <img src={NavIcon} alt='next-page' />
                </div>
              )}
            </div>
            <div className='customer-bud__info-left'>
              <div className='customer-bud__info-image-wrapper'>
                <div className='customer-bud__info-image-head'>
                  <img
                    src={getImageUrl(bud.companyImage)}
                    alt=''
                    className='customer-bud__info-image-logo'
                  />
                  <p className='customer-bud__info-image-text'>
                    <span>{bud.name}</span>
                    <br />
                    {bud.companyName}
                  </p>
                </div>
                <img
                  src={getImageUrl(selectedImage.src) || BudsIcon}
                  alt=''
                  className='customer-bud__info-image'
                />
                <div className='customer-bud__info-image-description'>
                  <span>{bud.kultivar || 'k.A.'}</span>
                  <br />
                  <p>{getGenetik(bud.genetik)}</p>
                </div>
              </div>
              <div className='customer-bud__info-gallery'>
                {bud.images?.map((image: string, index: number) => (
                  <div
                    className={`customer-bud__info-gallery-item ${
                      selectedImage.index === index &&
                      'customer-bud__info-gallery-item--selected'
                    }`}
                    key={index}
                    onClick={() => setSelectedImage({ index, src: image })}
                  >
                    <img src={getImageUrl(image)} alt='' />
                  </div>
                ))}
              </div>
            </div>
            <div className='customer-bud__info-right'>
              <p className='customer-bud__info-right-title'>Beschreibung:</p>
              <div
                className='customer-bud__info-right-container'
                dangerouslySetInnerHTML={{ __html: bud.description }}
              />
            </div>
          </div>
          <div className='customer-bud__buttons'>
            <button
              className='button button--dark customer-bud__button customer-bud__button--cancel'
              type='button'
              onClick={() => setRemoveBudPopupInfo(true)}
            >
              Löschen
            </button>
            <button
              className='button button--green customer-bud__button'
              type='button'
              onClick={() => history.push(`/customer/buds/${id}/edit`)}
            >
              Bearbeiten
            </button>
          </div>
        </div>
      </section>

      {removeBudPopupInfo && (
        <Modal>
          <div className='customer-buds__table-modal'>
            <p className='customer-buds__table-modal-title'>Bud löschen?</p>
            <p className='customer-buds__table-modal-paragraph'>
              Sie haben vor, den Bud "{bud.name}" zu löschen.
            </p>
            <p className='customer-buds__table-modal-paragraph'>
              Diese Änderungen kann nicht rückgängig gemacht werden.
            </p>
            <div className='customer-buds__table-modal-buttons'>
              <button
                className='button button--dark customer-buds__table-modal-button customer-buds__table-modal-button--delete'
                onClick={() => {
                  deleteBudsID(id).then(() => {
                    setRemoveBudPopupInfo(false);
                    history.push('/customer/buds');
                  });
                }}
              >
                Löschen
              </button>
              <button
                className='button button--green customer-buds__table-modal-button customer-buds__table-modal-button--cancel'
                onClick={() => setRemoveBudPopupInfo(false)}
              >
                Abbrechen
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default BudDetails;
