const baseURL = process.env.REACT_APP_BASE_URL;

export const uploadsApi = {
  getUploadsPath: (path: string) => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/uploads/${path}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getUploads: () => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/uploads`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  postUploads: (file: string) => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/uploads`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },

      body: file,
    });
  },
};
