import React from 'react';

import './BudsDosageEditFields.scss';

import CloseIcon from '../../../images/symbols/close.svg';
import LinkIcon from '../../../images/symbols/link.svg';
import DeleteIcon from '../../../images/symbols/delete.svg';

interface Props {
  fields: any;
  setFields: (arg: any) => void;
}

const BudsDosageEditFields: React.FC<Props> = ({
  fields,
  setFields,
}): JSX.Element => {
  const onAddNewField = () => {
    setFields({
      ...fields,
      dosageFields: [
        ...fields.dosageFields,
        { title: '', value: '', prefix: null, sufix: null },
      ],
    });
  };

  const onChangeField = (name: string, value: string, index: number) => {
    setFields({
      ...fields,
      dosageFields: fields.dosageFields.map((field: any, i: number) => {
        if (i === index) {
          return { ...field, [name]: value };
        }

        return field;
      }),
    });
  };

  const onRemoveField = (index: number) => {
    setFields({
      ...fields,
      dosageFields: fields.dosageFields.filter((_: any, i: number) => {
        return index !== i;
      }),
    });
  };

  return (
    <>
      {fields.dosageFields.map((field: any, index: number) => (
        <div className='dosage-edit__form-row' key={index}>
          {field.prefix !== null ? (
            <div className='dosage-edit__form-field'>
              <label
                htmlFor={`prefix-${field.id}`}
                className='dosage-edit__form-label'
              >
                Präfix
              </label>

              <div className='dosage-edit__form-input-wrapper'>
                <input
                  name='prefix'
                  type='text'
                  className='dosage-edit__form-input'
                  id={`prefix-${field.id}`}
                  value={field.prefix}
                  onChange={(event) =>
                    onChangeField(event.target.name, event.target.value, index)
                  }
                />

                <img
                  src={CloseIcon}
                  className='dosage-edit__form-input-close'
                  alt='close'
                  onClick={() => onChangeField('prefix', '', index)}
                />
                <img
                  src={LinkIcon}
                  alt='link'
                  className='dosage-edit__form-input-link'
                />
              </div>
            </div>
          ) : (
            <div className='dosage-edit__form-prefix-wrapper'>
              <button
                className='dosage-edit__form-prefix'
                type='button'
                onClick={() => onChangeField('prefix', '', index)}
              >
                + Präfix
              </button>
              <img
                src={LinkIcon}
                alt='link'
                className='dosage-edit__form-input-link'
              />
            </div>
          )}

          <div className='dosage-edit__form-field'>
            <input
              name='title'
              type='text'
              className='dosage-edit__form-input-title'
              placeholder='Feldname eingeben'
              value={field.title}
              onChange={(event) =>
                onChangeField(event.target.name, event.target.value, index)
              }
            />

            <div className='dosage-edit__form-input-wrapper'>
              <input
                name='value'
                type='text'
                placeholder='Dosierwert hier eingeben'
                className='dosage-edit__form-input'
                id={`field-${field.id}`}
                value={field.value}
                onChange={(event) =>
                  onChangeField(event.target.name, event.target.value, index)
                }
              />
              <img
                src={CloseIcon}
                className='dosage-edit__form-input-close'
                alt='close'
                onClick={() => onChangeField('value', '', index)}
              />
              <img
                src={LinkIcon}
                alt='link'
                className='dosage-edit__form-input-link'
              />
            </div>
          </div>

          {field.sufix !== null ? (
            <div className='dosage-edit__form-field'>
              <label
                htmlFor={`suffix-${field.id}`}
                className='dosage-edit__form-label'
              >
                Suffix
              </label>

              <div className='dosage-edit__form-input-wrapper'>
                <input
                  name='sufix'
                  type='text'
                  className='dosage-edit__form-input'
                  id={`suffix-${field.id}`}
                  value={field.sufix}
                  onChange={(event) =>
                    onChangeField(event.target.name, event.target.value, index)
                  }
                />

                <img
                  src={CloseIcon}
                  className='dosage-edit__form-input-close'
                  alt='close'
                  onClick={() => onChangeField('sufix', '', index)}
                />

                <img
                  src={DeleteIcon}
                  alt=''
                  className='dosage-edit__form-input-delete'
                  onClick={() => onRemoveField(index)}
                />
              </div>
            </div>
          ) : (
            <div className='dosage-edit__form-suffix-wrapper'>
              <button
                className='dosage-edit__form-suffix'
                type='button'
                onClick={() => onChangeField('sufix', '', index)}
              >
                + Suffix
              </button>
              <img
                src={DeleteIcon}
                alt=''
                className='dosage-edit__form-suffix-delete'
                onClick={() => onRemoveField(index)}
              />
            </div>
          )}
        </div>
      ))}

      {fields.dosageFields.length < 4 && (
        <button
          className='dosage-edit__form-add'
          type='button'
          onClick={onAddNewField}
        >
          Feld hinzufügen
        </button>
      )}
    </>
  );
};

export default BudsDosageEditFields;
