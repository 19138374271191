import React from 'react';
import { useLocation } from 'react-router';

import Header from '../components/login/Header';
import ResetComponent from '../components/Reset';

const Reset: React.FC = (): JSX.Element => {
  const query = new URLSearchParams(useLocation().search);

  return (
    <>
      <Header />
      <ResetComponent token={query.get('token')} />
    </>
  );
};

export default Reset;
