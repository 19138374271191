import * as interfaces from '../../interfaces';
const baseURL = process.env.REACT_APP_BASE_URL;

export const customersApi = {
  getCustomersInfo: () => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/customers/info`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getCustomers: () => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/customers`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  putCustomers: (data: interfaces.customersApi) => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/customers`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify(data),
    });
  },

  putCustomersDescriptions: (data: interfaces.customersDescriptionsApi) => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/customers/descriptions`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify(data),
    });
  },

  putCustomersSettings: (data: interfaces.customerSettingsApi) => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/customers/settings`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify(data),
    });
  },

  postCustomersMail: (data: { message: string }) => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/customers/mail`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify(data),
    });
  },
};
