export const statistics = {
  GET_PRESCRIPTIONS_BY_OUTPUT_SUCCESS: '  GET_PRESCRIPTIONS_BY_OUTPUT_SUCCESS',
  GET_PRESCRIPTIONS_BY_OUTPUT_FAILED: '  GET_PRESCRIPTIONS_BY_OUTPUT_FAILED',
  GET_PRESCRIPTIONS_BY_OUTPUT_PENDING: '  GET_PRESCRIPTIONS_BY_OUTPUT_PENDING',

  GET_PRESCRIPTIONS_BY_AMOUNT_SUCCESS: '  GET_PRESCRIPTIONS_BY_AMOUNT_SUCCESS',
  GET_PRESCRIPTIONS_BY_AMOUNT_FAILED: '  GET_PRESCRIPTIONS_BY_AMOUNT_FAILED',
  GET_PRESCRIPTIONS_BY_AMOUNT_PENDING: '  GET_PRESCRIPTIONS_BY_AMOUNT_PENDING',

  GET_PRODUCTS_AND_COUNT_SUCCESS: 'GET_PRODUCTS_AND_COUNT_SUCCESS',
  GET_PRODUCTS_AND_COUNT_FAILED: 'GET_PRODUCTS_AND_COUNT_FAILED',
  GET_PRODUCTS_AND_COUNT_PENDING: 'GET_PRODUCTS_AND_COUNT_PENDING',

  GET_PRODUCTS_AND_AMOUNT_SUCCESS: 'GET_PRODUCTS_AND_AMOUNT_SUCCESS',
  GET_PRODUCTS_AND_AMOUNT_FAILED: 'GET_PRODUCTS_AND_AMOUNT_FAILED',
  GET_PRODUCTS_AND_AMOUNT_PENDING: 'GET_PRODUCTS_AND_AMOUNT_PENDING',
};
