export const dosages = {
  GET_DOSAGES_ALL_SUCCESS: 'GET_DOSAGES_ALL_SUCCESS',
  GET_DOSAGES_ALL_FAILED: 'GET_DOSAGES_ALL_FAILED',
  GET_DOSAGES_ALL_PENDING: 'GET_DOSAGES_ALL_PENDING',

  GET_DOSAGES_ID_SUCCESS: 'GET_DOSAGES_ID_SUCCESS',
  GET_DOSAGES_ID_FAILED: 'GET_DOSAGES_ID_FAILED',
  GET_DOSAGES_ID_PENDING: 'GET_DOSAGES_ID_PENDING',

  PUT_DOSAGES_ID_SUCCESS: 'PUT_DOSAGES_ID_SUCCESS',
  PUT_DOSAGES_ID_FAILED: 'PUT_DOSAGES_ID_FAILED',
  PUT_DOSAGES_ID_PENDING: 'PUT_DOSAGES_ID_PENDING',

  DELETE_DOSAGES_ID_SUCCESS: 'DELETE_DOSAGES_ID_SUCCESS',
  DELETE_DOSAGES_ID_FAILED: 'DELETE_DOSAGES_ID_FAILED',
  DELETE_DOSAGES_ID_PENDING: 'DELETE_DOSAGES_ID_PENDING',

  GET_DOSAGES_SUCCESS: 'GET_DOSAGES_SUCCESS',
  GET_DOSAGES_FAILED: 'GET_DOSAGES_FAILED',
  GET_DOSAGES_PENDING: 'GET_DOSAGES_PENDING',

  POST_DOSAGES_SUCCESS: 'POST_DOSAGES_SUCCESS',
  POST_DOSAGES_FAILED: 'POST_DOSAGES_FAILED',
  POST_DOSAGES_PENDING: 'POST_DOSAGES_PENDING',
};
