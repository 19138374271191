import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../../store/actions';
import * as reducers from '../../../store/reducers';
import { DosagesType, FormsType } from '../../../types';

import './Buds.scss';

import CloseIcon from '../../../images/symbols/close.svg';
import CheckIcon from '../../../images/symbols/check-mark.svg';
import BudsHead from '../BudsHead';
import BudsTabs from '../BudsTabs';
import BudsTable from '../BudsTable';
import BudsDosage from '../BudsDosage';
import BudsForm from '../BudsForm';
import Modal from '../Modal';

type FormsShown = { shown: boolean } & FormsType;

const Buds: React.FC = (): JSX.Element => {
  const dispatch: any = useDispatch();

  const [tabNumber, setTabNumber] = useState(0);
  const [isNewPopupOpen, setIsNewPopupOpen] = useState({
    isOpen: false,
    title: '',
  });
  const [isNewSuccessPopupOpen, setIsNewSuccessPopupOpen] = useState(false);
  const [isListPopupOpen, setIsListPopupOpen] = useState(false);
  const [isListSuccessPopupOpen, setIsListSuccessPopupOpen] = useState(false);
  const [isFormPopupOpen, setIsFormPopupOpen] = useState(false);
  const [isFormSuccessPopupOpen, setIsFormSuccessPopupOpen] = useState(false);
  const [selectedDosages, setSelectedDosages] = useState<number[]>([]);
  const [selectedForms, setSelectedForms] = useState<number[]>([]);
  const [requestMessage, setRequestMessage] = useState('');

  const getDosages = useCallback(
    () =>
      dispatch(actions.request.customersActions.dosages.getDosages.get('bud')),
    [dispatch],
  );
  const putDosages = useCallback(
    (data) =>
      dispatch(
        actions.request.customersActions.dosages.putDosages.put('bud', data),
      ),
    [dispatch],
  );
  const getForms = useCallback(
    () => dispatch(actions.request.customersActions.forms.getForms.get()),
    [dispatch],
  );

  const putForms = useCallback(
    (data) =>
      dispatch(actions.request.customersActions.forms.putForms.put(data)),
    [dispatch],
  );
  const postCustomersMail = useCallback(
    (data) =>
      dispatch(
        actions.request.customersActions.customers.postCustomersMail.post(data),
      ),
    [dispatch],
  );

  const dosages: DosagesType[] = useSelector(
    reducers.request.customersReducers.dosagesState.get.value,
  );
  const forms: FormsShown[] = useSelector(
    reducers.request.customersReducers.formsState.get.value,
  );

  useEffect(() => {
    getDosages();
  }, [isListPopupOpen]);

  useEffect(() => {
    getForms();
  }, [isFormPopupOpen]);

  useEffect(() => {
    const shownDosagesIds = dosages
      .filter((dosage) => dosage.shown)
      .map((dosage) => dosage.id);
    setSelectedDosages(shownDosagesIds);
  }, [dosages]);

  useEffect(() => {
    const shownFormsIds = forms
      .filter((form) => form.shown)
      .map((form) => form.id);
    setSelectedForms(shownFormsIds);
  }, [forms]);

  const handleNewPopup = (title: string) => {
    setIsNewPopupOpen({ isOpen: true, title });
  };

  const handleListPopup = () => {
    setIsListPopupOpen(true);
  };

  const handleFormPopup = () => {
    setIsFormPopupOpen(true);
  };

  const onToggleSelectedDosages = (id: number) => {
    if (selectedDosages.includes(id)) {
      setSelectedDosages(selectedDosages.filter((d: number) => d !== id));
    } else {
      setSelectedDosages([id, ...selectedDosages]);
    }
  };

  const onToggleSelectedForms = (id: number) => {
    if (selectedForms.includes(id)) {
      setSelectedForms(selectedForms.filter((f: number) => f !== id));
    } else {
      setSelectedForms([id, ...selectedForms]);
    }
  };

  let buttons: Array<{
    title: string;
    onClick: any;
  }> = [];

  if (tabNumber === 0) {
    buttons = [
      {
        title: 'Neue Blüte anfragen',
        onClick: () => handleNewPopup('Neue Blüte anfragen'),
      },
    ];
  }

  if (tabNumber === 1) {
    buttons = [
      {
        title: 'Neue Dosierung anfragen',
        onClick: () => handleNewPopup('Neue Dosierung anfragen'),
      },
      {
        title: 'Dosierung aktivieren / deaktivieren',
        onClick: handleListPopup,
      },
    ];
  }

  if (tabNumber === 2) {
    buttons = [
      {
        title: 'Neue Abgabeformen anfragen',
        onClick: () => handleNewPopup('Neue Abgabeformen anfragen'),
      },
      {
        title: 'Abgabeformen listen',
        onClick: handleFormPopup,
      },
    ];
  }

  return (
    <>
      <section className='customer-buds'>
        <div className='container'>
          <BudsHead buttons={buttons} />
          <BudsTabs tabNumber={tabNumber} setTabNumber={setTabNumber} />
          {tabNumber === 0 && (
            <BudsTable
              view={localStorage.getItem('budsTableView') ?? 'table'}
              switchButtons='right'
            />
          )}
          {tabNumber === 1 && <BudsDosage />}
          {tabNumber === 2 && <BudsForm />}
        </div>
      </section>

      {isNewPopupOpen.isOpen && (
        <Modal>
          <div className='customer-buds__modal-new'>
            <button
              className='customer-buds__modal-new-close'
              type='button'
              onClick={() => setIsNewPopupOpen({ isOpen: false, title: '' })}
            >
              <img src={CloseIcon} alt='close' />
            </button>
            <p className='customer-buds__modal-new-title'>
              {isNewPopupOpen.title}
            </p>
            <form action='#'>
              <div className='customer-buds__modal-new-field'>
                <label
                  htmlFor='description'
                  className='customer-buds__modal-new-description'
                >
                  Ihre Nachricht
                </label>
                <textarea
                  name='description'
                  id='description'
                  className='customer-buds__modal-new-textarea'
                  placeholder='Ihre detaillierte Beschreibung...'
                  onChange={(event) => {
                    setRequestMessage(event.target.value);
                  }}
                />
                <button
                  className='button button--green customer-buds__modal-new-submit'
                  type='button'
                  onClick={() => {
                    postCustomersMail({ message: requestMessage }).then(() => {
                      setIsNewPopupOpen({ isOpen: false, title: '' });
                      setIsNewSuccessPopupOpen(true);
                    });
                  }}
                >
                  Anfrage abschicken
                </button>
              </div>
            </form>
          </div>
        </Modal>
      )}

      {isNewSuccessPopupOpen && (
        <Modal>
          <div className='customer-buds__modal-new-success'>
            <img
              src={CheckIcon}
              alt='check mark'
              className='customer-buds__modal-new-success-check'
            />
            <p className='customer-buds__modal-new-success-text'>
              Anfrage gesendet
            </p>
            <button
              className='button button--green customer-buds__modal-new-success-close'
              type='button'
              onClick={() => setIsNewSuccessPopupOpen(false)}
            >
              Ok
            </button>
          </div>
        </Modal>
      )}

      {isListPopupOpen && (
        <Modal mode='dosages'>
          <div className='customer-buds__modal-list'>
            <button
              className='customer-buds__modal-list-close'
              type='button'
              onClick={() => {
                setIsListPopupOpen(false);
              }}
            >
              <img src={CloseIcon} alt='close' />
            </button>
            <p className='customer-buds__modal-list-title'>Anleitung listen</p>
            <p className='customer-buds__modal-list-description'>
              Wählen Sie die Anleitung aus, die Sie listen möchten.
            </p>
            <div className='customer-buds__modal-list-dosages'>
              {dosages.map((dosage) => (
                <div
                  className={`customer-buds__modal-list-dosage ${
                    selectedDosages.includes(dosage.id) &&
                    'customer-buds__modal-list-dosage--checked'
                  }`}
                  key={dosage.id}
                >
                  <p className='customer-buds__modal-list-dosage-title'>
                    {dosage.name}
                  </p>
                  <p className='customer-buds__modal-list-dosage-description'>
                    {dosage.dosageFields
                      .map((field) =>
                        [field.prefix, field.value, field.sufix].join(''),
                      )
                      .join('')}
                  </p>
                  <input
                    checked={selectedDosages.includes(dosage.id)}
                    type='checkbox'
                    className='customer-buds__modal-list-dosage-input'
                    onChange={() => onToggleSelectedDosages(dosage.id)}
                  />
                </div>
              ))}
            </div>
            <button
              className='button button--green customer-buds__modal-list-submit'
              type='button'
              onClick={() => {
                putDosages({ dosagesIds: selectedDosages }).then(() => {
                  getDosages();
                  setIsListPopupOpen(false);
                  setIsListSuccessPopupOpen(true);
                });
              }}
            >
              Auswahl bestätigen
            </button>
          </div>
        </Modal>
      )}

      {isListSuccessPopupOpen && (
        <Modal>
          <div className='customer-buds__modal-list-success'>
            <img
              src={CheckIcon}
              alt='check mark'
              className='customer-buds__modal-list-success-check'
            />
            <p className='customer-buds__modal-list-success-text'>
              {`Ihre Auswahl wurde aktualisiert`}
            </p>
            <button
              className='button button--green customer-buds__modal-new-success-close'
              type='button'
              onClick={() => setIsListSuccessPopupOpen(false)}
            >
              Ok
            </button>
          </div>
        </Modal>
      )}

      {isFormPopupOpen && (
        <Modal mode='dosages'>
          <div className='customer-buds__modal-list'>
            <button
              className='customer-buds__modal-list-close'
              type='button'
              onClick={() => {
                setIsFormPopupOpen(false);
              }}
            >
              <img src={CloseIcon} alt='close' />
            </button>
            <p className='customer-buds__modal-list-title'>
              Abgabeformen listen
            </p>
            <p className='customer-buds__modal-list-description'>
              Wählen Sie die Abgabeform(en) aus, die in Ihrem Konfigurator
              zur Auswahl stehen sollen, um die Rezeptangaben zu
              vervollständigen.
            </p>
            <div className='customer-buds__modal-list-dosages'>
              {forms.map((form) => (
                <div
                  className={`customer-buds__modal-list-dosage ${
                    selectedForms.includes(form.id) &&
                    'customer-buds__modal-list-dosage--checked'
                  }`}
                  key={form.id}
                >
                  <p className='customer-buds__modal-list-dosage-title'>
                    {form.name}
                  </p>
                  <p className='customer-buds__modal-list-dosage-description'>
                    {form.receipe}
                  </p>
                  <input
                    checked={selectedForms.includes(form.id)}
                    type='checkbox'
                    className='customer-buds__modal-list-dosage-input'
                    onChange={() => onToggleSelectedForms(form.id)}
                  />
                </div>
              ))}
            </div>
            <button
              className='button button--green customer-buds__modal-list-submit'
              type='button'
              onClick={() => {
                putForms({ formsIds: selectedForms }).then(() => {
                  getForms();
                  setIsFormPopupOpen(false);
                  setIsFormSuccessPopupOpen(true);
                });
              }}
            >
              Auswahl bestätigen
            </button>
          </div>
        </Modal>
      )}
      {isFormSuccessPopupOpen && (
        <Modal>
          <div className='customer-buds__modal-list-success'>
            <img
              src={CheckIcon}
              alt='check mark'
              className='customer-buds__modal-list-success-check'
            />
            <p className='customer-buds__modal-list-success-text'>
              {`Ihre Auswahl wurde aktualisiert`}
            </p>
            <button
              className='button button--green customer-buds__modal-new-success-close'
              type='button'
              onClick={() => setIsFormSuccessPopupOpen(false)}
            >
              Ok
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Buds;
