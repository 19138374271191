import { combineReducers } from 'redux';
import * as reducers from './reducers';

export const combineReducer: any = combineReducers({
  // appData: combineReducers({}),
  requestData: combineReducers({
    uploads: reducers.request.uploadsReducers,
    auth: reducers.request.authReducer,
    admin: combineReducers({
      buds: reducers.request.adminReducers.budsReducer,
      extracts: reducers.request.adminReducers.extractsReducer,
      customers: reducers.request.adminReducers.customersReducer,
      dosages: reducers.request.adminReducers.dosagesReducer,
      forms: reducers.request.adminReducers.formReducer,
    }),
    customers: combineReducers({
      buds: reducers.request.customersReducers.budsReducer,
      extracts: reducers.request.customersReducers.extractsReducer,
      customers: reducers.request.customersReducers.customersReducer,
      dosages: reducers.request.customersReducers.dosagesReducer,
      forms: reducers.request.customersReducers.formsReducer,
      statistics: reducers.request.customersReducers.statisticsReducer,
    }),
  }),
});
