import React from 'react';
import { ReactSortable } from 'react-sortablejs';

import './style.scss';

interface Props {
  boxes: any[];
  setBoxes: any;
  extractName: string;
}

const ExtractDetailsDustbin: React.FC<Props> = ({
  boxes,
  setBoxes,
  extractName,
}): JSX.Element => {
  const onChangeValueDragDropItems = (value: string, index: number) => {
    const nextBoxes = [...boxes];
    nextBoxes[index] = { ...nextBoxes[index], value };
    setBoxes(nextBoxes);
  };

  return (
    <ReactSortable
      className={
        boxes.length === 0
          ? 'extract__dustbin'
          : 'extract__dustbin extract__dustbin--filled'
      }
      list={boxes}
      setList={setBoxes}
      animation={200}
      group='recipe'
      // Enable revertOnSpill to trigger onSpill event
      revertOnSpill
      onSpill={(event) => {
        // Remove element from DOM
        event.item.parentElement?.removeChild(event.item);
        // Dispatch update state in next tick to synchronize the events
        setImmediate(() => {
          setBoxes(boxes.filter((_: any, index) => index !== event.oldIndex));
        });
      }}
    >
      {boxes.map((box: any, index: number) => {
        if (box.type === 'field') {
          return (
            <div className={`extract__dustbin-box ${box.type}`} key={index}>
              <h4>{box.name}</h4>
              <input
                type='text'
                placeholder='Text eingeben'
                name={box.name}
                value={box.value}
                onChange={(event) => {
                  let { value } = event.target;
                  value = value.trim();

                  if (
                    box.text === 'Wirkstoffmenge basierend auf Multiplikator'
                  ) {
                    if (!Number.isNaN(+value))
                      onChangeValueDragDropItems(value, index);
                  } else {
                    onChangeValueDragDropItems(value, index);
                  }
                }}
              />
            </div>
          );
        } else if (box.type === 'box') {
          return (
            <div className={`extract__dustbin-box ${box.type}`} key={index}>
              <h4></h4>
              <p>{box.text}</p>
            </div>
          );
        } else if (box.type === 'symbol') {
          return (
            <div className={`extract__dustbin-box ${box.type}`} key={index}>
              <h4>{box.name}</h4>
              {box.text === 'Produktname' ? (
                <p>{extractName || box.symbol}</p>
              ) : (
                <p>{box.symbol}</p>
              )}
            </div>
          );
        }

        return null;
      })}
    </ReactSortable>
  );
};

export default ExtractDetailsDustbin;
