import React, { useState, useLayoutEffect } from 'react';
import { useLocation } from 'react-router';

import './Buds.scss';

import BudsHead from '../BudsHead';
import BudsTable from '../BudsTable';
import BudsTabs from '../BudsTabs';
import BudsDosage from '../BudsDosage';
import BudsForm from '../BudsForm';

const Buds: React.FC = (): JSX.Element => {
  const location: any = useLocation();

  const [tabNumber, setTabNumber] = useState(0);

  let budsHeadTitle: string = '';
  let budsHeadButtonText: string = '';

  if (tabNumber === 0) {
    budsHeadTitle = 'Blüten';
    budsHeadButtonText = 'Bud Erstellen';
  }

  if (tabNumber === 1) {
    budsHeadTitle = 'Blüten';
    budsHeadButtonText = 'Dosierungsanleitung erstellen';
  }

  if (tabNumber === 2) {
    budsHeadTitle = 'Blüten';
    budsHeadButtonText = 'Form erstellen';
  }

  useLayoutEffect(() => {
    if (location.state) {
      setTabNumber(location.state.tab);
    }
  }, [location]);

  return (
    <section className='buds'>
      <div className='container'>
        <BudsHead
          title={budsHeadTitle}
          buttonText={budsHeadButtonText}
          tab={tabNumber}
        />
        <BudsTabs tabNumber={tabNumber} setTabNumber={setTabNumber} />
        {tabNumber === 0 && <BudsTable />}
        {tabNumber === 1 && <BudsDosage />}
        {tabNumber === 2 && <BudsForm />}
      </div>
    </section>
  );
};

export default Buds;
