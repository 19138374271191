import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as actions from '../../../store/actions';
import * as reducers from '../../../store/reducers';
import { getImageUrl } from '../../../utils/getImageUrl';

import './ExtractEdit.scss';

import EditIcon from '../../../images/symbols/edit.svg';
import DeleteFieldIcon from '../../../images/symbols/delete-field.svg';
import CloseIcon from '../../../images/symbols/close.svg';
import ExtractIcon from '../../../images/extract-icon.svg';
import Modal from '../../customer/Modal';

interface Props {
  id: number;
}

const ExtractEdit: React.FC<Props> = ({ id }): JSX.Element => {
  const dispatch: any = useDispatch();
  const history = useHistory();

  const [removeExtractPopupInfo, setRemoveExtractPopupInfo] = useState(false);
  const [isImageOpen, setIsImageOpen] = useState(false);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState({ src: '', index: 0 });
  const [selectedGaleryItem, setSelectedGaleryItem] = useState('');
  const [dataItem, setDataItem] = useState({
    active: false,
    description: '',
    images: new Array<string>(),
  });

  const configGenetik: Record<string, string> = {
    indica: 'Indica',
    sativa: 'Sativa',
    hybrid: 'Hybrid',
    hybrid_indica: 'Hybrid (indica-dominant)',
    hybrid_sativa: 'Hybride (sativa-dominant)',
    na: 'k.A.',
  };

  const getExtractsID = useCallback(
    (id) =>
      dispatch(actions.request.customersActions.extracts.getExtractsID.get(id)),
    [dispatch],
  );
  const putExtractsID = useCallback(
    (id, data) =>
      dispatch(
        actions.request.customersActions.extracts.putExtractsID.put(id, data),
      ),
    [dispatch],
  );
  const deleteExtractsID = useCallback(
    (id) =>
      dispatch(
        actions.request.customersActions.extracts.deleteExtractsID.delete(id),
      ),
    [dispatch],
  );
  const getUploads = useCallback(
    () => dispatch(actions.request.uploadsActions.getUploads.get()),
    [dispatch],
  );
  const postUploads = useCallback(
    (file) => dispatch(actions.request.uploadsActions.postUploads.post(file)),
    [dispatch],
  );

  const extract = useSelector(
    reducers.request.customersReducers.extractsState.getID.value,
  );
  const uploads: string[] = useSelector(
    reducers.request.uploadsState.getUploads.value,
  );

  useEffect(() => {
    getExtractsID(id);
  }, []);

  useEffect(() => {
    if (Object.keys(extract).length === 0) return;

    setDataItem(extract);

    if (extract.images.length)
      setSelectedImage({ index: 0, src: extract.images[0] });
  }, [extract]);

  useEffect(() => {
    if (isGalleryOpen) getUploads();
  }, [isGalleryOpen]);

  const onUploadImage = (event: any) => {
    const formData = new FormData();
    const { files } = event.target;

    formData.append('file', files[0]);

    postUploads(formData).then((response: any) => {
      if (response.type !== 'POST_UPLOADS_SUCCESS') {
        return;
      }

      updateImagesList(response.payload);
      setIsImageOpen(false);
    });
  };

  const updateImagesList = (image: string) => {
    if (dataItem.images[selectedImage.index]) {
      // Add image instead of existing image
      const images = [...dataItem.images];
      images[selectedImage.index] = image;
      setDataItem({ ...dataItem, images });
      setSelectedImage({ ...selectedImage, src: image });
    } else {
      // Add image to free position
      setDataItem({ ...dataItem, images: [...dataItem.images, image] });
      setSelectedImage({ index: dataItem.images.length, src: image });
    }
  };

  const removeImage = (event: any, removedIndex: number) => {
    event.stopPropagation();

    const images = dataItem.images.filter((_, index) => index !== removedIndex);
    setDataItem({
      ...dataItem,
      images,
    });
    setSelectedImage({ index: 0, src: images[0] ?? '' });
  };

  const renderImages = () => {
    const images = [];

    for (let i = 0; i < 5; i++) {
      const isImageExists = dataItem.images[i] !== undefined;
      images.push(
        <div
          className={`customer-extract-edit__info-left-gallery-item ${
            selectedImage.index === i &&
            'customer-extract-edit__info-left-gallery-item--active'
          }`}
          key={i}
          onClick={() =>
            setSelectedImage({
              src: dataItem.images[i] ?? '',
              index: i,
            })
          }
        >
          {isImageExists && (
            <>
              <img
                src={DeleteFieldIcon}
                alt='close'
                className='customer-extract-edit__info-left-gallery-item-delete'
                onClick={(event) => removeImage(event, i)}
              />
              <img
                src={getImageUrl(dataItem.images[i])}
                alt=''
                className='customer-extract-edit__info-left-gallery-item-image'
              />
            </>
          )}
        </div>,
      );
    }

    return images;
  };

  return (
    <>
      <section className='customer-extract-edit'>
        <div className='container'>
          <button
            className='customer-extract-edit__back'
            type='button'
            onClick={() => history.push(`/customer/extracts/${id}`)}
          >
            Zurück
          </button>
          <h1 className='customer-extract-edit__title'>Extrakt bearbeiten</h1>
          <form action='#' className='customer-extract-edit__form'>
            <div className='customer-extract-edit__info'>
              <div className='customer-extract-edit__info-left'>
                <div className='customer-extract-edit__info-left-top'>
                  <div className='customer-extract-edit__info-left-info'>
                    <p className='customer-extract-edit__info-left-subtitle'>
                      Extraktname:
                    </p>
                    <p className='customer-extract-edit__info-left-title'>
                      {extract.name}
                    </p>
                    <p className='customer-extract-edit__info-left-paragraph'>
                      Sichtbar im Shop?
                    </p>
                    <div className='customer-extract-edit__info-left-radio'>
                      <div className='customer-extract-edit__info-left-radio-field'>
                        <input
                          type='radio'
                          className='customer-extract-edit__info-left-radio-input'
                          id='yes'
                          name='visibility'
                          checked={dataItem.active}
                          onChange={() =>
                            setDataItem({ ...dataItem, active: true })
                          }
                        />
                        <label
                          htmlFor='yes'
                          className='customer-extract-edit__info-left-radio-label'
                        >
                          Ja (Aktiv)
                        </label>
                      </div>
                      <div className='customer-extract-edit__info-left-radio-field'>
                        <input
                          type='radio'
                          className='customer-extract-edit__info-left-radio-input'
                          id='no'
                          name='visibility'
                          checked={!dataItem.active}
                          onChange={() =>
                            setDataItem({ ...dataItem, active: false })
                          }
                        />
                        <label
                          htmlFor='no'
                          className='customer-extract-edit__info-left-radio-label'
                        >
                          Nein (Inaktiv)
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='customer-extract-edit__info-left-image'>
                    <div
                      className='customer-extract-edit__info-left-image-wrapper'
                      onClick={() => setIsImageOpen(true)}
                    >
                      {selectedImage.src !== '' ? (
                        <>
                          <img
                            src={getImageUrl(selectedImage.src)}
                            alt=''
                            className='customer-extract-edit__info-left-edit-image'
                          />
                          <img
                            src={EditIcon}
                            alt='edit'
                            className='customer-extract-edit__info-left-edit'
                          />
                        </>
                      ) : (
                        <>
                          <p className='customer-extract-edit__info-left-image-title'>
                            Produktbild/er hochladen (bis zu 5)
                          </p>
                          <p className='customer-extract-edit__info-left-image-subtitle'>
                            Größe 340x140px
                          </p>
                        </>
                      )}
                    </div>
                    <div className='customer-extract-edit__info-left-gallery'>
                      {renderImages()}
                    </div>
                  </div>
                </div>
                <div className='customer-extract-edit__info-left-description'>
                  <p className='customer-extract-edit__info-left-paragraph'>
                    Beschreibung hinzufügen
                  </p>
                  <textarea
                    name='customer-extract-description'
                    id='customer-extract-description'
                    className='customer-extract-edit__info-left-textarea'
                    value={dataItem.description}
                    onChange={(event) => {
                      setDataItem({
                        ...dataItem,
                        description: event.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className='customer-extract-edit__info-right'>
                <div className='customer-extract-edit__info-image-wrapper'>
                  <div className='customer-extract-edit__info-image-head'>
                    <img
                      src={getImageUrl(extract.companyImage)}
                      alt=''
                      className='customer-extract-edit__info-image-logo'
                    />
                    <p className='customer-extract-edit__info-image-text'>
                      <span>{extract.name}</span>
                      <br />
                      {extract.companyName}
                    </p>
                  </div>
                  <img
                    src={getImageUrl(extract.image) || ExtractIcon}
                    alt=''
                    className='customer-extract-edit__info-image'
                  />
                  <div className='customer-extract-edit__info-image-description'>
                    <span>{extract.kultivar || 'k.A.'}</span>
                    <br />
                    <p>{configGenetik[extract.genetik]}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='customer-extract-edit__buttons'>
              <button
                className='button button--dark customer-extract-edit__button customer-extract-edit__button--cancel'
                type='button'
                onClick={() => setRemoveExtractPopupInfo(true)}
              >
                Löschen
              </button>
              <button
                className='button button--green customer-extract-edit__button'
                type='button'
                onClick={() => {
                  putExtractsID(id, {
                    active: dataItem.active,
                    images: dataItem.images,
                    description: dataItem.description,
                  }).then(() => {
                    history.push(`/customer/extracts/${id}`);
                  });
                }}
              >
                Speichern
              </button>
            </div>
          </form>
        </div>
      </section>

      {removeExtractPopupInfo && (
        <Modal>
          <div className='customer-extracts__table-modal'>
            <p className='customer-extracts__table-modal-title'>
              Extrakt löschen?
            </p>
            <p className='customer-extracts__table-modal-paragraph'>
              Sie haben vor, das Extrakt "{extract.name}" zu löschen.
            </p>
            <p className='customer-extracts__table-modal-paragraph'>
              Diese Änderungen kann nicht rückgängig gemacht werden.
            </p>
            <div className='customer-extracts__table-modal-buttons'>
              <button
                className='button button--dark customer-extracts__table-modal-button customer-extracts__table-modal-button--delete'
                onClick={() => {
                  deleteExtractsID(id).then(() => {
                    setRemoveExtractPopupInfo(false);
                    history.push('/customer/extracts');
                  });
                }}
              >
                Löschen
              </button>
              <button
                className='button button--green customer-extracts__table-modal-button customer-extracts__table-modal-button--cancel'
                onClick={() => setRemoveExtractPopupInfo(false)}
              >
                Abbrechen
              </button>
            </div>
          </div>
        </Modal>
      )}

      {isImageOpen && (
        <Modal>
          <div className='bud__upload'>
            <h1 className='bud__upload-title'>Bild hochladen:</h1>
            <div className='bud__upload-buttons'>
              <button
                className='button button--dark bud__upload-button'
                type='button'
                onClick={() => {
                  setIsImageOpen(false);
                  setIsGalleryOpen(true);
                }}
              >
                Von Galerie wählen
              </button>
              <input
                type='file'
                className='bud__upload-button bud__upload-button--input'
                onChange={(event) => onUploadImage(event)}
              />
            </div>
            <button
              className='bud__upload-close'
              type='button'
              onClick={() => setIsImageOpen(false)}
            >
              <img src={CloseIcon} alt='close' />
            </button>
          </div>
        </Modal>
      )}

      {isGalleryOpen && (
        <Modal mode='gallery'>
          <div className='bud__gallery'>
            <h1 className='bud__gallery-title'>Bild von Galerie auswählen</h1>
            <div className='bud__gallery-list'>
              {uploads.map((image) => (
                <div
                  className={[
                    'bud__gallery-item',
                    selectedGaleryItem === image && 'selected',
                  ]
                    .filter(Boolean)
                    .join(' ')}
                  key={image}
                  onClick={() => setSelectedGaleryItem(image)}
                >
                  <img
                    src={getImageUrl(image)}
                    alt='gallery item'
                    className='bud__gallery-image'
                  />
                </div>
              ))}
            </div>
            <div className='bud__gallery-buttons'>
              <button
                className='bud__gallery-button button button--dark'
                type='button'
                onClick={() => {
                  setIsGalleryOpen(false);
                  setSelectedGaleryItem('');
                }}
              >
                Schließen
              </button>
              <button
                className='bud__gallery-button button button--green'
                type='button'
                onClick={() => {
                  updateImagesList(selectedGaleryItem);
                  setIsGalleryOpen(false);
                  setSelectedGaleryItem('');
                }}
              >
                Anwenden
              </button>
            </div>
            <button
              className='bud__gallery-close'
              type='button'
              onClick={() => setIsGalleryOpen(false)}
            >
              <img src={CloseIcon} alt='close' />
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ExtractEdit;
