export const buds = {
  GET_BUDS_ALL_SUCCESS: 'GET_BUDS_ALL_SUCCESS',
  GET_BUDS_ALL_FAILED: 'GET_BUDS_ALL_FAILED',
  GET_BUDS_ALL_PENDING: 'GET_BUDS_ALL_PENDING',

  GET_BUDS_ID_SUCCESS: 'GET_BUDS_ID_SUCCESS',
  GET_BUDS_ID_FAILED: 'GET_BUDS_ID_FAILED',
  GET_BUDS_ID_PENDING: 'GET_BUDS_ID_PENDING',

  PUT_BUDS_ID_SUCCESS: 'PUT_BUDS_ID_SUCCESS',
  PUT_BUDS_ID_FAILED: 'PUT_BUDS_ID_FAILED',
  PUT_BUDS_ID_PENDING: 'PUT_BUDS_ID_PENDING',

  DELETE_BUDS_ID_SUCCESS: 'DELETE_BUDS_ID_SUCCESS',
  DELETE_BUDS_ID_FAILED: 'DELETE_BUDS_ID_FAILED',
  DELETE_BUDS_ID_PENDING: 'DELETE_BUDS_ID_PENDING',

  GET_BUDS_SUCCESS: 'GET_BUDS_SUCCESS',
  GET_BUDS_FAILED: 'GET_BUDS_FAILED',
  GET_BUDS_PENDING: 'GET_BUDS_PENDING',

  POST_BUDS_SUCCESS: 'POST_BUDS_SUCCESS',
  POST_BUDS_FAILED: 'POST_BUDS_FAILED',
  POST_BUDS_PENDING: 'POST_BUDS_PENDING',

  DELETE_BUDS_SUCCESS: 'DELETE_BUDS_SUCCESS',
  DELETE_BUDS_FAILED: 'DELETE_BUDS_FAILED',
  DELETE_BUDS_PENDING: 'DELETE_BUDS_PENDING',
};
