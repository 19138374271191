import React, { useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import './style.scss';

import CustomCheckbox from '../customCheckbox';
import ListTimePeriod from '../listTimePeriod';

interface Props {
  title: string;
  configData: any;
  options: any;
  settingsCheckbox: any;
  getData: Function;
  requestData: any;
}

const LineChart: React.FC<Props> = ({
  title,
  configData,
  options,
  settingsCheckbox,
  getData,
  requestData,
}): JSX.Element => {
  const [showCheckbox, setShowCheckbox] = useState<any>({});
  const [timePeriod, setTimePeriod] = useState('7d');

  useEffect(() => {
    getData(timePeriod);
  }, [timePeriod]);

  useEffect(() => {
    const newShowCheckbox: any = {};

    settingsCheckbox.forEach(
      (item: any) => (newShowCheckbox[item.name] = true),
    );

    setShowCheckbox(newShowCheckbox);
  }, []);

  const newConfigData = useMemo(() => {
    if (!requestData) return undefined;

    return {
      ...configData,
      labels: (Object.values(requestData)[0] as any).map((item: any) => {
        if (timePeriod === '7d') {
          return new Date(item.date).toLocaleString('en-us', {
            weekday: 'short',
          });
        } else if (timePeriod === '30d') {
          return new Date(item.date).getDate();
        } else if (timePeriod === '6m' || timePeriod === '12m') {
          return new Date(item.date).toLocaleString('en-us', {
            month: 'short',
          });
        }
      }),
      datasets: configData.datasets
        .map((item: any, index: number) => ({
          ...item,
          label: Object.keys(requestData)[index],
        }))
        .map((item: any) => ({
          ...item,
          data: requestData[item.label].map((item: any) => {
            if ('count' in item) return item.count;
            if ('amount' in item) return item.amount;
          }),
        })),
    };
  }, [requestData]);

  const showHideConfigData = {
    ...newConfigData,
    datasets: newConfigData?.datasets.filter(
      (index: any) => showCheckbox[index.label],
    ),
  };

  console.log(showHideConfigData, 'showHideConfigData');

  return (
    <div className='charts__item line-chart'>
      <p className='charts__item-title'>
        {title}

        <ListTimePeriod value={timePeriod} setValue={setTimePeriod} />
      </p>

      {newConfigData && (
        <Line
          data={showHideConfigData}
          options={options}
          className='charts__item-graphs'
        />
      )}

      <ul className='settings-list'>
        {settingsCheckbox.map((item: any, index: number) => (
          <li className='settings-item' key={index}>
            <CustomCheckbox
              item={item}
              showCheckbox={showCheckbox}
              setShowCheckbox={setShowCheckbox}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LineChart;
