import React, {
  useState,
  useRef,
  useLayoutEffect,
  useCallback,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../../store/actions';
import * as reducers from '../../../store/reducers';

import './SettingsPrescription.scss';

const SettingsPrescription = () => {
  const dispatch: any = useDispatch();

  const customer = useSelector(
    reducers.request.customersReducers.customersState.get.value,
  );

  const [settings, setSettings] = useState({
    budsSliderStep: 1,
    extractsSliderStep: 1,
    erixaButton: false,
    printButton: false,
    copyButton: false,
  });

  const rangeRefExtracts = useRef<HTMLInputElement>(null);
  const rangeRefBuds = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setSettings({
      budsSliderStep: customer.budsSliderStep,
      extractsSliderStep: customer.extractsSliderStep,
      erixaButton: customer.erixaButton,
      printButton: customer.printButton,
      copyButton: customer.copyButton,
    });
  }, [customer]);

  useLayoutEffect(() => {
    if (!rangeRefBuds.current) return;

    rangeRefBuds.current.style.setProperty(
      '--value',
      settings.budsSliderStep.toString(),
    );
  }, [settings.budsSliderStep]);

  useLayoutEffect(() => {
    if (!rangeRefExtracts.current) return;

    rangeRefExtracts.current.style.setProperty(
      '--value',
      settings.extractsSliderStep.toString(),
    );
  }, [settings.extractsSliderStep]);

  const getCustomer = useCallback(
    () =>
      dispatch(actions.request.customersActions.customers.getCustomers.get()),
    [dispatch],
  );
  const putCustomerSettings = useCallback(
    (data) =>
      dispatch(
        actions.request.customersActions.customers.putCustomersSettings.put(
          data,
        ),
      ),
    [dispatch],
  );

  const handleSubmit = () => {
    putCustomerSettings(settings).then(() => getCustomer());
  };

  return (
    <div className='customer-settings-prescription'>
      <div className='container'>
        <div className='customer-settings-prescription__inner'>
          <div className='customer-settings-prescription__block'>
            <p className='customer-settings-prescription__title'>Blüten</p>
            <div className='customer-settings-prescription__slider'>
              <div className='customer-settings-prescription__slider-top'>
                <p className='customer-settings-prescription__slider-title'>
                Schrittmenge
                </p>
                <div className='customer-settings-prescription__slider-number'>
                  {settings.budsSliderStep}
                </div>
              </div>
              <div className='customer-settings-prescription__slider-range'>
                <input
                  type='range'
                  className='customer-settings-prescription__slider-input styled-slider slider-progress'
                  min='1'
                  max='15'
                  step='1'
                  value={settings.budsSliderStep}
                  ref={rangeRefBuds}
                  id='buds-range'
                  onChange={(event: any) => {
                    setSettings({
                      ...settings,
                      budsSliderStep: Number(event.target.value),
                    });
                  }}
                />
              </div>
              <div className='customer-settings-prescription__slider-bottom'>
                <p>1</p>
                <p>15</p>
              </div>
            </div>
          </div>
          <div className='customer-settings-prescription__block'>
            <p className='customer-settings-prescription__title'>Extrakte</p>
            <div className='customer-settings-prescription__slider'>
              <div className='customer-settings-prescription__slider-top'>
                <p className='customer-settings-prescription__slider-title'>
                  Schrittmenge
                </p>
                <div className='customer-settings-prescription__slider-number'>
                  {settings.extractsSliderStep}
                </div>
              </div>
              <div className='customer-settings-prescription__slider-range'>
                <input
                  type='range'
                  className='customer-settings-prescription__slider-input styled-slider slider-progress'
                  min='1'
                  max='15'
                  step='1'
                  value={settings.extractsSliderStep}
                  ref={rangeRefExtracts}
                  id='extracts-range'
                  onChange={(event: any) => {
                    setSettings({
                      ...settings,
                      extractsSliderStep: Number(event.target.value),
                    });
                  }}
                />
              </div>
              <div className='customer-settings-prescription__slider-bottom'>
                <p>1</p>
                <p>15</p>
              </div>
            </div>
          </div>
          <div className='customer-settings-prescription-block'>
            <p className='customer-settings-prescription__title'>
            Welche Funktionen sollen angezeigt werden?
            </p>
            <div className='customer-settings-prescription__option'>
              <p className='customer-settings-prescription__option-text'>
              Als E-Rezept an Patient senden
              </p>
              <label className='form-switch'>
                <input
                  type='checkbox'
                  checked={settings.erixaButton}
                  onChange={(event) => {
                    setSettings({
                      ...settings,
                      erixaButton: event.target.checked,
                    });
                  }}
                />
                <i />
              </label>
            </div>
            <div className='customer-settings-prescription__option'>
              <p className='customer-settings-prescription__option-text'>
                Rezept ausdrucken
              </p>
              <label className='form-switch'>
                <input
                  type='checkbox'
                  checked={settings.printButton}
                  onChange={(event) => {
                    setSettings({
                      ...settings,
                      printButton: event.target.checked,
                    });
                  }}
                />
                <i />
              </label>
            </div>
            <div className='customer-settings-prescription__option'>
              <p className='customer-settings-prescription__option-text'>
                Angaben kopieren
              </p>
              <label className='form-switch'>
                <input
                  type='checkbox'
                  checked={settings.copyButton}
                  onChange={(event) => {
                    setSettings({
                      ...settings,
                      copyButton: event.target.checked,
                    });
                  }}
                />
                <i />
              </label>
            </div>
          </div>
        </div>
      </div>
      <button
        className='button button--green customer-settings-prescription__submit'
        onClick={handleSubmit}
      >
        Speichern
      </button>
    </div>
  );
};

export default SettingsPrescription;
