import * as types from '../../../types/request';

const initialState = {
  getPrescriptions: {
    amount: {
      data: null,
      status: null,
      error: null,
    },
    output: {
      data: null,
      status: null,
      error: null,
    },
  },

  getProducts: {
    amount: {
      data: null,
      status: null,
      error: null,
    },
    count: {
      data: null,
      status: null,
      error: null,
    },
  },
};

export const statisticsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    //----- GET_PRESCRIPTIONS_BY_AMOUNT
    case types.customersTypes.statistics.GET_PRESCRIPTIONS_BY_AMOUNT_SUCCESS:
      return {
        ...state,
        getPrescriptions: {
          ...state.getPrescriptions,
          amount: {
            ...state.getPrescriptions.amount,
            status: 'success',
            data: action.payload,
          },
        },
      };
    case types.customersTypes.statistics.GET_PRESCRIPTIONS_BY_AMOUNT_FAILED:
      return {
        ...state,
        getPrescriptions: {
          ...state.getPrescriptions,
          amount: {
            ...state.getPrescriptions.amount,
            status: 'failed',
            error: action.payload,
          },
        },
      };
    case types.customersTypes.statistics.GET_PRESCRIPTIONS_BY_AMOUNT_PENDING:
      return {
        ...state,
        getPrescriptions: {
          ...state.getPrescriptions,
          amount: {
            ...state.getPrescriptions.amount,
            status: 'pending',
            error: null,
          },
        },
      };

    //----- GET_PRESCRIPTIONS_BY_OUTPUT
    case types.customersTypes.statistics.GET_PRESCRIPTIONS_BY_OUTPUT_SUCCESS:
      return {
        ...state,
        getPrescriptions: {
          ...state.getPrescriptions,
          output: {
            ...state.getPrescriptions.output,
            status: 'success',
            data: action.payload,
          },
        },
      };
    case types.customersTypes.statistics.GET_PRESCRIPTIONS_BY_OUTPUT_FAILED:
      return {
        ...state,
        getPrescriptions: {
          ...state.getPrescriptions,
          output: {
            ...state.getPrescriptions.output,
            status: 'failed',
            error: action.payload,
          },
        },
      };
    case types.customersTypes.statistics.GET_PRESCRIPTIONS_BY_OUTPUT_PENDING:
      return {
        ...state,
        getPrescriptions: {
          ...state.getPrescriptions,
          output: {
            ...state.getPrescriptions.output,
            status: 'pending',
            error: null,
          },
        },
      };

    //----- GET_PRODUCTS_AND_AMOUNT
    case types.customersTypes.statistics.GET_PRODUCTS_AND_AMOUNT_SUCCESS:
      return {
        ...state,
        getProducts: {
          ...state.getProducts,
          amount: {
            ...state.getProducts.amount,
            status: 'success',
            data: action.payload,
          },
        },
      };
    case types.customersTypes.statistics.GET_PRODUCTS_AND_AMOUNT_FAILED:
      return {
        ...state,
        getProducts: {
          ...state.getProducts,
          amount: {
            ...state.getProducts.amount,
            status: 'failed',
            error: action.payload,
          },
        },
      };
    case types.customersTypes.statistics.GET_PRODUCTS_AND_AMOUNT_PENDING:
      return {
        ...state,
        getProducts: {
          ...state.getProducts,
          amount: {
            ...state.getProducts.amount,
            status: 'pending',
            error: null,
          },
        },
      };

    //----- GET_PRODUCTS_AND_COUNT
    case types.customersTypes.statistics.GET_PRODUCTS_AND_COUNT_SUCCESS:
      return {
        ...state,
        getProducts: {
          ...state.getProducts,
          count: {
            ...state.getProducts.count,
            status: 'success',
            data: action.payload,
          },
        },
      };
    case types.customersTypes.statistics.GET_PRODUCTS_AND_COUNT_FAILED:
      return {
        ...state,
        getProducts: {
          ...state.getProducts,
          count: {
            ...state.getProducts.count,
            status: 'failed',
            error: action.payload,
          },
        },
      };
    case types.customersTypes.statistics.GET_PRODUCTS_AND_COUNT_PENDING:
      return {
        ...state,
        getProducts: {
          ...state.getProducts,
          count: {
            ...state.getProducts.count,
            status: 'pending',
            error: null,
          },
        },
      };

    default: {
      return state;
    }
  }
};

export const statisticsState = {
  put: {
    status: (state: any) => state.requestData.customers.forms.putID.status,
    error: (state: any) => state.requestData.customers.forms.putID.error,
  },

  getPrescriptions: {
    amount: {
      data: (state: any) =>
        state.requestData.customers.statistics.getPrescriptions.amount.data,
      status: (state: any) =>
        state.requestData.customers.statistics.getPrescriptions.amount.status,
      error: (state: any) =>
        state.requestData.customers.statistics.getPrescriptions.amount.error,
    },
    output: {
      data: (state: any) =>
        state.requestData.customers.statistics.getPrescriptions.output.data,
      status: (state: any) =>
        state.requestData.customers.statistics.getPrescriptions.output.status,
      error: (state: any) =>
        state.requestData.customers.statistics.getPrescriptions.output.error,
    },
  },

  getProducts: {
    amount: {
      data: (state: any) =>
        state.requestData.customers.statistics.getProducts.amount.data,
      status: (state: any) =>
        state.requestData.customers.statistics.getProducts.amount.status,
      error: (state: any) =>
        state.requestData.customers.statistics.getProducts.amount.error,
    },
    count: {
      data: (state: any) =>
        state.requestData.customers.statistics.getProducts.count.data,
      status: (state: any) =>
        state.requestData.customers.statistics.getProducts.count.status,
      error: (state: any) =>
        state.requestData.customers.statistics.getProducts.count.error,
    },
  },
};
