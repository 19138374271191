import React from 'react';

import './Pagination.scss';

import NextPage from '../../../images/symbols/next-page.svg';

interface Props {
  itemsPerPage: number;
  currentPage: number;
  setCurrentPage: any;
  totalItems: number;
  paginate: any;
}

const Pagination: React.FC<Props> = ({
  itemsPerPage,
  currentPage,
  setCurrentPage,
  totalItems,
  paginate,
}): JSX.Element => {
  totalItems |= 1;
  const pageNumbers: number[] = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className='customer-pagination'>
      <button
        className='button customer-pagination__prev'
        onClick={() => {
          if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
          }
        }}
      >
        <img
          src={NextPage}
          alt='previous page'
          className='customer-pagination__prev-img'
        />
      </button>
      <ul className='customer-pagination__list'>
        {pageNumbers.map((number) => (
          <li key={number} className='customer-pagination__item'>
            <button
              className={
                number !== currentPage
                  ? 'button customer-pagination__link'
                  : 'button customer-pagination__link customer-pagination__link--active'
              }
              onClick={() => {
                paginate(number);
              }}
            >
              {number}
            </button>
          </li>
        ))}
      </ul>
      <button
        className='button customer-pagination__next'
        onClick={() => {
          if (currentPage < pageNumbers.length) {
            setCurrentPage(currentPage + 1);
          }
        }}
      >
        <img src={NextPage} alt='next page' />
      </button>
    </div>
  );
};

export default Pagination;
