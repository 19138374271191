export const buds = {
  GET_BUDS_ALL_SUCCESS: Symbol('GET_BUDS_ALL_SUCCESS'),
  GET_BUDS_ALL_FAILED: Symbol('GET_BUDS_ALL_FAILED'),
  GET_BUDS_ALL_PENDING: Symbol('GET_BUDS_ALL_PENDING'),

  GET_BUDS_ID_SUCCESS: Symbol('GET_BUDS_ID_SUCCESS'),
  GET_BUDS_ID_FAILED: Symbol('GET_BUDS_ID_FAILED'),
  GET_BUDS_ID_PENDING: Symbol('GET_BUDS_ID_PENDING'),

  PUT_BUDS_ID_SUCCESS: Symbol('PUT_BUDS_ID_SUCCESS'),
  PUT_BUDS_ID_FAILED: Symbol('PUT_BUDS_ID_FAILED'),
  PUT_BUDS_ID_PENDING: Symbol('PUT_BUDS_ID_PENDING'),

  DELETE_BUDS_ID_SUCCESS: Symbol('DELETE_BUDS_ID_SUCCESS'),
  DELETE_BUDS_ID_FAILED: Symbol('DELETE_BUDS_ID_FAILED'),
  DELETE_BUDS_ID_PENDING: Symbol('DELETE_BUDS_ID_PENDING'),

  GET_BUDS_SUCCESS: Symbol('GET_BUDS_SUCCESS'),
  GET_BUDS_FAILED: Symbol('GET_BUDS_FAILED'),
  GET_BUDS_PENDING: Symbol('GET_BUDS_PENDING'),

  DELETE_BUDS_SUCCESS: Symbol('DELETE_BUDS_SUCCESS'),
  DELETE_BUDS_FAILED: Symbol('DELETE_BUDS_FAILED'),
  DELETE_BUDS_PENDING: Symbol('DELETE_BUDS_PENDING'),
};
