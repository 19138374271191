import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../../store/actions';
import * as reducers from '../../../store/reducers';
import { DosagesType } from '../../../types';

import './ExtractsDosage.scss';

import DeleteIcon from '../../../images/symbols/delete.svg';
import CloseIcon from '../../../images/symbols/close.svg';
import Modal from '../Modal';

const ExtractsDosage: React.FC = (): JSX.Element => {
  const dispatch: any = useDispatch();

  const [searchQuery, setSearchQuery] = useState('');
  const [removeDosagePopupInfo, setRemoveDosagePopupInfo] = useState({
    id: 0,
    item: '',
    isOpen: false,
  });

  const getDosages = useCallback(
    (searchQuery) =>
      dispatch(
        actions.request.customersActions.dosages.getDosages.get(
          'extract',
          searchQuery,
        ),
      ),
    [dispatch],
  );
  const deleteDosagesID = useCallback(
    (id) =>
      dispatch(
        actions.request.customersActions.dosages.deleteDosagesID.delete(id),
      ),
    [dispatch],
  );

  const dosages: DosagesType[] = useSelector(
    reducers.request.customersReducers.dosagesState.get.value,
  ).filter((dosage: DosagesType) => dosage.shown);

  useEffect(() => {
    getDosages(searchQuery);
  }, [searchQuery]);

  const handleInput = (event: any) => {
    setSearchQuery(event.target.value);
  };

  return (
    <>
      <section className='customer-extracts__dosage'>
        <div className='customer-extracts__dosage-search'>
          <form action='#'>
            <div className='customer-extracts__dosage-field'>
              <input
                type='text'
                placeholder='Suchen'
                className='customer-extracts__dosage-input'
                value={searchQuery}
                onChange={handleInput}
              />
            </div>
          </form>
        </div>
        {dosages.map((dosage) => (
          <div
            className='customer-extracts__dosage-item'
            key={dosage.id}
            data-url={dosage.id}
          >
            <button
              className='button customer-extracts__dosage-item-delete'
              onClick={() => {
                setRemoveDosagePopupInfo({
                  id: dosage.id,
                  item: dosage.name,
                  isOpen: true,
                });
              }}
            >
              <img src={DeleteIcon} alt='delete item' />
            </button>
            <p className='customer-extracts__dosage-item-title'>
              {dosage.name}
            </p>
            <p className='customer-extracts__dosage-item-description'>
              {dosage.dosageFields
                .map((field) =>
                  [field.prefix, field.value, field.sufix].join(''),
                )
                .join('')}
            </p>
          </div>
        ))}
      </section>

      {removeDosagePopupInfo.isOpen && (
        <Modal>
          <div className='customer-extracts__table-modal'>
            <button
              className='customer-extracts__table-modal-close'
              onClick={() =>
                setRemoveDosagePopupInfo({ id: 0, item: '', isOpen: false })
              }
            >
              <img src={CloseIcon} alt='close' />
            </button>
            <p className='customer-extracts__table-modal-title'>
              Anleitung löschen?
            </p>
            <p className='customer-extracts__table-modal-paragraph'>
              Sie haben vor, die Dosierung "{removeDosagePopupInfo.item}"
              zu löschen.
            </p>
            <p className='customer-extracts__table-modal-paragraph'>
              Diese Änderungen kann nicht rückgängig gemacht werden.
            </p>
            <div className='customer-extracts__table-modal-buttons'>
              <button
                className='button customer-extracts__table-modal-button customer-extracts__table-modal-button--delete'
                onClick={() =>
                  deleteDosagesID(removeDosagePopupInfo.id).then(() => {
                    setRemoveDosagePopupInfo({
                      id: 0,
                      item: '',
                      isOpen: false,
                    });
                    getDosages(searchQuery);
                  })
                }
              >
                Löschen
              </button>
              <button
                className='button customer-extracts__table-modal-button customer-extracts__table-modal-button--cancel'
                onClick={() =>
                  setRemoveDosagePopupInfo({ id: 0, item: '', isOpen: false })
                }
              >
                Abbrechen
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ExtractsDosage;
