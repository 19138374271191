import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as reducers from '../../../store/reducers';

import './style.scss';

import BudsIcon from '../../../images/bud-icon.svg';
import ExtractIcon from '../../../images/extract-icon.svg';
import Charts from '../Charts';

const Main: React.FC = (): JSX.Element => {
  const info = useSelector(
    reducers.request.customersReducers.customersState.getInfo.value,
  );

  return (
    <main className='customer-dashboard'>
      <div className='container'>
        <div className='products'>
          <ul className='product-list'>
            <li className='product-item'>
              <NavLink to='/customer/buds'>
                <figure>
                  <p className='img'>
                    <img src={BudsIcon} alt='buds-icon' />
                  </p>
                  <figcaption className='title'>
                    Blüten{' '}
                    <span className='count'>Anzahl Blüten: {info.budsAmount}</span>
                  </figcaption>
                </figure>
              </NavLink>
            </li>

            <li className='product-item'>
              <NavLink to='/customer/extracts'>
                <figure>
                  <p className='img'>
                    <img src={ExtractIcon} alt='extracts-icon' />
                  </p>
                  <figcaption className='title'>
                    Extrakte
                    <span className='count'>
                      Anzahl Extrakte: {info.extractsAmount}
                    </span>
                  </figcaption>
                </figure>
              </NavLink>
            </li>
          </ul>
        </div>

        <Charts />
      </div>
    </main>
  );
};

export default Main;
