import { Label } from './label';

export class LabelsPlugin {
  static SUPPORTED_TYPES = {
    pie: true,
    doughnut: true,
    polarArea: true,
    bar: true,
  };

  id = 'labels';

  beforeDatasetsUpdate(chart, args, options) {
    if (!LabelsPlugin.SUPPORTED_TYPES[chart.config.type]) {
      return;
    }
    if (!Array.isArray(options)) {
      options = [options];
    }
    const count = options.length;
    if (!chart._labels || count !== chart._labels.length) {
      chart._labels = options.map(() => new Label());
    }
    let someOutside = false,
      maxPadding = 0;
    for (let i = 0; i < count; ++i) {
      const label = chart._labels[i];
      label.setup(chart, options[i]);
      if (label.options.position === 'outside') {
        someOutside = true;
        let padding =
          label.options.fontSize * 1.5 + label.options.outsidePadding;
        if (padding > maxPadding) {
          maxPadding = padding;
        }
      }
    }
    if (someOutside) {
      chart.chartArea.top += maxPadding;
      chart.chartArea.bottom -= maxPadding;
    }
  }

  afterDatasetUpdate(chart, args) {
    if (!LabelsPlugin.SUPPORTED_TYPES[chart.config.type]) {
      return;
    }
    chart._labels.forEach((label) => {
      label.args[args.index] = args;
    });
  }

  beforeDraw(chart) {
    if (!LabelsPlugin.SUPPORTED_TYPES[chart.config.type]) {
      return;
    }
    chart._labels.forEach((label) => {
      label.barTotalPercentage = {};
    });
  }

  afterDatasetsDraw(chart) {
    if (!LabelsPlugin.SUPPORTED_TYPES[chart.config.type]) {
      return;
    }
    chart._labels.forEach((label) => {
      label.render();
    });
  }
}
