import * as types from '../../../types/request';

const initialState = {
  getAll: {
    value: [],
    status: null,
    error: null,
  },

  getID: {
    value: {},
    status: null,
    error: null,
  },

  putID: {
    value: {},
    status: null,
    error: null,
  },

  deleteID: {
    status: null,
    error: null,
  },

  get: {
    value: [],
    total: 0,
    status: null,
    error: null,
  },

  post: {
    status: null,
    error: null,
  },
};

export const dosagesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    // ----- GET_DOSAGES_ALL_SUCCESS
    case types.adminTypes.dosages.GET_DOSAGES_ALL_SUCCESS:
      return {
        ...state,
        getAll: { ...state.getAll, status: 'success', value: action.payload },
      };
    case types.adminTypes.dosages.GET_DOSAGES_ALL_FAILED:
      return {
        ...state,
        getAll: { ...state.getAll, status: 'failed', error: action.payload },
      };
    case types.adminTypes.dosages.GET_DOSAGES_ALL_PENDING:
      return {
        ...state,
        getAll: { ...state.getAll, status: 'pending', error: null },
      };

    // ----- GET_DOSAGES_ID_SUCCESS
    case types.adminTypes.dosages.GET_DOSAGES_ID_SUCCESS:
      return {
        ...state,
        getID: { ...state.getID, status: 'success', value: action.payload },
      };
    case types.adminTypes.dosages.GET_DOSAGES_ID_FAILED:
      return {
        ...state,
        getID: { ...state.getID, status: 'failed', error: action.payload },
      };
    case types.adminTypes.dosages.GET_DOSAGES_ID_PENDING:
      return {
        ...state,
        getID: { ...state.getID, status: 'pending', error: null },
      };

    // ----- PUT_DOSAGES_ID_SUCCESS
    case types.adminTypes.dosages.PUT_DOSAGES_ID_SUCCESS:
      return {
        ...state,
        putID: { ...state.putID, status: 'success', value: action.payload },
      };
    case types.adminTypes.dosages.PUT_DOSAGES_ID_FAILED:
      return {
        ...state,
        putID: { ...state.putID, status: 'failed', error: action.payload },
      };
    case types.adminTypes.dosages.PUT_DOSAGES_ID_PENDING:
      return {
        ...state,
        putID: { ...state.putID, status: 'pending', error: null },
      };

    // ----- DELETE_DOSAGES_ID_SUCCESS
    case types.adminTypes.dosages.DELETE_DOSAGES_ID_SUCCESS:
      return { ...state, deleteID: { ...state.deleteID, status: 'success' } };
    case types.adminTypes.dosages.DELETE_DOSAGES_ID_FAILED:
      return {
        ...state,
        deleteID: {
          ...state.deleteID,
          status: 'failed',
          error: action.payload,
        },
      };
    case types.adminTypes.dosages.DELETE_DOSAGES_ID_PENDING:
      return {
        ...state,
        deleteID: { ...state.deleteID, status: 'pending', error: null },
      };

    // ----- GET_DOSAGES_SUCCESS
    case types.adminTypes.dosages.GET_DOSAGES_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          status: 'success',
          value: action.payload.dosages,
          total: action.payload.total,
        },
      };
    case types.adminTypes.dosages.GET_DOSAGES_FAILED:
      return {
        ...state,
        get: { ...state.get, status: 'failed', error: action.payload },
      };
    case types.adminTypes.dosages.GET_DOSAGES_PENDING:
      return {
        ...state,
        get: { ...state.get, status: 'pending', error: null },
      };

    // ----- POST_DOSAGES_SUCCESS
    case types.adminTypes.dosages.POST_DOSAGES_SUCCESS:
      return { ...state, post: { ...state.post, status: 'success' } };
    case types.adminTypes.dosages.POST_DOSAGES_FAILED:
      return {
        ...state,
        post: { ...state.post, status: 'failed', error: action.payload },
      };
    case types.adminTypes.dosages.POST_DOSAGES_PENDING:
      return {
        ...state,
        post: { ...state.post, status: 'pending', error: null },
      };

    default: {
      return state;
    }
  }
};

export const dosagesState = {
  getAll: {
    value: (state: any) => state.requestData.admin.dosages.getAll.value,
    status: (state: any) => state.requestData.admin.dosages.getAll.status,
    error: (state: any) => state.requestData.admin.dosages.getAll.error,
  },

  getID: {
    value: (state: any) => state.requestData.admin.dosages.getID.value,
    status: (state: any) => state.requestData.admin.dosages.getID.status,
    error: (state: any) => state.requestData.admin.dosages.getID.error,
  },

  putID: {
    value: (state: any) => state.requestData.admin.dosages.putID.value,
    status: (state: any) => state.requestData.admin.dosages.putID.status,
    error: (state: any) => state.requestData.admin.dosages.putID.error,
  },

  deleteID: {
    status: (state: any) => state.requestData.admin.dosages.deleteID.status,
    error: (state: any) => state.requestData.admin.dosages.deleteID.error,
  },

  get: {
    value: (state: any) => state.requestData.admin.dosages.get.value,
    total: (state: any) => state.requestData.admin.dosages.get.total,
    status: (state: any) => state.requestData.admin.dosages.get.status,
    error: (state: any) => state.requestData.admin.dosages.get.error,
  },

  post: {
    status: (state: any) => state.requestData.admin.dosages.post.status,
    error: (state: any) => state.requestData.admin.dosages.post.error,
  },
};
