import React from 'react';
import { useHistory } from 'react-router-dom';

import './ExtractsHead.scss';

interface Props {
  buttonText: string;
  tab: number;
}

const ExtractsHead: React.FC<Props> = ({ buttonText, tab }): JSX.Element => {
  const history = useHistory();

  const redirect = () => {
    let path: string = '';
    if (tab === 0) {
      path = `/admin/extracts/new`;
    }

    if (tab === 1) {
      path = `/admin/extracts/dosage/new`;
    }

    history.push(path, { isNewPage: true });
  };

  return (
    <div className='extracts__head'>
      <h1 className='extracts__title'>Extrakte</h1>
      <button
        className='button button--green extracts__create'
        onClick={() => {
          redirect();
        }}
      >
        {buttonText}
      </button>
    </div>
  );
};

export default ExtractsHead;
