export const customers = {
  GET_CUSTOMERS_INFO_SUCCESS: Symbol('GET_CUSTOMERS_INFO_SUCCESS'),
  GET_CUSTOMERS_INFO_FAILED: Symbol('GET_CUSTOMERS_INFO_FAILED'),
  GET_CUSTOMERS_INFO_PENDING: Symbol('GET_CUSTOMERS_INFO_PENDING'),

  PUT_CUSTOMERS_SUCCESS: Symbol('PUT_CUSTOMERS_SUCCESS'),
  PUT_CUSTOMERS_FAILED: Symbol('PUT_CUSTOMERS_FAILED'),
  PUT_CUSTOMERS_PENDING: Symbol('PUT_CUSTOMERS_PENDING'),

  GET_CUSTOMERS_SUCCESS: Symbol('GET_CUSTOMERS_SUCCESS'),
  GET_CUSTOMERS_FAILED: Symbol('GET_CUSTOMERS_FAILED'),
  GET_CUSTOMERS_PENDING: Symbol('GET_CUSTOMERS_PENDING'),

  PUT_CUSTOMERS_DESCRIPTIONS_SUCCESS: Symbol(
    'PUT_CUSTOMERS_DESCRIPTIONS_SUCCESS',
  ),
  PUT_CUSTOMERS_DESCRIPTIONS_FAILED: Symbol(
    'PUT_CUSTOMERS_DESCRIPTIONS_FAILED',
  ),
  PUT_CUSTOMERS_DESCRIPTIONS_PENDING: Symbol(
    'PUT_CUSTOMERS_DESCRIPTIONS_PENDING',
  ),

  PUT_CUSTOMERS_SETTINGS_SUCCESS: Symbol('PUT_CUSTOMERS_SETTINGS_SUCCESS'),
  PUT_CUSTOMERS_SETTINGS_FAILED: Symbol('PUT_CUSTOMERS_SETTINGS_FAILED'),
  PUT_CUSTOMERS_SETTINGS_PENDING: Symbol('PUT_CUSTOMERS_SETTINGS_PENDING'),

  POST_CUSTOMERS_MAIL_SUCCESS: Symbol('POST_CUSTOMERS_MAIL_SUCCESS'),
  POST_CUSTOMERS_MAIL_FAILED: Symbol('POST_CUSTOMERS_MAIL_FAILED'),
  POST_CUSTOMERS_MAIL_PENDING: Symbol('POST_CUSTOMERS_MAIL_PENDING'),
};
