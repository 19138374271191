export const getGenetik = (genetik: string): string => {
  const configGenetik: Record<string, string> = {
    indica: 'Indica',
    sativa: 'Sativa',
    hybrid: 'Hybrid',
    hybrid_indica: 'Hybrid (indica-dominant)',
    hybrid_sativa: 'Hybride (sativa-dominant)',
    na: 'k.A.',
  };

  return configGenetik[genetik];
};
