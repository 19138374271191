import React, { useState, useCallback, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../store/actions';
import * as reducers from '../../store/reducers';

import './style.scss';

const MainLogin: React.FC = (): JSX.Element => {
  const dispatch: any = useDispatch();

  const [formValues, setFormValues] = useState({ email: '', password: '' });
  const [error, setError] = useState(false);

  const loginStatus = useSelector(reducers.request.authState.login.status);

  const postLogin = useCallback(
    (data) => dispatch(actions.request.authActions.login.post(data)),
    [dispatch],
  );

  const onSetFormValues = (event: any) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
    setError(false);
  };

  const onSendFormValues = (event: any) => {
    event.preventDefault();
    setError(false);
    postLogin(formValues);
  };

  useEffect(() => {
    if (loginStatus === 'failed') {
      setError(true);
    }
  }, [loginStatus]);

  return (
    <main className='main-login-content'>
      <h1 className='main-login-content-title'></h1>

      <p className='main-login-content-message'></p>

      <form
        className='main-login-content-form login-form'
        onSubmit={onSendFormValues}
      >
        <p className='login-form-title'>Anmelden</p>

        <p className='login-form-field email'>
          <label htmlFor='field-email'>E-Mail</label>
          <input
            type='text'
            name='email'
            id='field-email'
            className={error ? 'border-error' : ''}
            value={formValues.email}
            onChange={onSetFormValues}
            placeholder='Unternehmens-E-Mail eingeben'
          />
          {error && (
            <p className='field-error login-form-field-error'>
              Die E-Mail oder das Passwort ist falsch
            </p>
          )}
        </p>

        <p className='login-form-field password'>
          <label htmlFor='field-password'>Passwort</label>
          <input
            type='password'
            name='password'
            id='field-password'
            className={error ? 'border-error' : ''}
            value={formValues.password}
            onChange={onSetFormValues}
            placeholder='Passwort eingeben'
          />
        </p>

        <NavLink to='/forgot' className='forgot-password-link'>
          Passwort vergessen?
        </NavLink>

        <button
          className='login-form-submit'
          type='submit'
          onSubmit={onSendFormValues}
        >
          Anmelden
        </button>
      </form>
    </main>
  );
};

export default MainLogin;
