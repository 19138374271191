import React from 'react';

import Header from '../../components/customer/Header';
import ExtractsComponent from '../../components/customer/Extracts';

const Extracts: React.FC = (): JSX.Element => {
  return (
    <>
      <Header />
      <ExtractsComponent />
    </>
  );
};

export default Extracts;
