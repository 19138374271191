import React from 'react';
import { useParams } from 'react-router-dom';

import BudDetails from '../../components/customer/BudDetails';
import Header from '../../components/customer/Header';

const Bud: React.FC = (): JSX.Element => {
  const { id } = useParams<any>();

  return (
    <>
      <Header />
      <BudDetails id={Number(id)} />
    </>
  );
};

export default Bud;
