import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../../store/actions';
import * as reducers from '../../../store/reducers';

import './SettingsEdit.scss';

const SettingsEdit = () => {
  const dispatch: any = useDispatch();

  const [tab, setTab] = useState(1);
  const [dataItem, setDataItem] = useState({
    homeTitle: '',
    homeDescription: '',
    budsDescription: '',
    extractsDescription: '',
  });

  const getInfo = useCallback(
    () =>
      dispatch(
        actions.request.customersActions.customers.getCustomersInfo.get(),
      ),
    [dispatch],
  );
  const putCustomersDescriptions = useCallback(
    (data) =>
      dispatch(
        actions.request.customersActions.customers.putCustomersDescriptions.put(
          data,
        ),
      ),
    [dispatch],
  );

  const info = useSelector(
    reducers.request.customersReducers.customersState.getInfo.value,
  );

  useEffect(() => {
    setDataItem({
      homeTitle: info.homeTitle,
      homeDescription: info.homeDescription,
      budsDescription: info.budsDescription,
      extractsDescription: info.extractsDescription,
    });
  }, [info]);

  const handleSubmit = () => {
    putCustomersDescriptions(dataItem).then(() => getInfo());
  };

  return (
    <section className='customer-settings-edit'>
      <div className='container'>
        <div className='customer-settings-edit__tabs'>
          <div
            className={
              tab === 1
                ? 'customer-settings-edit__tab customer-settings-edit__tab--active'
                : 'customer-settings-edit__tab'
            }
            onClick={() => setTab(1)}
          >
            Startseite
          </div>
          <div
            className={
              tab === 2
                ? 'customer-settings-edit__tab customer-settings-edit__tab--active'
                : 'customer-settings-edit__tab'
            }
            onClick={() => setTab(2)}
          >
            Blüten
          </div>
          <div
            className={
              tab === 3
                ? 'customer-settings-edit__tab customer-settings-edit__tab--active'
                : 'customer-settings-edit__tab'
            }
            onClick={() => setTab(3)}
          >
            Extrakte
          </div>
        </div>
        {tab === 1 && (
          <>
            <div className='customer-settings-edit__field'>
              <label className='customer-settings-edit__label' htmlFor='title'>
                Titel
              </label>
              <input
                type='text'
                id='title'
                className='customer-settings-edit__input'
                value={dataItem.homeTitle}
                onChange={(event) => {
                  setDataItem({ ...dataItem, homeTitle: event.target.value });
                }}
              />
            </div>
            <div className='customer-settings-edit__field'>
              <label
                htmlFor='description'
                className='customer-settings-edit__label'
              >
                Beschreibungstext
              </label>
              <textarea
                id='description'
                className='customer-settings-edit__textarea'
                value={dataItem.homeDescription}
                onChange={(event) => {
                  setDataItem({
                    ...dataItem,
                    homeDescription: event.target.value,
                  });
                }}
              />
            </div>
          </>
        )}
        {tab === 2 && (
          <>
            <div className='customer-settings-edit__field'>
              <label
                htmlFor='description'
                className='customer-settings-edit__label'
              >
                Beschreibungstext
              </label>
              <textarea
                id='description'
                className='customer-settings-edit__textarea'
                value={dataItem.budsDescription}
                onChange={(event) => {
                  setDataItem({
                    ...dataItem,
                    budsDescription: event.target.value,
                  });
                }}
              />
            </div>
          </>
        )}
        {tab === 3 && (
          <>
            <div className='customer-settings-edit__field'>
              <label
                htmlFor='description'
                className='customer-settings-edit__label'
              >
                Beschreibungstext
              </label>
              <textarea
                id='description'
                className='customer-settings-edit__textarea'
                value={dataItem.extractsDescription}
                onChange={(event) => {
                  setDataItem({
                    ...dataItem,
                    extractsDescription: event.target.value,
                  });
                }}
              />
            </div>
          </>
        )}
      </div>
      <button
        onClick={handleSubmit}
        className='button button--green customer-settings-edit__submit'
      >
        Speichern
      </button>
    </section>
  );
};

export default SettingsEdit;
