import React from 'react';

import './style.scss';

interface Props {
  item: any;
  showCheckbox: any;
  setShowCheckbox: any;
}

const CustomCheckbox: React.FC<Props> = ({
  item,
  showCheckbox,
  setShowCheckbox,
}): JSX.Element => (
  <label className='container-custom-checkbox'>
    <input
      type='checkbox'
      name={item.name}
      checked={showCheckbox[item.name]}
      onChange={() =>
        setShowCheckbox({
          ...showCheckbox,
          [item.name]: !showCheckbox[item.name],
        })
      }
    />
    <span className='checkmark'></span>
    <span
      className='icon-collor'
      style={{ backgroundColor: item.color }}
    ></span>
    {item.title}
  </label>
);

export default CustomCheckbox;
