import React from 'react';

import CustomersHead from '../CustomersHead';
import CustomersTable from '../CustomersTable';

import './Customers.scss';

const Customers: React.FC = (): JSX.Element => {
  return (
    <div className='customers'>
      <div className='container'>
        <CustomersHead />
        <CustomersTable />
      </div>
    </div>
  );
};

export default Customers;
