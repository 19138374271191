import React from 'react';

import './ExtractsTabs.scss';

interface Props {
  tabNumber: number;
  setTabNumber: any;
}

const ExtractsTabs: React.FC<Props> = ({
  tabNumber,
  setTabNumber,
}): JSX.Element => {
  return (
    <div className='extracts__tabs'>
      <div
        className={
          tabNumber !== 0
            ? 'extracts__tab'
            : 'extracts__tab extracts__tab--active'
        }
        onClick={(event) => {
          setTabNumber(0);
        }}
      >
        Extrakte
      </div>
      <div
        className={
          tabNumber !== 1
            ? 'extracts__tab'
            : 'extracts__tab extracts__tab--active'
        }
        onClick={(event) => {
          setTabNumber(1);
        }}
      >
        Dosierungsanleitung
      </div>
    </div>
  );
};

export default ExtractsTabs;
