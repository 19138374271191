import React from 'react';

import Header from '../../components/admin/Header';
import BudsComponent from '../../components/admin/Buds';

const Buds: React.FC = (): JSX.Element => {
  return (
    <>
      <Header />
      <BudsComponent />
    </>
  );
};

export default Buds;
