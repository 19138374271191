import * as api from '../../../../api';
import * as types from '../../../types/request';

export const refresh = {
  get: function () {
    return (dispatch: any) => {
      dispatch(this.getPending());

      return api.authApi
        .refresh()
        .then((response) => {
          if (response.status === 200) {
            return response.json().then((json) => {
              localStorage.setItem('accessToken', json.accessToken);
              localStorage.setItem('refreshToken', json.refreshToken);
              return dispatch(this.getSuccess(json));
            });
          } else {
            return response.json().then((json) => {
              localStorage.clear();
              dispatch(this.getFailed(json));
            });
          }
        })
        .catch((error) => {
          localStorage.clear();
          dispatch(this.getFailed(error));
        });
    };
  },

  getSuccess: (response: any) => ({
    type: types.authTypes.AUTH_REFRESH_SUCCESS,
    payload: response,
  }),

  getFailed: (error: any) => ({
    type: types.authTypes.AUTH_REFRESH_FAILED,
    payload: error,
  }),

  getPending: () => ({
    type: types.authTypes.AUTH_REFRESH_PENDING,
  }),
};
