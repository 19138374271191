import * as API from '../../../../../api';
import * as types from '../../../../types/request';

export const getProductsAndCount = {
  get: function (interval: string, type: string) {
    return (dispatch: any) => {
      dispatch(this.getPending());

      return API.customersApi.statisticsApi
        .getProductsAndCount(interval, type)
        .then((response: any) => {
          if (response.status === 200) {
            return response
              .json()
              .then((json: any) => dispatch(this.getSuccess(json)));
          } else {
            return response
              .json()
              .then((json: any) => dispatch(this.getFailed(json)));
          }
        })
        .catch((response: any) => {
          return dispatch(this.getFailed(response));
        });
    };
  },

  getSuccess: (response: any) => ({
    type: types.customersTypes.statistics.GET_PRODUCTS_AND_COUNT_SUCCESS,
    payload: response,
  }),

  getFailed: (response: any) => ({
    type: types.customersTypes.statistics.GET_PRODUCTS_AND_COUNT_FAILED,
    payload: response,
  }),

  getPending: () => ({
    type: types.customersTypes.statistics.GET_PRODUCTS_AND_COUNT_PENDING,
  }),
};
