import React from 'react';

import './style.scss';

interface Props {
  boxes: any[];
  extractName: string;
}

const resultInstruction: React.FC<Props> = ({
  boxes,
  extractName,
}): JSX.Element => {
  return (
    <section className='result-instruction'>
      <h3 className='title'>Abschließende Anweisung</h3>

      <div className='data'>
        {boxes
          .map((box: any) => {
            if (box.type === 'field') {
              return box.value;
            } else if (box.type === 'box') {
              return box.text;
            } else if (box.type === 'symbol') {
              if (box.text === 'Produktname') return extractName || box.symbol;

              return box.symbol;
            }
          })
          .reduce((acc: string, value: string) => {
            return value === ',' ? acc + value : acc + ' ' + value;
          }, '')}
      </div>
    </section>
  );
};

export default resultInstruction;
