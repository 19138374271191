export const extracts = {
  GET_EXTRACTS_ALL_SUCCESS: 'GET_EXTRACTS_ALL_SUCCESS',
  GET_EXTRACTS_ALL_FAILED: 'GET_EXTRACTS_ALL_FAILED',
  GET_EXTRACTS_ALL_PENDING: 'GET_EXTRACTS_ALL_PENDING',

  GET_EXTRACTS_ID_SUCCESS: 'GET_EXTRACTS_ID_SUCCESS',
  GET_EXTRACTS_ID_FAILED: 'GET_EXTRACTS_ID_FAILED',
  GET_EXTRACTS_ID_PENDING: 'GET_EXTRACTS_ID_PENDING',

  PUT_EXTRACTS_ID_SUCCESS: 'PUT_EXTRACTS_ID_SUCCESS',
  PUT_EXTRACTS_ID_FAILED: 'PUT_EXTRACTS_ID_FAILED',
  PUT_EXTRACTS_ID_PENDING: 'PUT_EXTRACTS_ID_PENDING',

  DELETE_EXTRACTS_ID_SUCCESS: 'DELETE_EXTRACTS_ID_SUCCESS',
  DELETE_EXTRACTS_ID_FAILED: 'DELETE_EXTRACTS_ID_FAILED',
  DELETE_EXTRACTS_ID_PENDING: 'DELETE_EXTRACTS_ID_PENDING',

  GET_EXTRACTS_SUCCESS: 'GET_EXTRACTS_SUCCESS',
  GET_EXTRACTS_FAILED: 'GET_EXTRACTS_FAILED',
  GET_EXTRACTS_PENDING: 'GET_EXTRACTS_PENDING',

  POST_EXTRACTS_SUCCESS: 'POST_EXTRACTS_SUCCESS',
  POST_EXTRACTS_FAILED: 'POST_EXTRACTS_FAILED',
  POST_EXTRACTS_PENDING: 'POST_EXTRACTS_PENDING',

  DELETE_EXTRACTS_SUCCESS: 'DELETE_EXTRACTS_SUCCESS',
  DELETE_EXTRACTS_FAILED: 'DELETE_EXTRACTS_FAILED',
  DELETE_EXTRACTS_PENDING: 'DELETE_EXTRACTS_PENDING',
};
