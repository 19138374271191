import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../../store/actions';
import * as reducers from '../../../store/reducers';
import { DosagesType } from '../../../types';

import './Extracts.scss';

import CloseIcon from '../../../images/symbols/close.svg';
import CheckIcon from '../../../images/symbols/check-mark.svg';
import ExtractsHead from '../ExtractsHead';
import ExtractsTabs from '../ExtractsTabs';
import ExtractsTable from '../ExtractsTable';
import ExtractsDosage from '../ExtractsDosage';
import Modal from '../Modal';

const Extracts: React.FC = (): JSX.Element => {
  const dispatch: any = useDispatch();

  const [tabNumber, setTabNumber] = useState(0);
  const [isNewPopupOpen, setIsNewPopupOpen] = useState({
    isOpen: false,
    title: '',
  });
  const [isNewSuccessPopupOpen, setIsNewSuccessPopupOpen] = useState(false);
  const [isListPopupOpen, setIsListPopupOpen] = useState(false);
  const [isListSuccessPopupOpen, setIsListSuccessPopupOpen] = useState(false);
  const [selectedDosages, setSelectedDosages] = useState<number[]>([]);
  const [requestMessage, setRequestMessage] = useState('');

  const getDosages = useCallback(
    () =>
      dispatch(
        actions.request.customersActions.dosages.getDosages.get('extract'),
      ),
    [dispatch],
  );
  const putDosages = useCallback(
    (data) =>
      dispatch(
        actions.request.customersActions.dosages.putDosages.put(
          'extract',
          data,
        ),
      ),
    [dispatch],
  );
  const postCustomersMail = useCallback(
    (data) =>
      dispatch(
        actions.request.customersActions.customers.postCustomersMail.post(data),
      ),
    [dispatch],
  );

  const dosages: DosagesType[] = useSelector(
    reducers.request.customersReducers.dosagesState.get.value,
  );

  useEffect(() => {
    getDosages();
  }, [isListPopupOpen]);

  useEffect(() => {
    const shownDosagesIds = dosages
      .filter((dosage) => dosage.shown)
      .map((dosage) => dosage.id);
    setSelectedDosages(shownDosagesIds);
  }, [dosages]);

  const handleNewPopup = (title: string) => {
    setIsNewPopupOpen({ isOpen: true, title });
  };

  const handleListPopup = () => {
    setIsListPopupOpen(true);
  };

  const onToggleSelectedDosages = (id: number) => {
    if (selectedDosages.includes(id)) {
      setSelectedDosages(selectedDosages.filter((d: number) => d !== id));
    } else {
      setSelectedDosages([id, ...selectedDosages]);
    }
  };

  let buttons: Array<{
    title: string;
    onClick: any;
  }> = [];

  if (tabNumber === 0) {
    buttons = [
      {
        title: 'Neuen Extrakt anfragen',
        onClick: () => handleNewPopup('Neuen Extrakt anfragen'),
      },
    ];
  }

  if (tabNumber === 1) {
    buttons = [
      {
        title: 'Neue Dosierung anfragen',
        onClick: () => handleNewPopup('Neue Dosierung anfragen'),
      },
      {
        title: 'Dosierung aktivieren / deaktivieren',
        onClick: handleListPopup,
      },
    ];
  }

  return (
    <>
      <section className='customer-extracts'>
        <div className='container'>
          <ExtractsHead buttons={buttons} />
          <ExtractsTabs tabNumber={tabNumber} setTabNumber={setTabNumber} />
          {tabNumber === 0 && (
            <ExtractsTable
              view={localStorage.getItem('extractsTableView') ?? 'table'}
              switchButtons='right'
            />
          )}
          {tabNumber === 1 && <ExtractsDosage />}
        </div>
      </section>

      {isNewPopupOpen.isOpen && (
        <Modal>
          <div className='customer-extracts__modal-new'>
            <button
              className='customer-extracts__modal-new-close'
              type='button'
              onClick={() => setIsNewPopupOpen({ isOpen: false, title: '' })}
            >
              <img src={CloseIcon} alt='close' />
            </button>
            <p className='customer-extracts__modal-new-title'>
              Neue Dosieranleitung anfragen
            </p>
            <form action='#'>
              <div className='customer-extracts__modal-new-field'>
                <label
                  htmlFor='description'
                  className='customer-extracts__modal-new-description'
                >
                  Beschreibung
                </label>
                <textarea
                  name='description'
                  id='description'
                  className='customer-extracts__modal-new-textarea'
                  placeholder='Beschreiben Sie Ihre Anfrage'
                  onChange={(event) => {
                    setRequestMessage(event.target.value);
                  }}
                />
                <button
                  className='button button--green customer-extracts__modal-new-submit'
                  type='button'
                  onClick={() => {
                    postCustomersMail({ message: requestMessage }).then(() => {
                      setIsNewPopupOpen({ isOpen: false, title: '' });
                      setIsNewSuccessPopupOpen(true);
                    });
                  }}
                >
                  Anfragen
                </button>
              </div>
            </form>
          </div>
        </Modal>
      )}

      {isNewSuccessPopupOpen && (
        <Modal>
          <div className='customer-extracts__modal-new-success'>
            <img
              src={CheckIcon}
              alt='check mark'
              className='customer-extracts__modal-new-success-check'
            />
            <p className='customer-extracts__modal-new-success-text'>
              Anfrage gesendet
            </p>
            <button
              className='button button--green customer-extracts__modal-new-success-close'
              type='button'
              onClick={() => setIsNewSuccessPopupOpen(false)}
            >
              Ok
            </button>
          </div>
        </Modal>
      )}

      {isListPopupOpen && (
        <Modal mode='dosages'>
          <div className='customer-extracts__modal-list'>
            <button
              className='customer-extracts__modal-list-close'
              type='button'
              onClick={() => {
                setIsListPopupOpen(false);
              }}
            >
              <img src={CloseIcon} alt='close' />
            </button>
            <p className='customer-extracts__modal-list-title'>
              Anleitung listen
            </p>
            <p className='customer-extracts__modal-list-description'>
              Wählen Sie die Anleitung aus, die Sie listen möchten.
            </p>
            <div className='customer-extracts__modal-list-dosages'>
              {dosages.map((dosage) => (
                <div
                  className={`customer-extracts__modal-list-dosage ${
                    selectedDosages.includes(dosage.id) &&
                    'customer-extracts__modal-list-dosage--checked'
                  }`}
                  key={dosage.id}
                >
                  <p className='customer-extracts__modal-list-dosage-title'>
                    {dosage.name}
                  </p>
                  <p className='customer-extracts__modal-list-dosage-description'>
                    {dosage.dosageFields
                      .map((field) =>
                        [field.prefix, field.value, field.sufix].join(''),
                      )
                      .join('')}
                  </p>
                  <input
                    checked={selectedDosages.includes(dosage.id)}
                    type='checkbox'
                    className='customer-extracts__modal-list-dosage-input'
                    onChange={() => onToggleSelectedDosages(dosage.id)}
                  />
                </div>
              ))}
            </div>
            <button
              className='button button--green customer-extracts__modal-list-submit'
              type='button'
              onClick={() => {
                putDosages({ dosagesIds: selectedDosages }).then(() => {
                  getDosages();
                  setIsListPopupOpen(false);
                  setIsListSuccessPopupOpen(true);
                });
              }}
            >
              Auswahl bestätigen
            </button>
          </div>
        </Modal>
      )}

      {isListSuccessPopupOpen && (
        <Modal>
          <div className='customer-extracts__modal-list-success'>
            <img
              src={CheckIcon}
              alt='check mark'
              className='customer-extracts__modal-list-success-check'
            />
            <p className='customer-extracts__modal-list-success-text'>
              {`Ihre Auswahl wurde aktualisiert`}
            </p>
            <button
              className='button button--green customer-extracts__modal-new-success-close'
              type='button'
              onClick={() => setIsListSuccessPopupOpen(false)}
            >
              Ok
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Extracts;
