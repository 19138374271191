export const uploadsTypes = {
  GET_UPLOADS_PATH_SUCCESS: 'GET_UPLOADS_PATH_SUCCESS',
  GET_UPLOADS_PATH_FAILED: 'GET_UPLOADS_PATH_FAILED',
  GET_UPLOADS_PATH_PENDING: 'GET_UPLOADS_PATH_PENDING',

  GET_UPLOADS_SUCCESS: 'GET_UPLOADS_SUCCESS',
  GET_UPLOADS_FAILED: 'GET_UPLOADS_FAILED',
  GET_UPLOADS_PENDING: 'GET_UPLOADS_PENDING',

  POST_UPLOADS_SUCCESS: 'POST_UPLOADS_SUCCESS',
  POST_UPLOADS_FAILED: 'POST_UPLOADS_FAILED',
  POST_UPLOADS_PENDING: 'POST_UPLOADS_PENDING',
};
