import React from 'react';

import ChangeComponent from '../../components/customer/Change';

const Change: React.FC = (): JSX.Element => {
  return (
    <>
      <ChangeComponent />
    </>
  );
};

export default Change;
