import * as interfaces from '../../interfaces';
const baseURL = process.env.REACT_APP_BASE_URL;

export const budsApi = {
  getBudsAll: () => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/customers/buds/all`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getBudsID: (id: number) => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/customers/buds/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  putBudsID: (id: number, data: interfaces.customersBudsApi) => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/customers/buds/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify(data),
    });
  },

  deleteBudsID: (id: number) => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/customers/buds/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getBuds: (start: number, limit: number, search?: string) => {
    const token = localStorage.getItem('accessToken');
    const searchParam = search ? `&search=${search}` : '';

    return fetch(
      `${baseURL}/customers/buds?start=${start}&limit=${limit}${searchParam}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  deleteBuds: (data: { budsIds: number[] }) => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/customers/buds`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify(data),
    });
  },
};
