import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as reducers from '../../../store/reducers';
import * as actions from '../../../store/actions';
import * as types from '../../../store/types/request';
import { getImageUrl } from '../../../utils/getImageUrl';

import './CustomerInfoEdit.scss';

import EditIcon from '../../../images/symbols/edit.svg';
import CloseIcon from '../../../images/symbols/close.svg';
import Modal from '../Modal';

interface Props {
  isShowContent: boolean;
}

const CustomerInfoEdit: React.FC<Props> = ({ isShowContent }): JSX.Element => {
  const history = useHistory();
  const params: any = useParams();
  const dispatch: any = useDispatch();

  const [fields, setFields] = useState<any>({
    email: '',
    company: '',
    bookkeepingName: '',
    bookkeepingEmail: '',
    bookkeepingPhone: '',
    technicalName: '',
    technicalEmail: '',
    technicalPhone: '',
    active: true,
    image: '',
    extractsIds: [],
    budsIds: [],
    dosagesIds: [],
    formsIds: [],
  });

  const [errors, setErrors] = useState({
    company: false,
    email: {
      valid: false,
      taken: false,
    },
    image: false,
    bookkeepingName: false,
    technicalName: false,
    bookkeepingEmail: false,
    technicalEmail: false,
    bookkeepingPhone: false,
    technicalPhone: false,
  });

  const [extractsOpen, setExtractsOpen] = useState(false);
  const [dosagesOpen, setDosagesOpen] = useState(false);
  const [formsOpen, setFormsOpen] = useState(false);

  const [selectedImg, setSelectedImg] = useState('');
  const [isImageOpen, setIsImageOpen] = useState(false);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);

  const putCustomersID = useCallback(
    (id, data) =>
      dispatch(
        actions.request.adminActions.customers.putCustomersID.put(id, data),
      ),
    [dispatch],
  );

  const getUploads = useCallback(
    () => dispatch(actions.request.uploadsActions.getUploads.get()),
    [dispatch],
  );
  const postCustomers = useCallback(
    (data) =>
      dispatch(actions.request.adminActions.customers.postCustomers.post(data)),
    [dispatch],
  );
  const postUploads = useCallback(
    (file) => dispatch(actions.request.uploadsActions.postUploads.post(file)),
    [dispatch],
  );

  const customersID = useSelector(
    reducers.request.adminReducers.customersState.getID.value,
  );
  const budsAll = useSelector(
    reducers.request.adminReducers.budsState.getAll.value,
  );
  const extractsAll = useSelector(
    reducers.request.adminReducers.extractsState.getAll.value,
  );
  const dosagesAll = useSelector(
    reducers.request.adminReducers.dosagesState.getAll.value,
  );
  const formsAll = useSelector(
    reducers.request.adminReducers.formsState.getAll.value,
  );
  const uploads = useSelector(reducers.request.uploadsState.getUploads.value);

  useEffect(() => {
    params.id && setFields(customersID);
  }, [customersID]);

  const onSetFields = (event: any) => {
    const { name, value, type } = event.target;

    setErrors({
      ...errors,
      [name]: false,
    });

    setFields({
      ...fields,
      [name]: type === 'radio' ? JSON.parse(value) : value,
    });
  };

  const selectBuds = (event: any) => {
    if (event.target.tagName === 'INPUT') {
      const value = event.target.dataset.value;

      if (event.target.checked) {
        setFields({
          ...fields,
          budsIds: [...fields.budsIds, Number(value.split(',')[1])],
        });
      } else {
        setFields({
          ...fields,
          budsIds: fields.budsIds.filter(
            (bud: any) => bud !== Number(value.split(',')[1]),
          ),
        });
      }
    }
  };

  const selectExtracts = (event: any) => {
    if (event.target.tagName === 'INPUT') {
      const value = event.target.dataset.value;

      if (event.target.checked) {
        setFields({
          ...fields,
          extractsIds: [...fields.extractsIds, Number(value.split(',')[1])],
        });
      } else {
        setFields({
          ...fields,
          extractsIds: fields.extractsIds.filter(
            (extract: any) => extract !== Number(value.split(',')[1]),
          ),
        });
      }
    }
  };

  const selectDosages = (event: any) => {
    if (event.target.tagName === 'LABEL') {
      if (event.target.classList.contains('dropdown-check-list-label--empty')) {
        setFields({ ...fields, dosagesIds: [] });

        const checkboxes = event.target
          .closest('.dropdown-check-list-items')
          .querySelectorAll('input');

        for (const checkbox of checkboxes) {
          checkbox.checked = false;
        }
      }
    }

    if (event.target.tagName === 'INPUT') {
      const value = event.target.dataset.value;

      if (event.target.checked) {
        setFields({
          ...fields,
          dosagesIds: [...fields.dosagesIds, Number(value.split(',')[1])],
        });
      } else {
        setFields({
          ...fields,
          dosagesIds: fields.dosagesIds.filter(
            (dosage: any) => dosage !== Number(value.split(',')[1]),
          ),
        });
      }
    }
  };

  const selectForms = (event: any) => {
    if (event.target.tagName === 'LABEL') {
      if (event.target.classList.contains('dropdown-check-list-label--empty')) {
        setFields({ ...fields, formsIds: [] });

        const checkboxes = event.target
          .closest('.dropdown-check-list-items')
          .querySelectorAll('input');

        for (const checkbox of checkboxes) {
          checkbox.checked = false;
        }
      }
    }

    if (event.target.tagName === 'INPUT') {
      const value = event.target.dataset.value;

      if (event.target.checked) {
        setFields({
          ...fields,
          formsIds: [...fields.formsIds, Number(value.split(',')[1])],
        });
      } else {
        setFields({
          ...fields,
          formsIds: fields.formsIds.filter(
            (form: any) => form !== Number(value.split(',')[1]),
          ),
        });
      }
    }
  };

  const handleCheckbox = (event: any) => {};

  const clearCheckboxes = (event: any) => {
    if (event.target.tagName === 'LABEL') {
      if (event.target.classList.contains('dropdown-check-list-label--empty')) {
        setFields({ ...fields, budsIds: [], extractsIds: [] });

        const checkboxes = event.target
          .closest('.dropdown-check-list-items')
          .querySelectorAll('input');

        for (const checkbox of checkboxes) {
          checkbox.checked = false;
        }
      }
    }
  };

  const onUploadImage = (event: any) => {
    const formData = new FormData();
    const { files } = event.target;

    formData.append('file', files[0]);

    postUploads(formData).then((response: any) => {
      if (response.type === 'POST_UPLOADS_SUCCESS') {
        setIsImageOpen(false);
        setFields({ ...fields, image: response.payload });
      }
    });
  };

  const handleSubmit = () => {
    const inputErrors = {
      company: false,
      email: {
        valid: false,
        taken: false,
      },
      image: false,
      bookkeepingName: false,
      technicalName: false,
      bookkeepingEmail: false,
      technicalEmail: false,
      bookkeepingPhone: false,
      technicalPhone: false,
    };

    const phoneRegexp = new RegExp(/^\+?[0-9]+([0-9]|\/|\(|\)|\-| ){10,}$/);
    const emailRegexp = new RegExp(
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
    );

    if (!fields.company.trim()) {
      inputErrors.company = true;
    }

    if (!emailRegexp.test(fields.email)) {
      inputErrors.email.valid = true;
    }

    if (!fields.image) {
      inputErrors.image = true;
    }

    if (!fields.bookkeepingName.trim()) {
      inputErrors.bookkeepingName = true;
    }

    if (!fields.technicalName.trim()) {
      inputErrors.technicalName = true;
    }

    if (!emailRegexp.test(fields.bookkeepingEmail)) {
      inputErrors.bookkeepingEmail = true;
    }

    if (!emailRegexp.test(fields.technicalEmail)) {
      inputErrors.technicalEmail = true;
    }

    if (!phoneRegexp.test(fields.bookkeepingPhone)) {
      inputErrors.bookkeepingPhone = true;
    }

    if (!phoneRegexp.test(fields.technicalPhone)) {
      inputErrors.technicalPhone = true;
    }

    if (
      Object.values(inputErrors).includes(true) ||
      Object.values(inputErrors.email).includes(true) ||
      Object.values(inputErrors.bookkeepingEmail).includes(true) ||
      Object.values(inputErrors.technicalEmail).includes(true)
    ) {
      setErrors(inputErrors);
    } else {
      params.id
        ? putCustomersID(params.id, fields).then((response: any) => {
            if (
              response.type ===
              types.adminTypes.customers.PUT_CUSTOMERS_ID_FAILED
            ) {
              if (response.payload.statusCode === 403) {
                setErrors({
                  ...errors,
                  email: {
                    ...errors.email,
                    taken: true,
                  },
                });
              }
            } else {
              history.push(`/admin/customers/${params.id}`);
            }
          })
        : postCustomers(fields).then((response: any) => {
            if (
              response.type === types.adminTypes.customers.POST_CUSTOMERS_FAILED
            ) {
              if (response.payload.statusCode === 403) {
                setErrors({
                  ...errors,
                  email: {
                    ...errors.email,
                    taken: true,
                  },
                });
              }
            } else {
              history.push(`/admin/customers/${response.payload}`);
            }
          });
    }
  };

  useEffect(() => {
    isGalleryOpen && getUploads();
  }, [isGalleryOpen]);

  return (
    <>
      {isShowContent && (
        <div className='customer-edit'>
          <div className='container'>
            <button
              className='customer-edit__back'
              onClick={() => {
                history.push('/admin/customers/');
              }}
            >
              Zurück
            </button>
            <h1 className='customer-edit__title'>Bearbeiten - Kundenname</h1>

            <form className='customer-edit__info'>
              <div className='customer-edit__info-general'>
                <div className='customer-edit__info-logo'>
                  <p className='customer-edit__info-title'>
                    Kundenlogo hochladen
                  </p>
                  {fields.image ? (
                    <div className='customer-edit__info-image'>
                      <img src={getImageUrl(fields.image)} alt='' />
                      <button
                        className='customer-edit__info-image-button'
                        type='button'
                      >
                        <img
                          src={EditIcon}
                          alt='edit-icon'
                          onClick={() => setIsImageOpen(true)}
                        />
                      </button>
                    </div>
                  ) : (
                    <div
                      className={`customer-edit__info-image upload ${
                        errors.image && 'border-error'
                      }`}
                      onClick={() => {
                        setErrors({ ...errors, image: false });
                        setIsImageOpen(true);
                      }}
                    >
                      <p className='customer__info-image-title'>Firmenlogo</p>
                      <p className='customer__info-image-subtitle'>
                        Größe 128x128px
                      </p>
                      {errors.image && (
                        <p className='field-error'>
                          Bitte, fügen Sie ein Bild hinzu
                        </p>
                      )}
                    </div>
                  )}
                </div>

                <div className='customer-edit__info-general-right'>
                  <div className='customer-edit__info-general-right-top'>
                    <div className='customer-edit__info-item'>
                      <label
                        className='customer-edit__info-title'
                        htmlFor='company'
                      >
                        Unternehmen
                      </label>
                      <input
                        type='text'
                        className={`customer-edit__info-input ${
                          errors.company && 'border-error'
                        }`}
                        name='company'
                        id='company'
                        value={fields.company}
                        onChange={onSetFields}
                      />
                      {errors.company && (
                        <p className='field-error'>
                          Bitte geben Sie den Unternehmen ein
                        </p>
                      )}
                    </div>

                    <div className='customer-edit__info-item'>
                      <label
                        className='customer-edit__info-title'
                        htmlFor='email'
                      >
                        E-Mail:
                      </label>
                      <input
                        type='email'
                        className={`customer-edit__info-input ${
                          (errors.email.valid || errors.email.taken) &&
                          'border-error'
                        }`}
                        name='email'
                        id='email'
                        value={fields.email}
                        onChange={onSetFields}
                      />
                      {errors.email.valid && (
                        <p className='field-error'>
                          Diese E-Mail ist nicht korrekt
                        </p>
                      )}
                      {errors.email.taken && (
                        <p className='field-error'>
                          Diese E-Mail ist bereits vergeben
                        </p>
                      )}
                    </div>

                    <div className='customer-edit__info-item'>
                      <p className='customer-edit__info-title'>
                        Ist Nutzer aktiv?
                      </p>
                      <div className='customer-edit__info-radio'>
                        <div className='customer-edit__info-radio-field'>
                          <input
                            type='radio'
                            name='active'
                            id='yes'
                            value='true'
                            onChange={onSetFields}
                            checked={fields.active}
                          />
                          <label
                            htmlFor='yes'
                            className='customer-edit__info-title'
                          >
                            Ja
                          </label>
                        </div>

                        <div className='customer-edit__info-radio-field'>
                          <input
                            type='radio'
                            name='active'
                            id='no'
                            value='false'
                            onChange={onSetFields}
                            checked={!fields.active}
                          />
                          <label
                            htmlFor='no'
                            className='customer-edit__info-title'
                          >
                            Nein
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='customer-edit__info-general-right-bottom'>
                    <div className='customer-edit__info-item'>
                      <p className='customer-edit__info-title'>
                        Mit Marken/Produkten verbinden:
                      </p>
                      <div className='dropdown-check-list'>
                        <p
                          className='dropdown-check-list-title'
                          onClick={() => {
                            setExtractsOpen(!extractsOpen);
                          }}
                        >
                          {[
                            ...budsAll
                              .filter((bud: any) =>
                                fields.budsIds.includes(bud.id),
                              )
                              .map((bud: any) => bud.name),
                            ...extractsAll
                              .filter((extract: any) =>
                                fields.extractsIds.includes(extract.id),
                              )
                              .map((extract: any) => extract.name),
                          ].join(', ')}
                        </p>

                        {extractsOpen && (
                          <ul className='dropdown-check-list-items'>
                            <label
                              className='dropdown-check-list-label dropdown-check-list-label--empty'
                              onClick={clearCheckboxes}
                            />
                            {budsAll.map((bud: any) => (
                              <label
                                className='dropdown-check-list-label'
                                key={bud.id}
                                onClick={selectBuds}
                              >
                                <input
                                  type='checkbox'
                                  data-value={['bud', bud.id]}
                                  checked={fields.budsIds.includes(bud.id)}
                                  onChange={handleCheckbox}
                                />
                                {bud.name}
                              </label>
                            ))}
                            {extractsAll.map((extract: any) => (
                              <label
                                className='dropdown-check-list-label'
                                key={extract.id}
                                onClick={selectExtracts}
                              >
                                <input
                                  type='checkbox'
                                  data-value={['extract', extract.id]}
                                  checked={fields.extractsIds.includes(
                                    extract.id,
                                  )}
                                  onChange={handleCheckbox}
                                />
                                {extract.name}
                              </label>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='customer-edit__info-section'>
                <p className='customer-edit__info-section-title'>
                  Ansprechpartner Buchhaltung
                </p>
                <div className='customer-edit__info-section-row'>
                  <div className='customer-edit__info-section-item'>
                    <label
                      htmlFor='bookkeepingName'
                      className='customer-edit__info-title'
                    >
                      Name:
                    </label>
                    <input
                      type='text'
                      className={`customer-edit__info-input ${
                        errors.bookkeepingName && 'border-error'
                      }`}
                      id='bookkeepingName'
                      name='bookkeepingName'
                      value={fields.bookkeepingName}
                      onChange={onSetFields}
                    />
                    {errors.bookkeepingName && (
                      <p className='field-error'>
                        Bitte geben Sie den Namen ein
                      </p>
                    )}
                  </div>

                  <div className='customer-edit__info-section-item'>
                    <label
                      htmlFor='bookkeepingEmail'
                      className='customer-edit__info-title'
                    >
                      E-mail:
                    </label>
                    <input
                      type='email'
                      className={`customer-edit__info-input ${
                        errors.bookkeepingEmail && 'border-error'
                      }`}
                      id='bookkeepingEmail'
                      name='bookkeepingEmail'
                      value={fields.bookkeepingEmail}
                      onChange={onSetFields}
                    />
                    {errors.bookkeepingEmail && (
                      <p className='field-error'>
                        Diese E-Mail ist nicht korrekt
                      </p>
                    )}
                  </div>

                  <div className='customer-edit__info-section-item'>
                    <label
                      htmlFor='bookkeepingPhone'
                      className='customer-edit__info-title'
                    >
                      Telefon:
                    </label>
                    <input
                      type='text'
                      className={`customer-edit__info-input ${
                        errors.bookkeepingPhone && 'border-error'
                      }`}
                      id='bookkeepingPhone'
                      name='bookkeepingPhone'
                      value={fields.bookkeepingPhone}
                      onChange={onSetFields}
                    />
                    {errors.bookkeepingPhone && (
                      <p className='field-error'>
                        Das Telefon ist nicht korrekt
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className='customer-edit__info-section'>
                <p className='customer-edit__info-section-title'>
                  Technischer Kontakt
                </p>
                <div className='customer-edit__info-section-row'>
                  <div className='customer-edit__info-section-item'>
                    <label
                      htmlFor='technicalName'
                      className='customer-edit__info-title'
                    >
                      Name:
                    </label>
                    <input
                      type='text'
                      className={`customer-edit__info-input ${
                        errors.technicalName && 'border-error'
                      }`}
                      id='technicalName'
                      name='technicalName'
                      value={fields.technicalName}
                      onChange={onSetFields}
                    />
                    {errors.technicalName && (
                      <p className='field-error'>
                        Bitte geben Sie den Namen ein
                      </p>
                    )}
                  </div>

                  <div className='customer-edit__info-section-item'>
                    <label
                      htmlFor='technicalEmail'
                      className='customer-edit__info-title'
                    >
                      E-mail:
                    </label>
                    <input
                      type='email'
                      className={`customer-edit__info-input ${
                        errors.technicalEmail && 'border-error'
                      }`}
                      id='technicalEmail'
                      name='technicalEmail'
                      value={fields.technicalEmail}
                      onChange={onSetFields}
                    />
                    {errors.technicalEmail && (
                      <p className='field-error'>
                        Diese E-Mail ist nicht korrekt
                      </p>
                    )}
                  </div>

                  <div className='customer-edit__info-section-item'>
                    <label
                      htmlFor='technicalPhone'
                      className='customer-edit__info-title'
                    >
                      Telefon:
                    </label>
                    <input
                      type='text'
                      className={`customer-edit__info-input ${
                        errors.technicalPhone && 'border-error'
                      }`}
                      id='technicalPhone'
                      name='technicalPhone'
                      value={fields.technicalPhone}
                      onChange={onSetFields}
                    />
                    {errors.technicalPhone && (
                      <p className='field-error'>
                        Das Telefon ist nicht korrekt
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className='customer-edit__info-section customer-edit__info-section--last'>
                <p className='customer-edit__info-section-title'>
                  Anleitungen für den Kunden
                </p>
                <p className='customer-edit__info-title'>
                  Anleitungen auswählen
                </p>
                <div className='dropdown-check-list'>
                  <p
                    className='dropdown-check-list-title'
                    onClick={() => {
                      setDosagesOpen(!dosagesOpen);
                    }}
                  >
                    {dosagesAll
                      .filter((dosage: any) =>
                        fields.dosagesIds.includes(dosage.id),
                      )
                      .map((dosage: any) => dosage.name)
                      .join(', ')}
                  </p>

                  {dosagesOpen && (
                    <ul className='dropdown-check-list-items'>
                      <label
                        className='dropdown-check-list-label dropdown-check-list-label--empty'
                        onClick={selectDosages}
                      />
                      {dosagesAll.map((dosage: any) => (
                        <label
                          className='dropdown-check-list-label'
                          key={dosage.id}
                          onClick={selectDosages}
                        >
                          <input
                            type='checkbox'
                            data-value={['dosage', dosage.id]}
                            checked={fields.dosagesIds.includes(dosage.id)}
                            onChange={handleCheckbox}
                          />
                          {dosage.name}
                        </label>
                      ))}
                    </ul>
                  )}
                </div>
              </div>

              <div className='customer-edit__info-section customer-edit__info-section--last'>
                <p className='customer-edit__info-section-title'>
                  Abgabeformen für den Kunden
                </p>
                <p className='customer-edit__info-title'>
                  Abgabeformen auswählen
                </p>
                <div className='dropdown-check-list'>
                  <p
                    className='dropdown-check-list-title'
                    onClick={() => {
                      setFormsOpen(!formsOpen);
                    }}
                  >
                    {formsAll
                      .filter((form: any) => fields.formsIds.includes(form.id))
                      .map((form: any) => form.name)
                      .join(', ')}
                  </p>

                  {formsOpen && (
                    <ul className='dropdown-check-list-items'>
                      <label
                        className='dropdown-check-list-label dropdown-check-list-label--empty'
                        onClick={selectForms}
                      />
                      {formsAll.map((form: any) => (
                        <label
                          className='dropdown-check-list-label'
                          key={form.id}
                          onClick={selectForms}
                        >
                          <input
                            type='checkbox'
                            data-value={['form', form.id]}
                            checked={fields.formsIds.includes(form.id)}
                            onChange={handleCheckbox}
                          />
                          {form.name}
                        </label>
                      ))}
                    </ul>
                  )}
                </div>
              </div>

              <div className='customer-edit__buttons'>
                <button
                  className='button customer-edit__button customer-edit__button--delete'
                  type='button'
                  onClick={() => {
                    history.push('/admin/customers');
                  }}
                >
                  Abbrechen
                </button>
                <button
                  className='button customer-edit__button customer-edit__button--save'
                  type='button'
                  onClick={handleSubmit}
                >
                  Speichern
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {isImageOpen && (
        <Modal>
          <div className='customer__upload'>
            <h1 className='customer__upload-title'>Bild hochladen:</h1>
            <div className='customer__upload-buttons'>
              <button
                className='button button--dark customer__upload-button'
                type='button'
                onClick={() => {
                  setIsImageOpen(false);
                  setIsGalleryOpen(true);
                }}
              >
                Von Galerie wählen
              </button>
              <input
                type='file'
                className='customer__upload-button customer__upload-button--input'
                onChange={onUploadImage}
              />
            </div>
            <button
              className='customer__upload-close'
              type='button'
              onClick={() => {
                setIsImageOpen(false);
              }}
            >
              <img src={CloseIcon} alt='close' />
            </button>
          </div>
        </Modal>
      )}

      {isGalleryOpen && (
        <Modal mode='gallery'>
          <div className='customer__gallery'>
            <h1 className='customer__gallery-title'>
              Bild von Galerie auswählen
            </h1>
            <div className='customer__gallery-list'>
              {uploads.map((img: string) => (
                <div
                  className={[
                    'customer__gallery-item',
                    selectedImg === img && 'selected',
                  ]
                    .filter(Boolean)
                    .join(' ')}
                  key={img}
                  onClick={() => setSelectedImg(img)}
                >
                  <img
                    src={getImageUrl(img)}
                    alt='gallery item'
                    className='customer__gallery-image'
                  />
                </div>
              ))}
            </div>
            <div className='customer__gallery-buttons'>
              <button
                className='customer__gallery-button button button--dark'
                type='button'
                onClick={() => {
                  setIsGalleryOpen(false);
                  setSelectedImg('');
                }}
              >
                Schließen
              </button>
              <button
                className='customer__gallery-button button button--green'
                type='button'
                onClick={() => {
                  setFields({ ...fields, image: selectedImg });
                  setIsGalleryOpen(false);
                  setSelectedImg('');
                }}
              >
                Anwenden
              </button>
            </div>
            <button
              className='customer__gallery-close'
              type='button'
              onClick={() => {
                setIsGalleryOpen(false);
                setSelectedImg('');
              }}
            >
              <img src={CloseIcon} alt='close' />
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default CustomerInfoEdit;
