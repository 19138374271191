import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../../store/actions';
import * as reducers from '../../../store/reducers';
import { getGenetik } from '../../../utils/getGenetik';

import DeleteIcon from '../../../images/symbols/delete.svg';
import CloseIcon from '../../../images/symbols/close.svg';
import Pagination from '../Pagination';
import Modal from '../Modal';

import './ExtractsTable.scss';

const ExtractsTable: React.FC = (): JSX.Element => {
  const dispatch: any = useDispatch();
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);

  const [searchQuery, setSearchQuery] = useState('');

  const [removeExtractPopupInfo, setRemoveExtractPopupInfo] = useState({
    id: 0,
    item: '',
    isOpen: false,
  });
  const [removeExtractsPopupInfo, setRemoveExtractsPopupInfo] = useState(false);
  const [selectedExtracts, setSelectedExtracts] = useState<number[]>([]);

  const getExtractsID = useCallback(
    (id) =>
      dispatch(actions.request.adminActions.extracts.getExtractsID.get(id)),
    [dispatch],
  );
  const getExtracts = useCallback(
    (start, limit, search) =>
      dispatch(
        actions.request.adminActions.extracts.getExtracts.get(
          start,
          limit,
          search,
        ),
      ),
    [dispatch],
  );
  const postExtracts = useCallback(
    (data) =>
      dispatch(actions.request.adminActions.extracts.postExtracts.post(data)),
    [dispatch],
  );
  const deleteExtractsID = useCallback(
    (id) =>
      dispatch(
        actions.request.adminActions.extracts.deleteExtractsID.delete(id),
      ),
    [dispatch],
  );
  const deleteExtracts = useCallback(
    (data) =>
      dispatch(
        actions.request.adminActions.extracts.deleteExtracts.delete(data),
      ),
    [dispatch],
  );

  const extracts = useSelector(
    reducers.request.adminReducers.extractsState.get.value,
  );
  const extractsTotal = useSelector(
    reducers.request.adminReducers.extractsState.get.total,
  );

  useEffect(() => {
    getExtracts(start, limit, searchQuery);
  }, [start, limit, searchQuery]);

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setStart(currentPage * limit - limit);
  }, [currentPage]);

  const handleSelect = (event: any) => {
    setLimit(event.target.value);
  };

  const handleInput = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const onToggleSelectedExtracts = (id: number) => {
    if (selectedExtracts.includes(id)) {
      setSelectedExtracts(selectedExtracts.filter((d: number) => d !== id));
    } else {
      setSelectedExtracts([id, ...selectedExtracts]);
    }
  };

  const redirect = (event: any) => {
    if (event.target.tagName === 'TD') {
      const path = `/admin/extracts/${event.target.closest('tr').dataset.url}`;

      history.push(path);
    }
  };

  const duplicate = async (ids: number[]) => {
    const singleBudRequests = ids.map((id) => getExtractsID(id));
    const budsResponses = await Promise.all(singleBudRequests);
    const budCreateRequests = budsResponses.map((response) => {
      const { id, ...budWithouId } = response.payload;
      return postExtracts(budWithouId);
    });
    await Promise.all(budCreateRequests);
  };

  return (
    <div className='extracts__table-wrapper'>
      <div className='extracts__table-filter'>
        <div className='extracts__table-select-wrapper'>
          <label
            htmlFor='extracts-number'
            className='extracts__table-select-label'
          >
            Einträge anzeigen:
          </label>
          <select
            name='extracts-number'
            id='extracts-number'
            className='extracts__table-select'
            value={limit}
            onChange={handleSelect}
          >
            <option value='5'>5</option>
            <option value='10'>10</option>
            <option value='15'>15</option>
            <option value='20'>20</option>
          </select>
        </div>
        <form action='#' className='extracts__table-search'>
          <div className='extracts__table-field'>
            <input
              type='text'
              placeholder='Suchen'
              className='extracts__table-input'
              value={searchQuery}
              onChange={handleInput}
            />
          </div>
        </form>
      </div>

      <table className='extracts__table'>
        <thead>
          <tr>
            <th />
            <th className='extracts__table-title'>Extrakt</th>
            <th className='extracts__table-title'>Sorte / Genetik</th>
            <th className='extracts__table-title'>TCH / CBD</th>
            <th className='extracts__table-title'>Hersteller</th>
            <th />
          </tr>
        </thead>

        <tbody>
          {extracts.map((extract: any, index: number) => (
            <tr
              key={extract.id}
              className='extracts__table-row'
              onClick={redirect}
              data-url={extract.id}
            >
              <td className='extracts__table-row-index'>
                <input
                  checked={selectedExtracts.includes(extract.id)}
                  type='checkbox'
                  className='extracts__table-row-checkbox'
                  onChange={() => onToggleSelectedExtracts(extract.id)}
                />
                {index + 1 + start}.
              </td>
              <td className='extracts__table-row-item'>{extract.name}</td>
              <td className='extracts__table-row-item'>
                {extract.kultivar || 'k.A.'} / {getGenetik(extract.genetik)}
              </td>
              <td className='extracts__table-row-item'>
                {`${
                  extract.isConcentration ? `1 - ${extract.thc}` : extract.thc
                } / ${
                  extract.isConcentration ? `1 - ${extract.cbd}` : extract.cbd
                } ${extract.unit}`}
              </td>
              <td className='extracts__table-row-item'>
                {extract.companyName}
              </td>
              <td className='extracts__table-row-button'>
                <button
                  className='button'
                  onClick={() => {
                    setRemoveExtractPopupInfo({
                      id: extract.id,
                      item: extract.name,
                      isOpen: true,
                    });
                  }}
                >
                  <img src={DeleteIcon} alt='delete' />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Pagination
        itemsPerPage={limit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalItems={extractsTotal}
        paginate={paginate}
      />

      {!!selectedExtracts.length && (
        <div className='extracts__table-buttons'>
          <button
            className='button button--green extracts__table-button'
            onClick={() => {
              duplicate(selectedExtracts).then(() => {
                setSelectedExtracts([]);
                getExtracts(start, limit, searchQuery);
              });
            }}
          >
            Ausgewählte duplizieren {selectedExtracts.length}
          </button>
          <button
            className='button button--dark extracts__table-button'
            onClick={() => setRemoveExtractsPopupInfo(true)}
          >
            Ausgewählte löschen {selectedExtracts.length}
          </button>
        </div>
      )}

      {removeExtractPopupInfo.isOpen && (
        <Modal>
          <div className='extracts__table-modal'>
            <button
              className='extracts__table-modal-close'
              onClick={() =>
                setRemoveExtractPopupInfo({ id: 0, item: '', isOpen: false })
              }
            >
              <img src={CloseIcon} alt='close' />
            </button>
            <p className='extracts__table-modal-title'>Sorte löschen?</p>
            <p className='extracts__table-modal-paragraph'>
              Sie haben vor, den Bud "{removeExtractPopupInfo.item}" zu löschen.
            </p>
            <p className='extracts__table-modal-paragraph'>
              Diese Änderungen kann nicht rückgängig gemacht werden.
            </p>
            <div className='extracts__table-modal-buttons'>
              <button
                className='button extracts__table-modal-button extracts__table-modal-button--delete'
                onClick={() =>
                  deleteExtractsID(removeExtractPopupInfo.id).then(() => {
                    setRemoveExtractPopupInfo({
                      id: 0,
                      item: '',
                      isOpen: false,
                    });
                    getExtracts(start, limit, searchQuery);
                  })
                }
              >
                Löschen
              </button>
              <button
                className='button extracts__table-modal-button extracts__table-modal-button--cancel'
                onClick={() =>
                  setRemoveExtractPopupInfo({ id: 0, item: '', isOpen: false })
                }
              >
                Abbrechen
              </button>
            </div>
          </div>
        </Modal>
      )}

      {removeExtractsPopupInfo && (
        <Modal>
          <div className='buds__table-modal'>
            <button
              className='buds__table-modal-close'
              onClick={() => setRemoveExtractsPopupInfo(false)}
            >
              <img src={CloseIcon} alt='close' />
            </button>
            <p className='buds__table-modal-title'>
              {selectedExtracts.length} Blüten löschen?
            </p>
            <p className='buds__table-modal-paragraph'>
              Sie haben vor, {selectedExtracts.length} ausgewählte Blüten zu
              löschen.
            </p>
            <p className='buds__table-modal-paragraph'>
              Nach dem Entfernen können Sie diese Änderungen nicht mehr
              rückgängig machen.
            </p>
            <div className='buds__table-modal-buttons'>
              <button
                className='button buds__table-modal-button buds__table-modal-button--delete'
                onClick={() =>
                  deleteExtracts({ extractsIds: selectedExtracts }).then(() => {
                    setRemoveExtractsPopupInfo(false);
                    setSelectedExtracts([]);
                    getExtracts(start, limit, searchQuery);
                  })
                }
              >
                Löschen
              </button>
              <button
                className='button buds__table-modal-button buds__table-modal-button--cancel'
                onClick={() => setRemoveExtractsPopupInfo(false)}
              >
                Abbrechen
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ExtractsTable;
