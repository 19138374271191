import React, { useCallback, useEffect, useLayoutEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { changeColor } from '../../../utils/changeColor';

import * as actions from '../../../store/actions';
import * as reducers from '../../../store/reducers';
import { getImageUrl } from '../../../utils/getImageUrl';

import './style.scss';

import Logo from '../../../images/header/logo-white.png';

const Header: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();

  const getInfo = useCallback(
    () =>
      dispatch(
        actions.request.customersActions.customers.getCustomersInfo.get(),
      ),
    [dispatch],
  );
  const getLogout = useCallback(
    () => dispatch(actions.request.authActions.logout.get()),
    [dispatch],
  );

  const info = useSelector(
    reducers.request.customersReducers.customersState.getInfo.value,
  );

  useEffect(() => {
    getInfo();
  }, []);

  useLayoutEffect(() => {
    // Apply custom colors globally
    const root = document.documentElement;
    root.style.setProperty('--brand-main-color', info.color);
    const hoverColor = changeColor(0.1, info.color);
    root.style.setProperty('--brand-main-hover-color', hoverColor);
    const pressedColor = changeColor(-0.1, info.color);
    root.style.setProperty('--brand-main-pressed-color', pressedColor);
    const headerActiveColor = changeColor(0.15, info.color);
    root.style.setProperty('--brand-header-active-color', headerActiveColor);

    let style = document.getElementById('global-styles');
    if (!style) {
      style = document.createElement('style');
      style.id = 'global-styles';
      document.head.appendChild(style);
    }

    style.innerHTML = info.css;
  }, [info]);

  return (
    <header className='customer-header'>
      <div className='container customer-header__container'>
        <NavLink to='/customer' className='customer-header__logo'>
          <img src={Logo} alt='CANNABIS APOTHEKE' width='149' height='50' />
        </NavLink>
        <div className='customer-header__right'>
          <nav className='customer-header__nav'>
            <NavLink
              to='/customer'
              className='customer-header__nav-link'
              activeClassName='customer-header__nav-link--active'
              exact
            >
              Home
            </NavLink>
            <NavLink
              to='/customer/buds'
              className='customer-header__nav-link'
              activeClassName='customer-header__nav-link--active'
            >
              Blüten
            </NavLink>
            <NavLink
              to='/customer/extracts'
              className='customer-header__nav-link'
              activeClassName='customer-header__nav-link--active'
            >
              Extrakte
            </NavLink>
            <NavLink
              to='/customer/settings'
              className='customer-header__nav-link'
              activeClassName='customer-header__nav-link--active'
            >
              Einstellungen
            </NavLink>
          </nav>
          <div className='customer-header__user'>
            <div className='customer-header__user-logo'>
              <img
                src={getImageUrl(info.image)}
                className='customer-header__user-image'
                alt=''
              />
            </div>
            <p className='customer-header__user-role'>{info.company}</p>
          </div>
          <button
            className='customer-header__logout'
            type='button'
            onClick={getLogout}
          >
            Abmelden
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
