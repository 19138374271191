import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../../store/actions';
import * as reducers from '../../../store/reducers';
import { getGenetik } from '../../../utils/getGenetik';
import { getImageUrl } from '../../../utils/getImageUrl';

import './ExtractDetails.scss';

import NavIcon from '../../../images/symbols/next-page.svg';
import ExtractIcon from '../../../images/extract-icon.svg';
import Modal from '../../customer/Modal';

interface Props {
  id: number;
}

const ExtractDetails: React.FC<Props> = ({ id }): JSX.Element => {
  const [removeExtractPopupInfo, setRemoveExtractPopupInfo] = useState(false);
  const [selectedImage, setSelectedImage] = useState<any>({});

  const dispatch: any = useDispatch();
  const history = useHistory();

  const getExtractsAll = useCallback(
    () =>
      dispatch(actions.request.customersActions.extracts.getExtractsAll.get()),
    [dispatch],
  );
  const getExtractsID = useCallback(
    (id) =>
      dispatch(actions.request.customersActions.extracts.getExtractsID.get(id)),
    [dispatch],
  );
  const deleteExtractsID = useCallback(
    (id) =>
      dispatch(
        actions.request.customersActions.extracts.deleteExtractsID.delete(id),
      ),
    [dispatch],
  );

  const extractsAll: number[] = useSelector(
    reducers.request.customersReducers.extractsState.getAll.value,
  );
  const extract = useSelector(
    reducers.request.customersReducers.extractsState.getID.value,
  );

  const previousId = extractsAll[extractsAll.indexOf(id) - 1];
  const nextId = extractsAll[extractsAll.indexOf(id) + 1];

  useEffect(() => {
    getExtractsID(id);
    getExtractsAll();
  }, [id]);

  useEffect(() => {
    if (Object.keys(extract).length === 0) return;

    if (extract.images.length)
      setSelectedImage({ index: 0, src: extract.images[0] });
    else setSelectedImage({ index: -1, src: extract.image });
  }, [extract]);

  return (
    <>
      <section className='customer-extract'>
        <div className='container'>
          <button
            className='customer-extract__back'
            type='button'
            onClick={() => history.push('/customer/extracts')}
          >
            Zurück
          </button>
          <h1 className='customer-extract__title'>Extrakt-details</h1>
          <div className='customer-extract__info'>
            <div className='customer-extract__info-navigation'>
              {previousId ? (
                <div
                  className='customer-extract__info-previous'
                  onClick={() =>
                    history.push(`/customer/extracts/${previousId}`)
                  }
                >
                  <img src={NavIcon} alt='previous-page' />
                </div>
              ) : (
                <div className='customer-extract__info-previous-disabled'>
                  <img src={NavIcon} alt='previous-page' />
                </div>
              )}
              {nextId ? (
                <div
                  className='customer-extract__info-next'
                  onClick={() => history.push(`/customer/extracts/${nextId}`)}
                >
                  <img src={NavIcon} alt='next-page' />
                </div>
              ) : (
                <div className='customer-extract__info-next-disabled'>
                  <img src={NavIcon} alt='next-page' />
                </div>
              )}
            </div>
            <div className='customer-extract__info-left'>
              <div className='customer-extract__info-image-wrapper'>
                <div className='customer-extract__info-image-head'>
                  <img
                    src={getImageUrl(extract.companyImage)}
                    alt=''
                    className='customer-extract__info-image-logo'
                  />
                  <p className='customer-extract__info-image-text'>
                    <span>{extract.name}</span>
                    <br />
                    {extract.companyName}
                  </p>
                </div>
                <img
                  src={getImageUrl(selectedImage.src) || ExtractIcon}
                  alt=''
                  className='customer-extract__info-image'
                />
                <div className='customer-extract__info-image-description'>
                  <span>{extract.kultivar || 'k.A.'}</span>
                  <br />
                  <p>{getGenetik(extract.genetik)}</p>
                </div>
              </div>
              <div className='customer-extract__info-gallery'>
                {extract.images?.map((image: string, index: number) => (
                  <div
                    className={`customer-extract__info-gallery-item ${
                      selectedImage.index === index &&
                      'customer-extract__info-gallery-item--selected'
                    }`}
                    key={index}
                    onClick={() => setSelectedImage({ index, src: image })}
                  >
                    <img src={getImageUrl(image)} alt='' />
                  </div>
                ))}
              </div>
            </div>
            <div className='customer-extract__info-right'>
              <p className='customer-extract__info-right-title'>
                Beschreibung:
              </p>
              <div
                className='customer-extract__info-right-container'
                dangerouslySetInnerHTML={{ __html: extract.description }}
              />
            </div>
          </div>
          <div className='customer-extract__buttons'>
            <button
              className='button button--dark customer-extract__button customer-extract__button--cancel'
              type='button'
              onClick={() => setRemoveExtractPopupInfo(true)}
            >
              Löschen
            </button>
            <button
              className='button button--green customer-extract__button'
              type='button'
              onClick={() => history.push(`/customer/extracts/${id}/edit`)}
            >
              Bearbeiten
            </button>
          </div>
        </div>
      </section>

      {removeExtractPopupInfo && (
        <Modal>
          <div className='customer-extracts__table-modal'>
            <p className='customer-extracts__table-modal-title'>
              Extrakt löschen?
            </p>
            <p className='customer-extracts__table-modal-paragraph'>
              Sie haben vor, das Extrakt "{extract.name}" zu löschen.
            </p>
            <p className='customer-extracts__table-modal-paragraph'>
              Diese Änderungen kann nicht rückgängig gemacht werden.
            </p>
            <div className='customer-extracts__table-modal-buttons'>
              <button
                className='button customer-extracts__table-modal-button customer-extracts__table-modal-button--delete'
                onClick={() => {
                  deleteExtractsID(id).then(() => {
                    setRemoveExtractPopupInfo(false);
                    history.push('/customer/extracts');
                  });
                }}
              >
                Löschen
              </button>
              <button
                className='button customer-extracts__table-modal-button customer-extracts__table-modal-button--cancel'
                onClick={() => setRemoveExtractPopupInfo(false)}
              >
                Abbrechen
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ExtractDetails;
