import React from 'react';

import './style.scss';

interface Props {
  value: any;
  setValue: (arg: any) => void;
}

const ListTimePeriod: React.FC<Props> = ({ value, setValue }): JSX.Element => {
  const timePeriodConfig = [
    {
      name: '7 Tage',
      value: '7d',
    },
    {
      name: '30 Tage',
      value: '30d',
    },
    {
      name: '6 Monate',
      value: '6m',
    },
    {
      name: '12 Monate',
      value: '12m',
    },
  ];

  return (
    <select
      className='list-time-period'
      value={value}
      onChange={(event: any) => setValue(event.target.value)}
    >
      {timePeriodConfig.map((time: any) => (
        <option className='item-period' value={time.value}>
          {time.name}
        </option>
      ))}
    </select>
  );
};

export default ListTimePeriod;
