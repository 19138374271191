import * as API from '../../../../../api';
import * as types from '../../../../types/request';

export const putCustomers = {
  put: function (data: any) {
    return (dispatch: any) => {
      dispatch(this.putPending());

      return API.customersApi.customersApi
        .putCustomers(data)
        .then((response: any) => {
          if (response.status === 200) {
            return response
              .json()
              .then((json: any) => dispatch(this.putSuccess(json)));
          } else {
            return response
              .json()
              .then((json: any) => dispatch(this.putFailed(json)));
          }
        })
        .catch((response: any) => {
          return dispatch(this.putFailed(response));
        });
    };
  },

  putSuccess: (response: any) => ({
    type: types.customersTypes.customers.PUT_CUSTOMERS_SUCCESS,
    payload: response,
  }),

  putFailed: (response: any) => ({
    type: types.customersTypes.customers.PUT_CUSTOMERS_FAILED,
    payload: response,
  }),

  putPending: () => ({
    type: types.customersTypes.customers.PUT_CUSTOMERS_PENDING,
  }),
};
