import React from 'react';

import './SettingsTabs.scss';

interface Props {
  tabNumber: number;
  setTabNumber: any;
}

const SettingsTabs: React.FC<Props> = ({
  tabNumber,
  setTabNumber,
}): JSX.Element => {
  return (
    <div className='customer-settings__tabs'>
      <div
        className={
          tabNumber !== 0
            ? 'customer-settings__tab'
            : 'customer-settings__tab customer-settings__tab--active'
        }
        onClick={(event) => {
          setTabNumber(0);
        }}
      >
        Allgemein
      </div>
      <div
        className={
          tabNumber !== 1
            ? 'customer-settings__tab'
            : 'customer-settings__tab customer-settings__tab--active'
        }
        onClick={(event) => {
          setTabNumber(1);
        }}
      >
        Inhalt
      </div>
      <div
        className={
          tabNumber !== 2
            ? 'customer-settings__tab'
            : 'customer-settings__tab customer-settings__tab--active'
        }
        onClick={(event) => {
          setTabNumber(2);
        }}
      >
        Rezepteinstellungen
      </div>
    </div>
  );
};

export default SettingsTabs;
