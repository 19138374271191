import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import * as actions from '../../store/actions';

import BudsDosage from '../../components/admin/BudsDosageEdit';
import Header from '../../components/admin/Header';

const BudsDosageEdit: React.FC = (): JSX.Element => {
  const dispatch: any = useDispatch();
  const param: any = useParams();

  const getDosagesID = useCallback(
    (id) => dispatch(actions.request.adminActions.dosages.getDosagesID.get(id)),
    [dispatch],
  );

  useEffect(() => {
    param.id && getDosagesID(param.id);
  }, []);

  return (
    <>
      <Header />
      <BudsDosage id={param.id} />
    </>
  );
};

export default BudsDosageEdit;
