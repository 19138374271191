import * as interfaces from '../../interfaces';
const baseURL = process.env.REACT_APP_BASE_URL;

export const dosagesApi = {
  getDosagesAll: () => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/admin/dosages/all`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getDosagesID: (id: number) => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/admin/dosages/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  putDosagesID: (id: number, data: interfaces.adminDosagesApi) => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/admin/dosages/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify(data),
    });
  },

  deleteDosagesID: (id: number) => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/admin/dosages/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getDosages: (start: number, limit: number, type: string, search?: string) => {
    const token = localStorage.getItem('accessToken');
    const searchParam = search ? `&search=${search}` : '';

    return fetch(
      `${baseURL}/admin/dosages?start=${start}&limit=${limit}&type=${type}${searchParam}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  postDosages: (data: interfaces.adminDosagesApi) => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/admin/dosages`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify(data),
    });
  },
};
