import * as API from '../../../../../api';
import * as types from '../../../../types/request';

export const putBudsID = {
  put: function (id: number, data: any) {
    return (dispatch: any) => {
      dispatch(this.putPending());

      return API.adminApi.budsApi
        .putBudsID(id, data)
        .then((response: any) => {
          if (response.status === 200) {
            return response
              .json()
              .then((json: any) => dispatch(this.putSuccess(json)));
          } else {
            return response
              .json()
              .then((json: any) => dispatch(this.putFailed(json)));
          }
        })
        .catch((response: any) => {
          return dispatch(this.putFailed(response));
        });
    };
  },

  putSuccess: (response: any) => ({
    type: types.adminTypes.buds.PUT_BUDS_ID_SUCCESS,
    payload: response,
  }),

  putFailed: (response: any) => ({
    type: types.adminTypes.buds.PUT_BUDS_ID_FAILED,
    payload: response,
  }),

  putPending: () => ({
    type: types.adminTypes.buds.PUT_BUDS_ID_PENDING,
  }),
};
