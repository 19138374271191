import React from 'react';
import { ReactSortable } from 'react-sortablejs';

import './style.scss';

interface Props {
  items: any[];
}

const ExtractDetailsBox: React.FC<Props> = ({ items }): JSX.Element => {
  return (
    <ReactSortable
      className='extract__boxes'
      list={items}
      setList={() => {}}
      sort={false}
      group={{
        name: 'recipe',
        pull: 'clone',
        put: false,
      }}
    >
      {items.map((item, index) => (
        <div className='extract__box' key={index}>
          {item.text}
        </div>
      ))}
    </ReactSortable>
  );
};

export default ExtractDetailsBox;
