import * as api from '../../../../api';
import * as types from '../../../types/request';

export const forgot = {
  post: function (data: {}) {
    return (dispatch: any) => {
      dispatch(this.postPending());

      return api.authApi
        .forgot(data)
        .then((response) => {
          if (response.status === 201) {
            return dispatch(this.postSuccess({}));
          } else {
            throw new Error();
          }
        })
        .catch((error) => {
          dispatch(this.postFailed(error));
          throw new Error();
        });
    };
  },

  postSuccess: (response: any) => ({
    type: types.authTypes.AUTH_FORGOT_SUCCESS,
    payload: response,
  }),

  postFailed: (error: any) => ({
    type: types.authTypes.AUTH_FORGOT_FAILED,
    payload: error,
  }),

  postPending: () => ({
    type: types.authTypes.AUTH_FORGOT_PENDING,
  }),
};
