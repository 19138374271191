import React, { useState, useLayoutEffect } from 'react';
import { useLocation } from 'react-router';

import './Extracts.scss';

import ExtractsHead from '../ExtractsHead';
import ExtractsTabs from '../ExtractsTabs';
import ExtractsTable from '../ExtractsTable';
import ExtractsDosages from '../ExtractsDosages';

const Extracts: React.FC = (): JSX.Element => {
  const location: any = useLocation();

  const [tabNumber, setTabNumber] = useState(0);

  let extractsHeadButtonText: string = '';

  if (tabNumber === 0) {
    extractsHeadButtonText = 'Extrakt erstellen';
  }

  if (tabNumber === 1) {
    extractsHeadButtonText = 'Dosierungsanleitung erstellen';
  }

  useLayoutEffect(() => {
    if (location.state) {
      setTabNumber(location.state.tab);
    }
  }, [location]);

  return (
    <div className='extracts'>
      <div className='container'>
        <ExtractsHead buttonText={extractsHeadButtonText} tab={tabNumber} />
        <ExtractsTabs tabNumber={tabNumber} setTabNumber={setTabNumber} />
        {tabNumber === 0 && <ExtractsTable />}
        {tabNumber === 1 && <ExtractsDosages />}
      </div>
    </div>
  );
};

export default Extracts;
