import * as api from '../../../../api';
import * as types from '../../../types/request';

export const getUploads = {
  get: function () {
    return (dispatch: any) => {
      dispatch(this.getPending());

      return api.uploadsApi
        .getUploads()
        .then((response) => {
          if (response.status === 200) {
            return response
              .json()
              .then((json) => dispatch(this.getSuccess(json)));
          } else {
            return response
              .json()
              .then((json) => dispatch(this.getFailed(json)));
          }
        })
        .catch((error) => {
          return dispatch(this.getFailed(error));
        });
    };
  },

  getSuccess: (response: any) => ({
    type: types.uploadsTypes.GET_UPLOADS_SUCCESS,
    payload: response,
  }),

  getFailed: (error: any) => ({
    type: types.uploadsTypes.GET_UPLOADS_FAILED,
    payload: error,
  }),

  getPending: () => ({
    type: types.uploadsTypes.GET_UPLOADS_PENDING,
  }),
};
