import React from 'react';
import { useParams } from 'react-router-dom';

import Header from '../../components/customer/Header';
import BudEditComponent from '../../components/customer/BudEdit';

const BudEdit: React.FC = (): JSX.Element => {
  const { id } = useParams<any>();

  return (
    <>
      <Header />
      <BudEditComponent id={Number(id)} />
    </>
  );
};

export default BudEdit;
