import React from 'react';

import SettingsEditComponent from '../../components/customer/SettingsEdit';

const SettingsEdit: React.FC = (): JSX.Element => {
  return (
    <>
      <SettingsEditComponent />
    </>
  );
};

export default SettingsEdit;
