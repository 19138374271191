import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import * as actions from '../../store/actions';

import './Reset.scss';

interface Props {
  token: string | null;
}

const Reset: React.FC<Props> = ({ token }): JSX.Element => {
  const dispatch: any = useDispatch();
  const history = useHistory();

  const [password, setPassword] = useState({ new: '', repeat: '' });
  const [error, setError] = useState(false);

  const reset = useCallback(
    (token, data) =>
      dispatch(actions.request.authActions.reset.post(token, data)),
    [dispatch],
  );

  useEffect(() => {
    if (!token) history.push('/');
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (password.new !== password.repeat) {
      setError(true);
      return;
    }

    reset(token, { password: password.new })
      .then(() => history.push('/'))
      .catch(() => setError(true));
  };

  return (
    <section className='reset'>
      <div className='container reset__container'>
        <div className='reset__content'>
          <h1 className='reset__title'>Neues Passwort erstellen</h1>
          <form action='#'>
            <div className='reset__field'>
              <label htmlFor='password' className='reset__label'>
                Neues Passwort
              </label>
              <input
                type='password'
                className={`reset__input ${error && 'border-error'}`}
                id='password'
                placeholder='Neues Passwort'
                value={password.new}
                onChange={(event) => {
                  setError(false);
                  setPassword({ ...password, new: event.target.value });
                }}
              />
            </div>
            <div className='reset__field'>
              <label htmlFor='confirm' className='reset__label'>
                Wiederhole das neue Passwort
              </label>
              <input
                type='password'
                className={`reset__input ${error && 'border-error'}`}
                id='confirm'
                placeholder='Wiederhole das neue Passwort'
                value={password.repeat}
                onChange={(event) => {
                  setError(false);
                  setPassword({ ...password, repeat: event.target.value });
                }}
              />
            </div>
            <button
              className='button button--green reset__submit'
              type='button'
              onClick={(event) => handleSubmit(event)}
            >
              Anwenden
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Reset;
