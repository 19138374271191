import React, { useEffect, useState, useMemo } from 'react';
import { Pie } from 'react-chartjs-2';

import './style.scss';

import CustomRadio from '../customRadio';
import ListTimePeriod from '../listTimePeriod';

interface Props {
  title: any;
  configData: any;
  requestData: any;
  options: any;
  settingsRadio: any;
  getData: Function;
}

const PieChart: React.FC<Props> = ({
  title,
  configData,
  requestData,
  options,
  settingsRadio,
  getData,
}): JSX.Element => {
  const [selectRadio, setSelectRadio] = useState('bud');
  const [timePeriod, setTimePeriod] = useState('7d');

  useEffect(() => {
    getData(timePeriod, selectRadio);
  }, [timePeriod, selectRadio]);

  const newConfigData = useMemo(() => {
    if (!requestData) return undefined;

    return {
      ...configData,
      labels: requestData.map((item: any) => item.name),
      datasets: configData.datasets.map((item: any) => ({
        ...item,
        label: selectRadio,
        data: requestData.map((item: any) => {
          if ('count' in item) return item.count;
          if ('amount' in item) return item.amount;
        }),
      })),
    };
  }, [requestData]);

  return (
    <div className='charts__item pie-chart'>
      <p className='charts__item-title'>
        {title}

        <ListTimePeriod value={timePeriod} setValue={setTimePeriod} />
      </p>

      <div className='chart-container'>
        {newConfigData && (
          <Pie
            data={newConfigData}
            options={options}
            className='charts__item-graphs'
          />
        )}
      </div>

      <ul className='settings-list'>
        {settingsRadio.map((item: any, index: number) => (
          <li className='settings-item' key={index}>
            <CustomRadio
              item={item}
              selectRadio={selectRadio}
              setSelectRadio={setSelectRadio}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PieChart;
