import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../../store/actions';
import * as reducers from '../../../store/reducers';
import { CustomerBudsType } from '../../../types';
import { getGenetik } from '../../../utils/getGenetik';
import { getImageUrl } from '../../../utils/getImageUrl';

import './BudsTable.scss';

import DeleteIcon from '../../../images/symbols/delete.svg';
import CloseIcon from '../../../images/symbols/close.svg';
import BudsIcon from '../../../images/bud-icon.svg';
import { ReactComponent as DropdownIcon } from '../../../images/symbols/picture-green-dropdown.svg';
import Pagination from '../Pagination';
import Modal from '../Modal';

interface Props {
  view: string;
  switchButtons: string;
}

const BudsTable: React.FC<Props> = ({ view, switchButtons }): JSX.Element => {
  const dispatch: any = useDispatch();
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [tableView, setTableView] = useState(view);
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);
  const [isAll, setIsAll] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');

  const [removeBudPopupInfo, setRemoveBudPopupInfo] = useState({
    id: 0,
    item: '',
    isOpen: false,
  });
  const [removeBudsPopupInfo, setRemoveBudsPopupInfo] = useState(false);
  const [selectedBuds, setSelectedBuds] = useState<number[]>([]);

  const getBuds = useCallback(
    (start, limit, searchQuery) =>
      dispatch(
        actions.request.customersActions.buds.getBuds.get(
          start,
          limit,
          searchQuery,
        ),
      ),
    [dispatch],
  );
  const deleteBudsID = useCallback(
    (id) =>
      dispatch(actions.request.customersActions.buds.deleteBudsID.delete(id)),
    [dispatch],
  );
  const deleteBuds = useCallback(
    (data) =>
      dispatch(actions.request.customersActions.buds.deleteBuds.delete(data)),
    [dispatch],
  );

  const buds = useSelector(
    reducers.request.customersReducers.budsState.get.value,
  );
  const budsTotal = useSelector(
    reducers.request.customersReducers.budsState.get.total,
  );

  useEffect(() => {
    getBuds(start, limit, searchQuery);
  }, [start, limit, searchQuery]);

  useEffect(() => {
    setStart(currentPage * limit - limit);
  }, [currentPage]);

  useEffect(() => {
    const limit = localStorage.getItem(`buds_${tableView}_limit`);
    if (!limit) {
      setLimit(tableView === 'table' ? 10 : 9);
      setIsAll(false);
    } else if (limit === 'all') {
      setLimit(budsTotal);
      setIsAll(true);
    } else {
      setLimit(Number(limit));
      setIsAll(false);
    }
  }, [budsTotal, tableView]);

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSelect = (event: any) => {
    const { value } = event.target;
    localStorage.setItem(`buds_${tableView}_limit`, value);
    if (value === 'all') {
      setLimit(budsTotal);
      setIsAll(true);
    } else {
      setLimit(value);
      setIsAll(false);
    }
  };

  const handleInput = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const onToggleSelectedBuds = (id: number) => {
    if (selectedBuds.includes(id)) {
      setSelectedBuds(selectedBuds.filter((d: number) => d !== id));
    } else {
      setSelectedBuds([id, ...selectedBuds]);
    }
  };

  const tableViewRedirectFactory = (id: number) => {
    return (event: any) => {
      if (event.target.tagName === 'TD') history.push(`/customer/buds/${id}`);
    };
  };

  const listViewRedirectFactory = (id: number) => {
      console.log('the object id:' , id);
      history.push(`/customer/buds/${id}`);
  };

  return (
    <div className='customer-buds__table-wrapper'>
      <div
        className={`customer-buds__table-switch customer-buds__table-switch--${switchButtons}`}
      >
        <div
          className='customer-buds__table-switch-button customer-buds__table-switch-button--list'
          onClick={() => {
            setTableView('list');
            localStorage.setItem('budsTableView', 'list');
            setIsAll(false);
          }}
        >
          <div
            className={`customer-buds__table-switch-button-square ${
              tableView === 'list' &&
              'customer-buds__table-switch-button-square--active'
            }`}
          />
          <div
            className={`customer-buds__table-switch-button-square ${
              tableView === 'list' &&
              'customer-buds__table-switch-button-square--active'
            }`}
          />
          <div
            className={`customer-buds__table-switch-button-square ${
              tableView === 'list' &&
              'customer-buds__table-switch-button-square--active'
            }`}
          />
          <div
            className={`customer-buds__table-switch-button-square ${
              tableView === 'list' &&
              'customer-buds__table-switch-button-square--active'
            }`}
          />
        </div>
        <div
          className='customer-buds__table-switch-button customer-buds__table-switch-button--table'
          onClick={() => {
            setTableView('table');
            localStorage.setItem('budsTableView', 'table');
            setIsAll(false);
          }}
        >
          <div
            className={`customer-buds__table-switch-button-line ${
              tableView === 'table' &&
              'customer-buds__table-switch-button-line--active'
            }`}
          />
          <div
            className={`customer-buds__table-switch-button-line ${
              tableView === 'table' &&
              'customer-buds__table-switch-button-line--active'
            }`}
          />
          <div
            className={`customer-buds__table-switch-button-line ${
              tableView === 'table' &&
              'customer-buds__table-switch-button-line--active'
            }`}
          />
          <div
            className={`customer-buds__table-switch-button-line ${
              tableView === 'table' &&
              'customer-buds__table-switch-button-line--active'
            }`}
          />
        </div>
      </div>
      <div
        className={`customer-buds__table-filter customer-buds__table-filter--${switchButtons}`}
      >
        <div className='customer-buds__table-select-wrapper'>
          <label
            htmlFor='customer-buds-number'
            className='customer-buds__table-select-label'
          >
            Einträge anzeigen:
          </label>
          <select
            name='customer-buds-number'
            id='customer-buds-number'
            className='customer-buds__table-select'
            value={isAll ? 'all' : limit}
            onChange={handleSelect}
          >
            <option value={tableView === 'table' ? 5 : 6}>
              {tableView === 'table' ? 5 : 6}
            </option>
            <option value={tableView === 'table' ? 10 : 9}>
              {tableView === 'table' ? 10 : 9}
            </option>
            <option value={tableView === 'table' ? 15 : 12}>
              {tableView === 'table' ? 15 : 12}
            </option>
            <option value={tableView === 'table' ? 20 : 15}>
              {tableView === 'table' ? 20 : 15}
            </option>
            {tableView === 'table' && <option value='all'>Alles</option>}
          </select>
        </div>
        <form
          action='#'
          className='customer-buds__table-search'
          onSubmit={(event) => event.preventDefault()}
        >
          <div className='customer-buds__table-field'>
            <input
              type='text'
              placeholder='Suchen'
              className='customer-buds__table-input'
              value={searchQuery}
              onChange={handleInput}
            />
          </div>
        </form>
      </div>
      {tableView === 'table' && (
        <table className='customer-buds__table'>
          <thead>
            <tr>
              <th />
              <th className='customer-buds__table-title'>Bud</th>
              <th className='customer-buds__table-title'>Kultivar / Genetik</th>
              <th className='customer-buds__table-title'>TCH / CBD (%)</th>
              <th className='customer-buds__table-title'>Unternehmen</th>
              <th className='customer-buds__table-title'>Status</th>
              <th className='customer-buds__table-title'>Bild</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {buds.map((bud: CustomerBudsType, index: number) => (
              <tr
                key={bud.id}
                className='customer-buds__table-row'
                onClick={tableViewRedirectFactory(bud.id)}
              >
                <td className='customer-buds__table-row-index'>
                  <input
                    checked={selectedBuds.includes(bud.id)}
                    type='checkbox'
                    className='buds__table-row-checkbox'
                    onChange={() => onToggleSelectedBuds(bud.id)}
                  />
                  {index + 1 + start}.
                </td>
                <td className='customer-buds__table-row-item'>{bud.name}</td>
                <td className='customer-buds__table-row-item'>
                  {bud.kultivar || 'k.A.'} / {getGenetik(bud.genetik)}
                </td>
                <td className='customer-buds__table-row-item'>
                  {bud.thc} / {bud.cbd}
                </td>
                <td className='customer-buds__table-row-item'>
                  {bud.companyName}
                </td>
                <td
                  className={`customer-buds__table-row-item  ${
                    bud.active
                      ? 'customer-buds__table-row-item--active'
                      : 'customer-buds__table-row-item--inactive'
                  }`}
                >
                  {bud.active ? 'Aktiv' : 'Inaktiv'}
                </td>
                <td className='customer-buds__table-row-item customer-buds__table-row-item--picture'>
                  <DropdownIcon />
                  <div className='customer-buds__table-row-item-popup'>
                    <img src={getImageUrl(bud.image) || BudsIcon} alt='' />
                    <div className='triangle' />
                  </div>
                </td>
                <td className='customer-buds__table-row-button'>
                  <button
                    className='button'
                    onClick={() => {
                      setRemoveBudPopupInfo({
                        id: bud.id,
                        item: bud.name,
                        isOpen: true,
                      });
                    }}
                  >
                    <img src={DeleteIcon} alt='delete' />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {tableView === 'list' && (
        <div className='customer-buds__list'>
          {buds.map((bud: CustomerBudsType) => (
            <div
              className='customer-buds__list-item'
              key={bud.id}
              onClick={ () => listViewRedirectFactory(bud.id)}
            >
              <div className='customer-buds__list-image-wrapper'>
                <input
                  checked={selectedBuds.includes(bud.id)}
                  type='checkbox'
                  className='customer-buds__list-item-checkbox'
                  onChange={() => onToggleSelectedBuds(bud.id)}
                />
                <div className='customer-buds__list-image-head'>
                  <img
                    src={getImageUrl(bud.companyImage)}
                    alt=''
                    className='customer-buds__list-image-logo'
                  />
                  <p className='customer-buds__list-image-text'>
                    <span>{bud.name}</span>
                    <br />
                    {bud.companyName}
                  </p>
                </div>
                <img
                  src={getImageUrl(bud.image) || BudsIcon}
                  alt=''
                  className='customer-buds__list-image'
                />
                <div className='customer-buds__list-image-description'>
                  <span>{bud.kultivar || 'k.A.'}</span>
                  <br />
                  <p>{getGenetik(bud.genetik)}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {!isAll && (
        <Pagination
          itemsPerPage={limit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalItems={budsTotal || 1}
          paginate={paginate}
        />
      )}

      {!!selectedBuds.length && (
        <div className='customer-buds__table-buttons'>
          <button
            className='button button--dark customer-buds__table-button'
            onClick={() => setRemoveBudsPopupInfo(true)}
          >
            Ausgewählte löschen {selectedBuds.length}
          </button>
        </div>
      )}

      {removeBudPopupInfo.isOpen && (
        <Modal>
          <div className='customer-buds__table-modal'>
            <button
              className='customer-buds__table-modal-close'
              onClick={() =>
                setRemoveBudPopupInfo({ id: 0, item: '', isOpen: false })
              }
            >
              <img src={CloseIcon} alt='close' />
            </button>
            <p className='customer-buds__table-modal-title'>Sorte löschen?</p>
            <p className='customer-buds__table-modal-paragraph'>
              Sie haben vor, den Bud "{removeBudPopupInfo.item}" zu löschen.
            </p>
            <p className='customer-buds__table-modal-paragraph'>
              Diese Änderungen kann nicht rückgängig gemacht werden.
            </p>
            <div className='customer-buds__table-modal-buttons'>
              <button
                className='button customer-buds__table-modal-button customer-buds__table-modal-button--delete'
                onClick={() =>
                  deleteBudsID(removeBudPopupInfo.id).then(() => {
                    setRemoveBudPopupInfo({ id: 0, item: '', isOpen: false });
                    getBuds(start, limit, searchQuery);
                  })
                }
              >
                Löschen
              </button>
              <button
                className='button customer-buds__table-modal-button customer-buds__table-modal-button--cancel'
                onClick={() =>
                  setRemoveBudPopupInfo({ id: 0, item: '', isOpen: false })
                }
              >
                Abbrechen
              </button>
            </div>
          </div>
        </Modal>
      )}

      {removeBudsPopupInfo && (
        <Modal>
          <div className='customer-buds__table-modal'>
            <button
              className='customer-buds__table-modal-close'
              onClick={() => setRemoveBudsPopupInfo(false)}
            >
              <img src={CloseIcon} alt='close' />
            </button>
            <p className='customer-buds__table-modal-title'>
              {selectedBuds.length} Blüten löschen?
            </p>
            <p className='customer-buds__table-modal-paragraph'>
              Sie haben vor, {selectedBuds.length} ausgewählte Blüten zu löschen.
            </p>
            <p className='customer-buds__table-modal-paragraph'>
              Nach dem Entfernen können Sie diese Änderungen nicht mehr
              rückgängig machen.
            </p>
            <div className='customer-buds__table-modal-buttons'>
              <button
                className='button customer-buds__table-modal-button customer-buds__table-modal-button--delete'
                onClick={() =>
                  deleteBuds({ budsIds: selectedBuds }).then(() => {
                    setRemoveBudsPopupInfo(false);
                    setSelectedBuds([]);
                    getBuds(start, limit, searchQuery);
                  })
                }
              >
                Löschen
              </button>
              <button
                className='button customer-buds__table-modal-button customer-buds__table-modal-button--cancel'
                onClick={() => setRemoveBudsPopupInfo(false)}
              >
                Abbrechen
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default BudsTable;
