import * as API from '../../../../../api';
import * as types from '../../../../types/request';

export const deleteDosagesID = {
  delete: function (id: number) {
    return (dispatch: any) => {
      dispatch(this.deletePending());

      return API.adminApi.dosagesApi
        .deleteDosagesID(id)
        .then((response: any) => {
          if (response.status === 200) {
            return response
              .json()
              .then((json: any) => dispatch(this.deleteSuccess(json)));
          } else {
            return response
              .json()
              .then((json: any) => dispatch(this.deleteFailed(json)));
          }
        })
        .catch((response: any) => {
          return dispatch(this.deleteFailed(response));
        });
    };
  },

  deleteSuccess: (response: any) => ({
    type: types.adminTypes.dosages.DELETE_DOSAGES_ID_SUCCESS,
    payload: response,
  }),

  deleteFailed: (response: any) => ({
    type: types.adminTypes.dosages.DELETE_DOSAGES_ID_FAILED,
    payload: response,
  }),

  deletePending: () => ({
    type: types.adminTypes.dosages.DELETE_DOSAGES_ID_PENDING,
  }),
};
