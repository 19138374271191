import * as interfaces from '../../interfaces';
const baseURL = process.env.REACT_APP_BASE_URL;

export const extractsApi = {
  getExtractsAll: () => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/customers/extracts/all`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getExtractsID: (id: number) => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/customers/extracts/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  putExtractsID: (id: number, data: interfaces.customersExtractsApi) => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/customers/extracts/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify(data),
    });
  },

  deleteExtractsID: (id: number) => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/customers/extracts/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getExtracts: (start: number, limit: number, search?: string) => {
    const token = localStorage.getItem('accessToken');
    const searchParam = search ? `&search=${search}` : '';

    return fetch(
      `${baseURL}/customers/extracts?start=${start}&limit=${limit}${searchParam}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  deleteExtracts: (data: { extractsIds: number[] }) => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/customers/extracts`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify(data),
    });
  },
};
