import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as reducer from '../../../store/reducers';
import * as actions from '../../../store/actions';
import { getImageUrl } from '../../../utils/getImageUrl';

import './ExtractDetails.scss';

import ExtractDetailsBox from './components/ExtractDetailsBox';
import ExtractDetailsDustbin from './components/ExtractDetailsDustbin';
import Modal from '../Modal';
import EditIcon from '../../../images/symbols/edit.svg';
import CloseIcon from '../../../images/symbols/close.svg';
import ExtractIcon from '../../../images/extract-icon.svg';

import ResultInstructions from './components/ResultInstructions';

interface Props {
  isNewPage: boolean;
  id: number;
}

const ExtractDetails: React.FC<Props> = ({ isNewPage, id }): JSX.Element => {
  const dispatch: any = useDispatch();
  const history = useHistory();

  const [dataItem, setDataItem] = useState({
    name: '',
    companyName: '',
    kultivar: '',
    cbd: '0',
    thc: '0',
    genetik: 'na',
    country: '',
    companyImage: '',
    image: '',
    used: 0,
    recipe: new Array<any>(),
    isConcentration: false,
    unit: 'mg/ml',
    concentrationTarget: 'thc',
  });

  const [errors, setErrors] = useState({
    companyName: false,
    name: false,
    country: false,
    cbd: false,
    thc: false,
    companyImage: false,
  });

  const [selectedImg, setSelectedImg] = useState('');

  const [boxesToShow, setBoxesToShow] = useState<any[]>([]);

  const [isImageOpen, setIsImageOpen] = useState({ name: '', isOpen: false });
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);

  const configGenetik = [
    {
      name: 'Indica',
      key: 'indica',
    },
    {
      name: 'Sativa',
      key: 'sativa',
    },
    {
      name: 'Hybrid',
      key: 'hybrid',
    },
    {
      name: 'Hybrid (indica-dominant)',
      key: 'hybrid_indica',
    },
    {
      name: 'Hybride (sativa-dominant)',
      key: 'hybrid_sativa',
    },
    {
      name: 'k.A.',
      key: 'na',
    },
  ];

  const configUnits = [
    {
      name: 'mg/ml',
      key: 'mg_ml',
    },
    {
      name: 'mg/g',
      key: 'mg_g',
    },
  ];

  const configConcentrationTarget = [
    {
      name: 'thc',
      key: 'thc_unit',
    },
    {
      name: 'cbd',
      key: 'cbd_unit',
    },
  ];

  const configDragDropItems = [
    {
      type: 'symbol',
      text: 'Produktname',
      symbol: 'Produktname',
      name: 'Produktname',
    },
    { type: 'box', text: 'Verschriebene Menge' },
    {
      type: 'field',
      text: 'Wirkstoffmenge basierend auf Multiplikator',
      name: 'Wirkstoffmenge basierend auf Multiplikator',
      value: '',
    },
    { type: 'box', text: 'Gesamte Menge des Mittels "THC"' },
    { type: 'box', text: 'Dosierungsanleitung' },
    { type: 'box', text: '"A" hinzufügen, wenn Gesamtmenge "THC" > 1000mg' },
    {
      type: 'symbol',
      text: '"ml" hinzufügen',
      symbol: 'ml',
      name: 'Hinzufügen',
    },
    { type: 'symbol', text: '"g" hinzufügen', symbol: 'g', name: 'Hinzufügen' },
    {
      type: 'symbol',
      text: '"mg" hinzufügen',
      symbol: 'mg',
      name: 'Hinzufügen',
    },
    { type: 'symbol', text: '"," hinzufügen', symbol: ',', name: 'Hinzufügen' },
    { type: 'field', text: 'Eingabefeld', name: 'Eingabefeld', value: '' },
  ];

  if (dataItem.isConcentration) {
    configDragDropItems.push({ type: 'box', text: 'THC per Einheit' });
    configDragDropItems.push({ type: 'box', text: 'CBD per Einheit' });
  }

  const getUploads = useCallback(
    () => dispatch(actions.request.uploadsActions.getUploads.get()),
    [dispatch],
  );
  const putExtractsID = useCallback(
    (id, data) =>
      dispatch(
        actions.request.adminActions.extracts.putExtractsID.put(id, data),
      ),
    [dispatch],
  );
  const postExtacts = useCallback(
    (data) =>
      dispatch(actions.request.adminActions.extracts.postExtracts.post(data)),
    [dispatch],
  );
  const postUploads = useCallback(
    (file) => dispatch(actions.request.uploadsActions.postUploads.post(file)),
    [dispatch],
  );

  const extractsID = useSelector(
    reducer.request.adminReducers.extractsState.getID.value,
  );
  const uploads = useSelector(reducer.request.uploadsState.getUploads.value);

  const onUploadImage = (event: any) => {
    const formData = new FormData();
    const { files } = event.target;

    formData.append('file', files[0]);

    postUploads(formData).then((response: any) => {
      if (response.type === 'POST_UPLOADS_SUCCESS') {
        setIsImageOpen({ name: '', isOpen: false });
        setDataItem({ ...dataItem, [isImageOpen.name]: response.payload });
      }
    });
  };

  const handleSubmit = () => {
    const inputErrors = {
      companyName: false,
      name: false,
      country: false,
      cbd: false,
      thc: false,
      companyImage: false,
    };

    if (dataItem.companyName.trim() === '') {
      inputErrors.companyName = true;
    }

    if (dataItem.name.trim() === '') {
      inputErrors.name = true;
    }

    if (dataItem.country.trim() === '') {
      inputErrors.country = true;
    }

    if (String(dataItem.cbd).trim() === '') {
      inputErrors.cbd = true;
    }

    if (String(dataItem.thc).trim() === '') {
      inputErrors.thc = true;
    }

    if (dataItem.companyImage.trim() === '') {
      inputErrors.companyImage = true;
    }

    if (Object.values(inputErrors).includes(true)) {
      setErrors(inputErrors);
    } else {
      const normalizedData = {
        ...dataItem,
        cbd: Number(dataItem.cbd),
        thc: Number(dataItem.thc),
      };

      id
        ? putExtractsID(id, normalizedData).then(() =>
            history.push('/admin/extracts'),
          )
        : postExtacts(normalizedData).then(() =>
            history.push('/admin/extracts'),
          );
    }
  };

  useEffect(() => {
    if (id && Object.keys(extractsID).length) {
      setDataItem(extractsID);
      setBoxesToShow(extractsID.recipe.map((item: any) => JSON.parse(item)));
    }
  }, [extractsID]);

  const clearBoxes = () => {
    setBoxesToShow([]);
  };

  useEffect(() => {
    setDataItem({
      ...dataItem,
      recipe: boxesToShow.map((item: any) => {
        if (item.type === 'field') {
          return JSON.stringify(item);
        } else if (item.type === 'box') {
          return JSON.stringify(item);
        } else if (item.type === 'symbol') {
          return JSON.stringify(item);
        }

        return item;
      }),
    });
  }, [boxesToShow]);

  useEffect(() => {
    isGalleryOpen && getUploads();
  }, [isGalleryOpen]);

  return (
    <>
      <div className='extract'>
        <div className='container'>
          <h1 className='extract__title'>Extraktdetails</h1>
          <form className='extract__form'>
            <div className='extract__first-step'>
              <div className='extract__info'>
                <div className='extract__left'>
                  <h2 className='extract__subtitle'>Schritt 1</h2>
                  <div className='extract__left-images'>
                    <div
                      className={`extract__left-image ${
                        errors.companyImage && 'border-error'
                      }`}
                    >
                      {dataItem.companyImage ? (
                        <>
                          <img
                            src={getImageUrl(dataItem.companyImage)}
                            alt=''
                          />
                          <button
                            className='extract__left-image-edit'
                            type='button'
                            onClick={() =>
                              setIsImageOpen({
                                name: 'companyImage',
                                isOpen: true,
                              })
                            }
                          >
                            <img src={EditIcon} alt='edit' />
                          </button>
                        </>
                      ) : (
                        <div
                          className='extract__left-image-wrapper'
                          onClick={() => {
                            setErrors({ ...errors, companyImage: false });
                            setIsImageOpen({
                              name: 'companyImage',
                              isOpen: true,
                            });
                          }}
                        >
                          <p className='extract__left-image-title'>
                            Unternehmenslogo hochladen
                          </p>
                          <p className='extract__left-image-subtitle'>
                            Größe 160x45px
                          </p>
                          {errors.companyImage && (
                            <p className='field-error'>
                              Bitte, fügen Sie ein Bild hinzu
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                    <div className='extract__left-image'>
                      {dataItem.image ? (
                        <>
                          <img src={getImageUrl(dataItem.image)} alt='' />
                          <button
                            className='extract__left-image-edit'
                            type='button'
                            onClick={() =>
                              setIsImageOpen({ name: 'image', isOpen: true })
                            }
                          >
                            <input
                              type='file'
                              id='choose-file-2'
                              style={{ display: 'none' }}
                              onChange={(event) => {}}
                            />
                            <img src={EditIcon} alt='edit' />
                          </button>
                        </>
                      ) : (
                        <div
                          className='extract__left-image-wrapper'
                          onClick={() => {
                            setIsImageOpen({ name: 'image', isOpen: true });
                          }}
                        >
                          <p className='extract__left-image-title'>
                            Unternehmenslogo hochladen
                          </p>
                          <p className='extract__left-image-subtitle'>
                            Größe 160x45px
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='extract__form-inputs'>
                    <div className='extract__form-left'>
                      <div className='extract__form-field'>
                        <label
                          htmlFor='company-name'
                          className='extract__form-label'
                        >
                          Unternehmensname
                        </label>
                        <input
                          type='text'
                          className={`extract__form-input ${
                            errors.companyName && 'border-error'
                          }`}
                          id='company-name'
                          value={dataItem.companyName}
                          onChange={(event) => {
                            setErrors({ ...errors, companyName: false });
                            setDataItem({
                              ...dataItem,
                              companyName: event.target.value,
                            });
                          }}
                        />
                        {errors.companyName && (
                          <p className='field-error'>
                            Der Unternehmensname darf nicht leer sein
                          </p>
                        )}
                      </div>

                      <div className='extract__form-field'>
                        <label htmlFor='name' className='extract__form-label'>
                          Name
                        </label>
                        <input
                          type='text'
                          className={`extract__form-input ${
                            errors.name && 'border-error'
                          }`}
                          id='name'
                          value={dataItem.name}
                          onChange={(event) => {
                            setErrors({ ...errors, name: false });
                            setDataItem({
                              ...dataItem,
                              name: event.target.value,
                            });
                          }}
                        />
                        {errors.name && (
                          <p className='field-error'>
                            Der Name darf nicht leer sein
                          </p>
                        )}
                      </div>

                      <div className='extract__form-field'>
                        <label
                          htmlFor='country'
                          className='extract__form-label'
                        >
                          Land
                        </label>
                        <input
                          type='text'
                          className={`extract__form-input ${
                            errors.country && 'border-error'
                          }`}
                          id='country'
                          value={dataItem.country}
                          onChange={(event) => {
                            setErrors({ ...errors, country: false });
                            setDataItem({
                              ...dataItem,
                              country: event.target.value,
                            });
                          }}
                        />
                        {errors.country && (
                          <p className='field-error'>
                            Das Land darf nicht leer sein
                          </p>
                        )}
                      </div>
                    </div>

                    <div className='extract__form-right'>
                      <div className='extract__form-field'>
                        <label
                          htmlFor='cultivar'
                          className='extract__form-label'
                        >
                          Kultivar
                        </label>
                        <input
                          type='text'
                          className='extract__form-input'
                          id='cultivar'
                          value={dataItem.kultivar}
                          onChange={(event) => {
                            setDataItem({
                              ...dataItem,
                              kultivar: event.target.value,
                            });
                          }}
                        />
                      </div>

                      <div className='extract__form-field'>
                        <label htmlFor='cbd' className='extract__form-label'>
                          {dataItem.isConcentration
                            ? 'Maximale CBD-Menge'
                            : `CBD-Menge (${dataItem.unit})`}
                        </label>
                        <input
                          type='text'
                          className={`extract__form-input ${
                            errors.cbd && 'border-error'
                          }`}
                          id='cbd'
                          value={dataItem.cbd}
                          onChange={(event) => {
                            setErrors({ ...errors, cbd: false });

                            let { value } = event.target;
                            value = value.trim();
                            if (!Number.isNaN(+value))
                              setDataItem({ ...dataItem, cbd: value });
                          }}
                        />
                        {errors.cbd && (
                          <p className='field-error'>
                            CBD darf nicht leer sein
                          </p>
                        )}
                      </div>

                      <div className='extract__form-field'>
                        <label htmlFor='thc' className='extract__form-label'>
                          {dataItem.isConcentration
                            ? 'Maximale THC-Menge'
                            : `THC-Menge (${dataItem.unit})`}
                        </label>
                        <input
                          type='text'
                          className={`extract__form-input ${
                            errors.thc && 'border-error'
                          }`}
                          id='thc'
                          value={dataItem.thc}
                          onChange={(event) => {
                            setErrors({ ...errors, thc: false });

                            let { value } = event.target;
                            value = value.trim();
                            if (!Number.isNaN(+value))
                              setDataItem({ ...dataItem, thc: value });
                          }}
                        />
                        <div className='extract__form-individual'>
                          <label htmlFor='individual'>
                            <input
                              type='checkbox'
                              id='individual'
                              checked={dataItem.isConcentration}
                              onChange={(event) => {
                                setDataItem({
                                  ...dataItem,
                                  isConcentration: event.target.checked,
                                });
                              }}
                            />
                            individuelle Konzentration möglich
                          </label>
                        </div>

                        {dataItem.isConcentration && (
                          <div className='extract__form-radio__concentration-target'>
                            <div className='extract__form-radio-items extract__form-radio-items--left'>
                              {configConcentrationTarget.map((item) => (
                                <p
                                  className='extract__form-radio-item'
                                  key={item.key}
                                >
                                  <input
                                    type='radio'
                                    name='concentrationUnits'
                                    id={item.key}
                                    checked={
                                      dataItem.concentrationTarget === item.name
                                    }
                                    onChange={() => {
                                      setDataItem({
                                        ...dataItem,
                                        concentrationTarget: item.name,
                                      });
                                    }}
                                  />
                                  <label
                                    htmlFor={item.key}
                                    className='extract__form-radio-label'
                                  >
                                    {item.name.toUpperCase()}
                                  </label>
                                </p>
                              ))}
                            </div>
                          </div>
                        )}

                        {errors.thc && (
                          <p className='field-error'>
                            THC darf nicht leer sein
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='extract__form-radio'>
                    <p className='extract__form-radio-title'>Einheit</p>
                    <div className='extract__form-radio-items extract__form-radio-items--left'>
                      {configUnits.map((item) => (
                        <p className='extract__form-radio-item' key={item.key}>
                          <input
                            type='radio'
                            name='units'
                            id={item.key}
                            checked={dataItem.unit === item.name}
                            onChange={() => {
                              setDataItem({ ...dataItem, unit: item.name });
                            }}
                          />
                          <label
                            htmlFor={item.key}
                            className='extract__form-radio-label'
                          >
                            {item.name}
                          </label>
                        </p>
                      ))}
                    </div>
                  </div>

                  <div className='extract__form-radio'>
                    <p className='extract__form-radio-title'>Genetik</p>
                    <div className='extract__form-radio-items'>
                      {configGenetik.map((item: any) => (
                        <p className='extract__form-radio-item' key={item.key}>
                          <input
                            type='radio'
                            name='genetics'
                            id={item.key}
                            checked={dataItem.genetik === item.key}
                            onChange={() => {
                              setDataItem({ ...dataItem, genetik: item.key });
                            }}
                          />
                          <label
                            htmlFor={item.key}
                            className='extract__form-radio-label'
                          >
                            {item.name}
                          </label>
                        </p>
                      ))}
                    </div>
                  </div>
                </div>

                <div className='extract__right'>
                  <h2 className='extract__right-title'>Vorschau</h2>
                  <div className='extract__right-box'>
                    <div className='extract__right-box-head'>
                      {dataItem.companyImage ? (
                        <img
                          src={getImageUrl(dataItem.companyImage)}
                          alt='logo'
                          className='extract__right-box-logo'
                        />
                      ) : (
                        <div className='extract__right-box-logo' />
                      )}
                      <div className='extract__right-box-description'>
                        <p className='extract__right-box-title'>
                          {dataItem.name || 'Name'}
                        </p>
                        <p className='extract__right-box-paragraph'>
                          {dataItem.companyName || 'Unternehmensname'}
                        </p>
                      </div>
                    </div>
                    <div className='extract__right-box-image'>
                      {dataItem.image ? (
                        <img src={getImageUrl(dataItem.image)} alt='image' />
                      ) : (
                        <img src={ExtractIcon} alt='image' />
                      )}
                    </div>
                    <div className='extract__right-box-text'>
                      <p className='extract__right-box-text-title'>
                        {dataItem.kultivar || 'Kultivar'}
                      </p>
                      <div className='extract__right-box-text-paragraph'>
                        {configGenetik.find(
                          (genetik) => genetik.key === dataItem.genetik,
                        )?.name || 'Die Genetik der Sorte.'}
                      </div>
                    </div>
                  </div>
                  {isNewPage && (
                    <p className='extract__right-description'>
                      Customers use this extract: <span>{dataItem.used}</span>
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className='extract__second-step'>
              <div className='extract__section'>
                <h2 className='extract__subtitle'>Schritt 2</h2>
                <p className='extract__info-subtitle'>
                  Komponente per Drag & Drop selektieren
                </p>
                <ExtractDetailsBox items={configDragDropItems} />
              </div>

              <div className='extract__section'>
                <p className='extract__info-subtitle'>
                  Hier droppen, um Anleitung zu erstellen
                </p>
                <button
                  className='extract__clear-boxes'
                  type='button'
                  onClick={clearBoxes}
                >
                  Löschen
                </button>
                <ExtractDetailsDustbin
                  boxes={boxesToShow}
                  setBoxes={setBoxesToShow}
                  extractName={dataItem.name}
                />
              </div>

              <ResultInstructions
                boxes={boxesToShow}
                extractName={dataItem.name}
              />
            </div>

            <div className='extract__form-buttons'>
              <button
                className='button extract__form-button extract__form-button--cancel'
                type='button'
                onClick={() => {
                  history.push('/admin/extracts');
                }}
              >
                Abbrechen
              </button>
              <button
                className='button extract__form-button extract__form-button--save'
                type='button'
                onClick={handleSubmit}
              >
                Speichern
              </button>
            </div>
          </form>
        </div>
      </div>

      {isImageOpen.isOpen && (
        <Modal>
          <div className='extract__upload'>
            <h1 className='extract__upload-title'>Bild hochladen:</h1>
            <div className='extract__upload-buttons'>
              <button
                className='button button--dark extract__upload-button'
                type='button'
                onClick={() => {
                  setIsImageOpen({ ...isImageOpen, isOpen: false });
                  setIsGalleryOpen(true);
                }}
              >
                Von Galerie wählen
              </button>
              <input
                type='file'
                className='extract__upload-button extract__upload-button--input'
                onChange={onUploadImage}
              />
            </div>
            <button
              className='extract__upload-close'
              type='button'
              onClick={() => setIsImageOpen({ name: '', isOpen: false })}
            >
              <img src={CloseIcon} alt='close' />
            </button>
          </div>
        </Modal>
      )}

      {isGalleryOpen && (
        <Modal mode='gallery'>
          <div className='extract__gallery'>
            <h1 className='extract__gallery-title'>
              Bild von Galerie auswählen
            </h1>
            <div className='extract__gallery-list'>
              {uploads.map((img: string) => (
                <div
                  className={[
                    'extract__gallery-item',
                    selectedImg === img && 'selected',
                  ]
                    .filter(Boolean)
                    .join(' ')}
                  key={img}
                  onClick={() => setSelectedImg(img)}
                >
                  <img
                    src={getImageUrl(img)}
                    alt='gallery item'
                    className='extract__gallery-image'
                  />
                </div>
              ))}
            </div>
            <div className='extract__gallery-buttons'>
              <button
                className='extract__gallery-button button button--dark'
                type='button'
                onClick={() => {
                  setIsGalleryOpen(false);
                  setSelectedImg('');
                }}
              >
                Schließen
              </button>
              <button
                className='extract__gallery-button button button--green'
                type='button'
                onClick={() => {
                  setDataItem({ ...dataItem, [isImageOpen.name]: selectedImg });
                  setIsGalleryOpen(false);
                  setSelectedImg('');
                }}
              >
                Anwenden
              </button>
            </div>
            <button
              className='extract__gallery-close'
              type='button'
              onClick={() => {
                setIsGalleryOpen(false);
                setSelectedImg('');
              }}
            >
              <img src={CloseIcon} alt='close' />
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ExtractDetails;
