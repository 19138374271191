import React from 'react';
import { useParams } from 'react-router-dom';

import Header from '../../components/customer/Header';
import ExtractEditComponent from '../../components/customer/ExtractEdit';

const ExtractEdit: React.FC = (): JSX.Element => {
  const { id } = useParams<any>();

  return (
    <>
      <Header />
      <ExtractEditComponent id={Number(id)} />
    </>
  );
};

export default ExtractEdit;
