import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../../store/actions';
import * as reducers from '../../../store/reducers';
import { FormsType } from '../../../types';

import './BudsForm.scss';

import DeleteIcon from '../../../images/symbols/delete.svg';
import CloseIcon from '../../../images/symbols/close.svg';
import Modal from '../Modal';

type FormsShown = { shown: boolean } & FormsType;

const BudsForm: React.FC = (): JSX.Element => {
  const dispatch: any = useDispatch();

  const [searchQuery, setSearchQuery] = useState('');
  const [removeFormPopupInfo, setRemoveFormPopupInfo] = useState({
    id: 0,
    item: '',
    isOpen: false,
  });

  const getForms = useCallback(
    (searchQuery) =>
      dispatch(
        actions.request.customersActions.forms.getForms.get(searchQuery),
      ),
    [dispatch],
  );

  const deleteFormsID = useCallback(
    (id) =>
      dispatch(actions.request.customersActions.forms.deleteFormsID.delete(id)),
    [dispatch],
  );

  const forms: FormsShown[] = useSelector(
    reducers.request.customersReducers.formsState.get.value,
  ).filter((form: FormsShown) => form.shown);

  useEffect(() => {
    getForms(searchQuery);
  }, [searchQuery]);

  const handleInput = (event: any) => {
    setSearchQuery(event.target.value);
  };

  return (
    <>
      <section className='customer-buds__form'>
        <div className='customer-buds__form-search'>
          <form action='#'>
            <div className='customer-buds__form-field'>
              <input
                type='text'
                placeholder='Suchen'
                className='customer-buds__form-input'
                value={searchQuery}
                onChange={handleInput}
              />
            </div>
          </form>
        </div>
        {forms.map((form) => (
          <div
            className='customer-buds__form-item'
            key={form.id}
            data-url={form.id}
          >
            <button
              className='button customer-buds__form-item-delete'
              onClick={() => {
                setRemoveFormPopupInfo({
                  id: form.id,
                  item: form.name,
                  isOpen: true,
                });
              }}
            >
              <img src={DeleteIcon} alt='delete item' />
            </button>
            <p className='customer-buds__form-item-title'>{form.name}</p>
            <p className='customer-buds__form-item-description'>
              {form.receipe}
            </p>
          </div>
        ))}
      </section>

      {removeFormPopupInfo.isOpen && (
        <Modal>
          <div className='customer-buds__table-modal'>
            <button
              className='customer-buds__table-modal-close'
              onClick={() =>
                setRemoveFormPopupInfo({ id: 0, item: '', isOpen: false })
              }
            >
              <img src={CloseIcon} alt='close' />
            </button>
            <p className='customer-buds__table-modal-title'>
              Abgabeformen löschen?
            </p>
            <p className='customer-buds__table-modal-paragraph'>
              Sie haben vor, die Abgabeformen "{removeFormPopupInfo.item}" zu
              löschen.
            </p>
            <p className='customer-buds__table-modal-paragraph'>
              Diese Änderungen kann nicht rückgängig gemacht werden.
            </p>
            <div className='customer-buds__table-modal-buttons'>
              <button
                className='button customer-buds__table-modal-button customer-buds__table-modal-button--delete'
                onClick={() =>
                  deleteFormsID(removeFormPopupInfo.id).then(() => {
                    setRemoveFormPopupInfo({
                      id: 0,
                      item: '',
                      isOpen: false,
                    });
                    getForms(searchQuery);
                  })
                }
              >
                Löschen
              </button>
              <button
                className='button customer-buds__table-modal-button customer-buds__table-modal-button--cancel'
                onClick={() =>
                  setRemoveFormPopupInfo({ id: 0, item: '', isOpen: false })
                }
              >
                Abbrechen
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default BudsForm;
