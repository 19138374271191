import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as reducers from '../../../store/reducers';
import { getImageUrl } from '../../../utils/getImageUrl';

import './CustomerInfo.scss';

interface Props {
  id: number;
  isShowContent: boolean;
}

const CustomerInfo: React.FC<Props> = ({ id, isShowContent }): JSX.Element => {
  const history = useHistory();

  const customersID = useSelector(
    reducers.request.adminReducers.customersState.getID.value,
  );
  const budsAll = useSelector(
    reducers.request.adminReducers.budsState.getAll.value,
  );
  const extractsAll = useSelector(
    reducers.request.adminReducers.extractsState.getAll.value,
  );
  const dosagesAll = useSelector(
    reducers.request.adminReducers.dosagesState.getAll.value,
  );
  const formsAll = useSelector(
    reducers.request.adminReducers.formsState.getAll.value,
  );

  const redirect = (event: any) => {
    history.push(`/admin/customers/${id}/edit`);
  };

  return (
    <>
      {isShowContent && (
        <div className='customer'>
          <div className='container'>
            <button
              className='customer__back'
              onClick={() => {
                history.push('/admin/customers/');
              }}
            >
              Zurück
            </button>
            <h1 className='customer__title'>Hier steht der Kundename</h1>
            <div className='customer__info'>
              <div className='customer__info-general'>
                <div className='customer__info-logo'>
                  <p className='customer__info-title'>Kundenlogo hochladen</p>

                  {customersID.image ? (
                    <div className='customer-edit__info-image'>
                      <img src={getImageUrl(customersID.image)} alt='' />
                    </div>
                  ) : (
                    <div className='customer__info-image'>
                      <p className='customer__info-image-title'>Firmenlogo</p>
                      <p className='customer__info-image-subtitle'>
                        Größe 128x128px
                      </p>
                    </div>
                  )}
                </div>
                <div className='customer__info-general-right'>
                  <div className='customer__info-general-right-top'>
                    <div className='customer__info-item'>
                      <p className='customer__info-title'>Anleitungsname:</p>
                      <p className='customer__info-text'>
                        {customersID.company}
                      </p>
                    </div>
                    <div className='customer__info-item'>
                      <p className='customer__info-title'>E-Mail:</p>
                      <p className='customer__info-text'>{customersID.email}</p>
                    </div>
                    <div className='customer__info-item'>
                      <p className='customer__info-title'>Ist Nutzer aktiv?</p>
                      <p className='customer__info-text'>
                        {customersID.active ? 'Ja (Aktiv)' : 'Nein(Inaktiv)'}
                      </p>
                    </div>
                  </div>
                  <div className='customer__info-general-right-bottom'>
                    <div className='customer__info-item'>
                      <p className='customer__info-title'>
                        Mit Marken/Produkten verbinden:
                      </p>
                      <p className='customer__info-text'>
                        {[
                          ...budsAll
                            .filter((bud: any) =>
                              customersID.budsIds.includes(bud.id),
                            )
                            .map((bud: any) => bud.name),
                          ...extractsAll
                            .filter((extract: any) =>
                              customersID.extractsIds.includes(extract.id),
                            )
                            .map((extract: any) => extract.name),
                        ].join(', ')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='customer__info-section'>
                <p className='customer__info-section-title'>
                  Ansprechpartner Buchhaltung
                </p>
                <div className='customer__info-section-row'>
                  <div className='customer__info-item'>
                    <p className='customer__info-title'>Name:</p>
                    <p className='customer__info-text'>
                      {customersID.bookkeepingName}
                    </p>
                  </div>
                  <div className='customer__info-item'>
                    <p className='customer__info-title'>E-mail:</p>
                    <p className='customer__info-text'>
                      {customersID.bookkeepingEmail}
                    </p>
                  </div>
                  <div className='customer__info-item'>
                    <p className='customer__info-title'>Telefon:</p>
                    <p className='customer__info-text'>
                      {customersID.bookkeepingPhone}
                    </p>
                  </div>
                </div>
              </div>
              <div className='customer__info-section'>
                <p className='customer__info-section-title'>
                  Technischer Kontakt
                </p>
                <div className='customer__info-section-row'>
                  <div className='customer__info-item'>
                    <p className='customer__info-title'>Name:</p>
                    <p className='customer__info-text'>
                      {customersID.technicalName}
                    </p>
                  </div>
                  <div className='customer__info-item'>
                    <p className='customer__info-title'>E-mail:</p>
                    <p className='customer__info-text'>
                      {customersID.technicalEmail}
                    </p>
                  </div>
                  <div className='customer__info-item'>
                    <p className='customer__info-title'>Telefon:</p>
                    <p className='customer__info-text'>
                      {customersID.technicalPhone}
                    </p>
                  </div>
                </div>
              </div>
              <div className='customer__info-section'>
                <p className='customer__info-section-title'>
                  Anleitungen für den Kunden
                </p>
                <p className='customer__info-section-paragraph'>Name:</p>
                <p className='customer__info-section-paragraph'>
                  {dosagesAll
                    .filter((dosage: any) =>
                      customersID.dosagesIds.includes(dosage.id),
                    )
                    .map((dosage: any) => dosage.name)
                    .join(', ')}
                </p>
              </div>
              <div className='customer__info-section'>
                <p className='customer__info-section-title'>
                  Abgabeformen für den Kunden
                </p>
                <p className='customer__info-section-paragraph'>Name:</p>
                <p className='customer__info-section-paragraph'>
                  {formsAll
                    .filter((form: any) =>
                      customersID.formsIds.includes(form.id),
                    )
                    .map((form: any) => form.name)
                    .join(', ')}
                </p>
              </div>
              <button
                className='button customer__edit'
                type='button'
                onClick={redirect}
              >
                Bearbeiten
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomerInfo;
