export const forms = {
  PUT_FORMS_SUCCESS: Symbol('PUT_FORMS_SUCCESS'),
  PUT_FORMS_FAILED: Symbol('PUT_FORMS_FAILED'),
  PUT_FORMS_PENDING: Symbol('PUT_FORMS_PENDING'),

  DELETE_FORMS_ID_SUCCESS: Symbol('DELETE_FORMS_ID_SUCCESS'),
  DELETE_FORMS_ID_FAILED: Symbol('DELETE_FORMS_ID_FAILED'),
  DELETE_FORMS_ID_PENDING: Symbol('DELETE_FORMS_ID_PENDING'),

  GET_FORMS_SUCCESS: Symbol('GET_FORMS_SUCCESS'),
  GET_FORMS_FAILED: Symbol('GET_FORMS_FAILED'),
  GET_FORMS_PENDING: Symbol('GET_FORMS_PENDING'),
};
