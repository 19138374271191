import * as types from '../../../types/request';

const initialState = {
  put: {
    status: null,
    error: null,
  },

  deleteID: {
    status: null,
    error: null,
  },

  get: {
    value: [],
    status: null,
    error: null,
  },
};

export const formsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    // ----- PUT_FORMS_SUCCESS
    case types.customersTypes.forms.PUT_FORMS_SUCCESS:
      return { ...state, put: { ...state.put, status: 'success' } };
    case types.customersTypes.forms.PUT_FORMS_FAILED:
      return {
        ...state,
        put: { ...state.put, status: 'failed', error: action.payload },
      };
    case types.customersTypes.forms.PUT_FORMS_PENDING:
      return {
        ...state,
        put: { ...state.put, status: 'pending', error: null },
      };

    // ----- DELETE_FORMS_ID_SUCCESS
    case types.customersTypes.forms.DELETE_FORMS_ID_SUCCESS:
      return { ...state, deleteID: { ...state.deleteID, status: 'success' } };
    case types.customersTypes.forms.DELETE_FORMS_ID_FAILED:
      return {
        ...state,
        deleteID: {
          ...state.deleteID,
          status: 'failed',
          error: action.payload,
        },
      };
    case types.customersTypes.forms.DELETE_FORMS_ID_PENDING:
      return {
        ...state,
        deleteID: { ...state.deleteID, status: 'pending', error: null },
      };

    // ----- GET_FORMS_SUCCESS
    case types.customersTypes.forms.GET_FORMS_SUCCESS:
      return {
        ...state,
        get: { ...state.get, status: 'success', value: action.payload },
      };
    case types.customersTypes.forms.GET_FORMS_FAILED:
      return {
        ...state,
        get: { ...state.get, status: 'failed', error: action.payload },
      };
    case types.customersTypes.forms.GET_FORMS_PENDING:
      return {
        ...state,
        get: { ...state.get, status: 'pending', error: null },
      };

    default: {
      return state;
    }
  }
};

export const formsState = {
  put: {
    status: (state: any) => state.requestData.customers.forms.putID.status,
    error: (state: any) => state.requestData.customers.forms.putID.error,
  },

  deleteID: {
    status: (state: any) => state.requestData.customers.forms.deleteID.status,
    error: (state: any) => state.requestData.customers.forms.deleteID.error,
  },

  get: {
    value: (state: any) => state.requestData.customers.forms.get.value,
    status: (state: any) => state.requestData.customers.forms.get.status,
    error: (state: any) => state.requestData.customers.forms.get.error,
  },
};
