import * as types from '../../../types/request';

const initialState = {
  getAll: {
    value: [],
    status: null,
    error: null,
  },

  getID: {
    value: {},
    status: null,
    error: null,
  },

  putID: {
    value: {},
    status: null,
    error: null,
  },

  deleteID: {
    status: null,
    error: null,
  },

  get: {
    value: [],
    total: 0,
    status: null,
    error: null,
  },

  post: {
    status: null,
    error: null,
  },

  delete: {
    status: null,
    error: null,
  },
};

export const budsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    // ----- GET_BUDS_ALL_SUCCESS
    case types.adminTypes.buds.GET_BUDS_ALL_SUCCESS:
      return {
        ...state,
        getAll: { ...state.getAll, status: 'success', value: action.payload },
      };
    case types.adminTypes.buds.GET_BUDS_ALL_FAILED:
      return {
        ...state,
        getAll: { ...state.getAll, status: 'failed', error: action.payload },
      };
    case types.adminTypes.buds.GET_BUDS_ALL_PENDING:
      return {
        ...state,
        getAll: { ...state.getAll, status: 'pending', error: null },
      };

    // ----- GET_BUDS_ID_SUCCESS
    case types.adminTypes.buds.GET_BUDS_ID_SUCCESS:
      return {
        ...state,
        getID: { ...state.getID, status: 'success', value: action.payload },
      };
    case types.adminTypes.buds.GET_BUDS_ID_FAILED:
      return {
        ...state,
        getID: { ...state.getID, status: 'failed', error: action.payload },
      };
    case types.adminTypes.buds.GET_BUDS_ID_PENDING:
      return {
        ...state,
        getID: { ...state.getID, status: 'pending', error: null },
      };

    // ----- PUT_BUDS_ID_SUCCESS
    case types.adminTypes.buds.PUT_BUDS_ID_SUCCESS:
      return {
        ...state,
        putID: { ...state.putID, status: 'success', value: action.payload },
      };
    case types.adminTypes.buds.PUT_BUDS_ID_FAILED:
      return {
        ...state,
        putID: { ...state.putID, status: 'failed', error: action.payload },
      };
    case types.adminTypes.buds.PUT_BUDS_ID_PENDING:
      return {
        ...state,
        putID: { ...state.putID, status: 'pending', error: null },
      };

    // ----- DELETE_BUDS_ID_SUCCESS
    case types.adminTypes.buds.DELETE_BUDS_ID_SUCCESS:
      return { ...state, deleteID: { ...state.deleteID, status: 'success' } };
    case types.adminTypes.buds.DELETE_BUDS_ID_FAILED:
      return {
        ...state,
        deleteID: {
          ...state.deleteID,
          status: 'failed',
          error: action.payload,
        },
      };
    case types.adminTypes.buds.DELETE_BUDS_ID_PENDING:
      return {
        ...state,
        deleteID: { ...state.deleteID, status: 'pending', error: null },
      };

    // ----- GET_BUDS_SUCCESS
    case types.adminTypes.buds.GET_BUDS_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          status: 'success',
          value: action.payload.buds,
          total: action.payload.total,
        },
      };
    case types.adminTypes.buds.GET_BUDS_FAILED:
      return {
        ...state,
        get: { ...state.get, status: 'failed', error: action.payload },
      };
    case types.adminTypes.buds.GET_BUDS_PENDING:
      return {
        ...state,
        get: { ...state.get, status: 'pending', error: null },
      };

    // ----- POST_BUDS_SUCCESS
    case types.adminTypes.buds.POST_BUDS_SUCCESS:
      return { ...state, post: { ...state.post, status: 'success' } };
    case types.adminTypes.buds.POST_BUDS_FAILED:
      return {
        ...state,
        post: { ...state.post, status: 'failed', error: action.payload },
      };
    case types.adminTypes.buds.POST_BUDS_PENDING:
      return {
        ...state,
        post: { ...state.post, status: 'pending', error: null },
      };

    // ----- DELETE_BUDS_SUCCESS
    case types.adminTypes.buds.DELETE_BUDS_SUCCESS:
      return { ...state, delete: { ...state.delete, status: 'success' } };
    case types.adminTypes.buds.DELETE_BUDS_FAILED:
      return {
        ...state,
        delete: { ...state.delete, status: 'failed', error: action.payload },
      };
    case types.adminTypes.buds.DELETE_BUDS_PENDING:
      return {
        ...state,
        delete: { ...state.delete, status: 'pending', error: null },
      };

    default: {
      return state;
    }
  }
};

export const budsState = {
  getAll: {
    value: (state: any) => state.requestData.admin.buds.getAll.value,
    status: (state: any) => state.requestData.admin.buds.getAll.status,
    error: (state: any) => state.requestData.admin.buds.getAll.error,
  },

  getID: {
    value: (state: any) => state.requestData.admin.buds.getID.value,
    status: (state: any) => state.requestData.admin.buds.getID.status,
    error: (state: any) => state.requestData.admin.buds.getID.error,
  },

  putID: {
    value: (state: any) => state.requestData.admin.buds.putID.value,
    status: (state: any) => state.requestData.admin.buds.putID.status,
    error: (state: any) => state.requestData.admin.buds.putID.error,
  },

  deleteID: {
    status: (state: any) => state.requestData.admin.buds.deleteID.status,
    error: (state: any) => state.requestData.admin.buds.deleteID.error,
  },

  get: {
    value: (state: any) => state.requestData.admin.buds.get.value,
    total: (state: any) => state.requestData.admin.buds.get.total,
    status: (state: any) => state.requestData.admin.buds.get.status,
    error: (state: any) => state.requestData.admin.buds.get.error,
  },

  post: {
    status: (state: any) => state.requestData.admin.buds.post.status,
    error: (state: any) => state.requestData.admin.buds.post.error,
  },

  delete: {
    status: (state: any) => state.requestData.admin.buds.delete.status,
    error: (state: any) => state.requestData.admin.buds.delete.error,
  },
};
