const baseURL = process.env.REACT_APP_BASE_URL;

export const authApi = {
  login: (data: {}) => {
    return fetch(`${baseURL}/auth/login`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },

      body: JSON.stringify(data),
    });
  },

  refresh: () => {
    const token = localStorage.getItem('refreshToken');

    return fetch(`${baseURL}/auth/refresh`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  logout: () => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/auth/logout`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  change: (data: {}) => {
    const token = localStorage.getItem('accessToken');

    return fetch(`${baseURL}/auth/change`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify(data),
    });
  },

  forgot: (data: {}) => {
    return fetch(`${baseURL}/auth/forgot`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },

      body: JSON.stringify(data),
    });
  },

  reset: (token: string, data: {}) => {
    return fetch(`${baseURL}/auth/reset`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify(data),
    });
  },
};
