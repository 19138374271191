import React from 'react';

import './style.scss';

interface Props {
  item: any;
  selectRadio: any;
  setSelectRadio: any;
}

const CustomRadio: React.FC<Props> = ({
  item,
  selectRadio,
  setSelectRadio,
}): JSX.Element => (
  <label className='container-custom-radio'>
    <input
      type='radio'
      value={item.name}
      checked={item.name === selectRadio}
      onChange={(event) => setSelectRadio(event.target.value)}
    />
    <span className={`checkmark ${item.name}`}></span>

    {item.title}
  </label>
);

export default CustomRadio;
