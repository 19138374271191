export const customers = {
  GET_CUSTOMERS_ID_SUCCESS: 'GET_CUSTOMERS_ID_SUCCESS',
  GET_CUSTOMERS_ID_FAILED: 'GET_CUSTOMERS_ID_FAILED',
  GET_CUSTOMERS_ID_PENDING: 'GET_CUSTOMERS_ID_PENDING',

  PUT_CUSTOMERS_ID_SUCCESS: 'PUT_CUSTOMERS_ID_SUCCESS',
  PUT_CUSTOMERS_ID_FAILED: 'PUT_CUSTOMERS_ID_FAILED',
  PUT_CUSTOMERS_ID_PENDING: 'PUT_CUSTOMERS_ID_PENDING',

  DELETE_CUSTOMERS_ID_SUCCESS: 'DELETE_CUSTOMERS_ID_SUCCESS',
  DELETE_CUSTOMERS_ID_FAILED: 'DELETE_CUSTOMERS_ID_FAILED',
  DELETE_CUSTOMERS_ID_PENDING: 'DELETE_CUSTOMERS_ID_PENDING',

  GET_CUSTOMERS_SUCCESS: 'GET_CUSTOMERS_SUCCESS',
  GET_CUSTOMERS_FAILED: 'GET_CUSTOMERS_FAILED',
  GET_CUSTOMERS_PENDING: 'GET_CUSTOMERS_PENDING',

  POST_CUSTOMERS_SUCCESS: 'POST_CUSTOMERS_SUCCESS',
  POST_CUSTOMERS_FAILED: 'POST_CUSTOMERS_FAILED',
  POST_CUSTOMERS_PENDING: 'POST_CUSTOMERS_PENDING',

  DELETE_CUSTOMERS_SUCCESS: 'DELETE_CUSTOMERS_SUCCESS',
  DELETE_CUSTOMERS_FAILED: 'DELETE_CUSTOMERS_FAILED',
  DELETE_CUSTOMERS_PENDING: 'DELETE_CUSTOMERS_PENDING',
};
