import React from 'react';

import Header from '../../components/admin/Header';
import CustomersComponent from '../../components/admin/Customers';

const Customers: React.FC = (): JSX.Element => {
  return (
    <>
      <Header />
      <CustomersComponent />
    </>
  );
};

export default Customers;
