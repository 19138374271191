export const dosages = {
  PUT_DOSAGES_SUCCESS: Symbol('PUT_DOSAGES_SUCCESS'),
  PUT_DOSAGES_FAILED: Symbol('PUT_DOSAGES_FAILED'),
  PUT_DOSAGES_PENDING: Symbol('PUT_DOSAGES_PENDING'),

  DELETE_DOSAGES_ID_SUCCESS: Symbol('DELETE_DOSAGES_ID_SUCCESS'),
  DELETE_DOSAGES_ID_FAILED: Symbol('DELETE_DOSAGES_ID_FAILED'),
  DELETE_DOSAGES_ID_PENDING: Symbol('DELETE_DOSAGES_ID_PENDING'),

  GET_DOSAGES_SUCCESS: Symbol('GET_DOSAGES_SUCCESS'),
  GET_DOSAGES_FAILED: Symbol('GET_DOSAGES_FAILED'),
  GET_DOSAGES_PENDING: Symbol('GET_DOSAGES_PENDING'),
};
