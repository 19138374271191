import * as types from '../../../types/request';

const initialState = {
  getID: {
    value: {},
    status: null,
    error: null,
  },

  putID: {
    value: {},
    status: null,
    error: null,
  },

  deleteID: {
    status: null,
    error: null,
  },

  get: {
    value: [],
    total: 0,
    status: null,
    error: null,
  },

  post: {
    status: null,
    error: null,
  },

  delete: {
    status: null,
    error: null,
  },
};

export const customersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    // ----- GET_CUSTOMERS_ID_SUCCESS
    case types.adminTypes.customers.GET_CUSTOMERS_ID_SUCCESS:
      return {
        ...state,
        getID: { ...state.getID, status: 'success', value: action.payload },
      };
    case types.adminTypes.customers.GET_CUSTOMERS_ID_FAILED:
      return {
        ...state,
        getID: { ...state.getID, status: 'failed', error: action.payload },
      };
    case types.adminTypes.customers.GET_CUSTOMERS_ID_PENDING:
      return {
        ...state,
        getID: { ...state.getID, status: 'pending', error: null },
      };

    // ----- PUT_CUSTOMERS_ID_SUCCESS
    case types.adminTypes.customers.PUT_CUSTOMERS_ID_SUCCESS:
      return {
        ...state,
        putID: { ...state.putID, status: 'success', value: action.payload },
      };
    case types.adminTypes.customers.PUT_CUSTOMERS_ID_FAILED:
      return {
        ...state,
        putID: { ...state.putID, status: 'failed', error: action.payload },
      };
    case types.adminTypes.customers.PUT_CUSTOMERS_ID_PENDING:
      return {
        ...state,
        putID: { ...state.putID, status: 'pending', error: null },
      };

    // ----- DELETE_CUSTOMERS_ID_SUCCESS
    case types.adminTypes.customers.DELETE_CUSTOMERS_ID_SUCCESS:
      return { ...state, deleteID: { ...state.deleteID, status: 'success' } };
    case types.adminTypes.customers.DELETE_CUSTOMERS_ID_FAILED:
      return {
        ...state,
        deleteID: {
          ...state.deleteID,
          status: 'failed',
          error: action.payload,
        },
      };
    case types.adminTypes.customers.DELETE_CUSTOMERS_ID_PENDING:
      return {
        ...state,
        deleteID: { ...state.deleteID, status: 'pending', error: null },
      };

    // ----- GET_CUSTOMERS_SUCCESS
    case types.adminTypes.customers.GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          status: 'success',
          value: action.payload.customers,
          total: action.payload.total,
        },
      };
    case types.adminTypes.customers.GET_CUSTOMERS_FAILED:
      return {
        ...state,
        get: { ...state.get, status: 'failed', error: action.payload },
      };
    case types.adminTypes.customers.GET_CUSTOMERS_PENDING:
      return {
        ...state,
        get: { ...state.get, status: 'pending', error: null },
      };

    // ----- POST_CUSTOMERS_SUCCESS
    case types.adminTypes.customers.POST_CUSTOMERS_SUCCESS:
      return { ...state, post: { ...state.post, status: 'success' } };
    case types.adminTypes.customers.POST_CUSTOMERS_FAILED:
      return {
        ...state,
        post: { ...state.post, status: 'failed', error: action.payload },
      };
    case types.adminTypes.customers.POST_CUSTOMERS_PENDING:
      return {
        ...state,
        post: { ...state.post, status: 'pending', error: null },
      };

    // ----- DELETE_CUSTOMERS_SUCCESS
    case types.adminTypes.customers.DELETE_CUSTOMERS_SUCCESS:
      return { ...state, delete: { ...state.delete, status: 'success' } };
    case types.adminTypes.customers.DELETE_CUSTOMERS_FAILED:
      return {
        ...state,
        delete: { ...state.delete, status: 'failed', error: action.payload },
      };
    case types.adminTypes.customers.DELETE_CUSTOMERS_PENDING:
      return {
        ...state,
        delete: { ...state.delete, status: 'pending', error: null },
      };

    default: {
      return state;
    }
  }
};

export const customersState = {
  getID: {
    value: (state: any) => state.requestData.admin.customers.getID.value,
    status: (state: any) => state.requestData.admin.customers.getID.status,
    error: (state: any) => state.requestData.admin.customers.getID.error,
  },

  putID: {
    value: (state: any) => state.requestData.admin.customers.putID.value,
    status: (state: any) => state.requestData.admin.customers.putID.status,
    error: (state: any) => state.requestData.admin.customers.putID.error,
  },

  deleteID: {
    status: (state: any) => state.requestData.admin.customers.deleteID.status,
    error: (state: any) => state.requestData.admin.customers.deleteID.error,
  },

  get: {
    value: (state: any) => state.requestData.admin.customers.get.value,
    total: (state: any) => state.requestData.admin.customers.get.total,
    status: (state: any) => state.requestData.admin.customers.get.status,
    error: (state: any) => state.requestData.admin.customers.get.error,
  },

  post: {
    status: (state: any) => state.requestData.admin.customers.post.status,
    error: (state: any) => state.requestData.admin.customers.post.error,
  },

  delete: {
    status: (state: any) => state.requestData.admin.customers.delete.status,
    error: (state: any) => state.requestData.admin.customers.delete.error,
  },
};
