import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import * as actions from '../../store/actions';

import Header from '../../components/admin/Header';
import ExtractsDosage from '../../components/admin/ExtractsDosageEdit';

const ExtractDosageEdit: React.FC = (): JSX.Element => {
  const dispatch: any = useDispatch();
  const param: any = useParams();

  const getDosagesID = useCallback(
    (id) => dispatch(actions.request.adminActions.dosages.getDosagesID.get(id)),
    [dispatch],
  );

  useEffect(() => {
    param.id && getDosagesID(param.id);
  }, []);

  return (
    <>
      <Header />
      <ExtractsDosage id={param.id} />
    </>
  );
};

export default ExtractDosageEdit;
