import * as API from '../../../../../api';
import * as types from '../../../../types/request';

export const getForms = {
  get: function (search?: string) {
    return (dispatch: any) => {
      dispatch(this.getPending());

      return API.customersApi.formsApi
        .getForms(search)
        .then((response: any) => {
          if (response.status === 200) {
            return response
              .json()
              .then((json: any) => dispatch(this.getSuccess(json)));
          } else {
            return response
              .json()
              .then((json: any) => dispatch(this.getFailed(json)));
          }
        })
        .catch((response: any) => {
          return dispatch(this.getFailed(response));
        });
    };
  },

  getSuccess: (response: any) => ({
    type: types.customersTypes.forms.GET_FORMS_SUCCESS,
    payload: response,
  }),

  getFailed: (response: any) => ({
    type: types.customersTypes.forms.GET_FORMS_FAILED,
    payload: response,
  }),

  getPending: () => ({
    type: types.customersTypes.forms.GET_FORMS_PENDING,
  }),
};
