import React from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import * as actions from '../../../store/actions';

import Logo from '../../../images/header/logo-white.png';
import AdminLogo from '../../../images/header/admin-icon.svg';

import './Header.scss';

const Header: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();

  const getLogout = () => {
    dispatch(actions.request.authActions.logout.get());
  };

  return (
    <header className='header'>
      <div className='container header__container'>
        <NavLink to='/admin/buds' className='header__logo'>
          <img src={Logo} alt='CANNABIS APOTHEKE' width='149' height='50' />
        </NavLink>
        <div className='header__right'>
          <nav className='header__nav'>
            <NavLink
              to='/admin/buds'
              className='header__nav-link'
              activeClassName='header__nav-link--active'
            >
              Blüten
            </NavLink>
            <NavLink
              to='/admin/extracts'
              className='header__nav-link'
              activeClassName='header__nav-link--active'
            >
              Extrakte
            </NavLink>
            <NavLink
              to='/admin/customers'
              className='header__nav-link'
              activeClassName='header__nav-link--active'
            >
              Kunden
            </NavLink>
          </nav>
          <div className='header__user'>
            <div className='header__user-logo'>
              <img
                src={AdminLogo}
                className='header__user-image'
                alt='use role'
              />
            </div>
            <p className='header__user-role'>Admin</p>
          </div>
          <button className='header__logout' type='button' onClick={getLogout}>
            Abmelden
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
