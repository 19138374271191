import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as reducer from '../../../store/reducers';
import * as actions from '../../../store/actions';
import { getImageUrl } from '../../../utils/getImageUrl';

import './BudDetails.scss';

import EditIcon from '../../../images/symbols/edit.svg';
import CloseIcon from '../../../images/symbols/close.svg';
import BudsIcon from '../../../images/bud-icon.svg';
import Modal from '../Modal';

interface Props {
  isNewPage: boolean;
  id: number;
}

const BudDetails: React.FC<Props> = ({ isNewPage, id }): JSX.Element => {
  const dispatch: any = useDispatch();
  const history = useHistory();

  const [dataItem, setDataItem] = useState({
    companyName: '',
    name: '',
    country: '',
    kultivar: '',
    cbd: '0',
    thc: '0',
    genetik: 'na',
    companyImage: '',
    image: '',
    used: 0,
  });

  const [errors, setErrors] = useState({
    companyName: false,
    name: false,
    country: false,
    cbd: false,
    thc: false,
    companyImage: false,
  });

  const [selectedImg, setSelectedImg] = useState('');

  const [isImageOpen, setIsImageOpen] = useState({ name: '', isOpen: false });
  const [isGalleryOpen, setIsGalleryOpen] = useState<boolean>(false);

  const configGenetik = [
    {
      name: 'Indica',
      key: 'indica',
    },
    {
      name: 'Sativa',
      key: 'sativa',
    },
    {
      name: 'Hybrid',
      key: 'hybrid',
    },
    {
      name: 'Hybrid (indica-dominant)',
      key: 'hybrid_indica',
    },
    {
      name: 'Hybride (sativa-dominant)',
      key: 'hybrid_sativa',
    },
    {
      name: 'k.A.',
      key: 'na',
    },
  ];

  const getUploads = useCallback(
    () => dispatch(actions.request.uploadsActions.getUploads.get()),
    [dispatch],
  );
  const putBudsID = useCallback(
    (id, data) =>
      dispatch(actions.request.adminActions.buds.putBudsID.put(id, data)),
    [dispatch],
  );
  const postBuds = useCallback(
    (data) => dispatch(actions.request.adminActions.buds.postBuds.post(data)),
    [dispatch],
  );
  const postUploads = useCallback(
    (file) => dispatch(actions.request.uploadsActions.postUploads.post(file)),
    [dispatch],
  );

  const budsID = useSelector(
    reducer.request.adminReducers.budsState.getID.value,
  );
  const uploads = useSelector(reducer.request.uploadsState.getUploads.value);

  const onUploadImage = (event: any) => {
    const formData = new FormData();
    const { files } = event.target;

    formData.append('file', files[0]);

    postUploads(formData).then((response: any) => {
      if (response.type === 'POST_UPLOADS_SUCCESS') {
        setIsImageOpen({ name: '', isOpen: false });
        setDataItem({ ...dataItem, [isImageOpen.name]: response.payload });
      }
    });
  };

  const handleSubmit = () => {
    const inputErrors = {
      companyName: false,
      name: false,
      country: false,
      cbd: false,
      thc: false,
      companyImage: false,
    };

    if (dataItem.companyName.trim() === '') {
      inputErrors.companyName = true;
    }

    if (dataItem.name.trim() === '') {
      inputErrors.name = true;
    }

    if (dataItem.country.trim() === '') {
      inputErrors.country = true;
    }

    if (String(dataItem.cbd).trim() === '') {
      inputErrors.cbd = true;
    }

    if (String(dataItem.thc).trim() === '') {
      inputErrors.thc = true;
    }

    if (dataItem.companyImage.trim() === '') {
      inputErrors.companyImage = true;
    }

    if (Object.values(inputErrors).includes(true)) {
      setErrors(inputErrors);
    } else {
      const normalizedData = {
        ...dataItem,
        cbd: Number(dataItem.cbd),
        thc: Number(dataItem.thc),
      };

      id
        ? putBudsID(id, normalizedData).then(() => history.push('/admin/buds'))
        : postBuds(normalizedData).then(() => history.push('/admin/buds'));
    }
  };

  useEffect(() => {
    id && setDataItem(budsID);
  }, [budsID]);

  useEffect(() => {
    isGalleryOpen && getUploads();
  }, [isGalleryOpen]);

  return (
    <>
      <div className='bud'>
        <div className='container'>
          <h1 className='bud__title'>Blüten Infos</h1>
          <div className='bud__info'>
            <div className='bud__left'>
              <div className='bud__left-images'>
                <div
                  className={`bud__left-image ${
                    errors.companyImage && 'border-error'
                  }`}
                >
                  {dataItem.companyImage ? (
                    <>
                      <img src={getImageUrl(dataItem.companyImage)} alt='' />
                      <button
                        className='bud__left-image-edit'
                        type='button'
                        onClick={() => {
                          setIsImageOpen({
                            name: 'companyImage',
                            isOpen: true,
                          });
                        }}
                      >
                        <img src={EditIcon} alt='edit' />
                      </button>
                    </>
                  ) : (
                    <div
                      className='bud__left-image-wrapper'
                      onClick={() => {
                        setErrors({ ...errors, companyImage: false });
                        setIsImageOpen({ name: 'companyImage', isOpen: true });
                      }}
                    >
                      <p className='bud__left-image-title'>
                        Unternehmenslogo hochladen
                      </p>
                      <p className='bud__left-image-subtitle'>Größe 160x45px</p>
                      {errors.companyImage && (
                        <p className='field-error'>
                          Bitte, fügen Sie ein Bild hinzu
                        </p>
                      )}
                    </div>
                  )}
                </div>

                <div className='bud__left-image'>
                  {dataItem.image ? (
                    <>
                      <img src={getImageUrl(dataItem.image)} alt='' />
                      <button
                        className='bud__left-image-edit'
                        type='button'
                        onClick={() =>
                          setIsImageOpen({ name: 'image', isOpen: true })
                        }
                      >
                        <img src={EditIcon} alt='edit' />
                      </button>
                    </>
                  ) : (
                    <div
                      className='bud__left-image-wrapper'
                      onClick={() => {
                        setIsImageOpen({ name: 'image', isOpen: true });
                      }}
                    >
                      <p className='bud__left-image-title'>
                        Unternehmenslogo hochladen
                      </p>
                      <p className='bud__left-image-subtitle'>Größe 160x45px</p>
                    </div>
                  )}
                </div>
              </div>
              <form className='bud__form'>
                <div className='bud__form-inputs'>
                  <div className='bud__form-left'>
                    <div className='bud__form-field'>
                      <label htmlFor='company-name' className='bud__form-label'>
                        Unternehmensname
                      </label>
                      <input
                        type='text'
                        className={`bud__form-input ${
                          errors.companyName && 'border-error'
                        }`}
                        id='company-name'
                        value={dataItem.companyName}
                        onChange={(event) => {
                          setErrors({ ...errors, companyName: false });
                          setDataItem({
                            ...dataItem,
                            companyName: event.target.value,
                          });
                        }}
                      />
                      {errors.companyName && (
                        <p className='field-error'>
                          Der Unternehmensname darf nicht leer sein
                        </p>
                      )}
                    </div>

                    <div className='bud__form-field'>
                      <label htmlFor='name' className='bud__form-label'>
                        Name
                      </label>
                      <input
                        type='text'
                        className={`bud__form-input ${
                          errors.name && 'border-error'
                        }`}
                        id='name'
                        value={dataItem.name}
                        onChange={(event) => {
                          setErrors({ ...errors, name: false });
                          setDataItem({
                            ...dataItem,
                            name: event.target.value,
                          });
                        }}
                      />
                      {errors.name && (
                        <p className='field-error'>
                          Der Name darf nicht leer sein
                        </p>
                      )}
                    </div>

                    <div className='bud__form-field'>
                      <label htmlFor='country' className='bud__form-label'>
                        Land
                      </label>
                      <input
                        type='text'
                        className={`bud__form-input ${
                          errors.country && 'border-error'
                        }`}
                        id='country'
                        value={dataItem.country}
                        onChange={(event) => {
                          setErrors({ ...errors, country: false });
                          setDataItem({
                            ...dataItem,
                            country: event.target.value,
                          });
                        }}
                      />
                      {errors.country && (
                        <p className='field-error'>
                          Das Land darf nicht leer sein
                        </p>
                      )}
                    </div>
                  </div>

                  <div className='bud__form-right'>
                    <div className='bud__form-field'>
                      <label htmlFor='cultivar' className='bud__form-label'>
                        Kultivar
                      </label>
                      <input
                        type='text'
                        className='bud__form-input'
                        id='cultivar'
                        value={dataItem.kultivar}
                        onChange={(event) => {
                          setDataItem({
                            ...dataItem,
                            kultivar: event.target.value,
                          });
                        }}
                      />
                    </div>

                    <div className='bud__form-field'>
                      <label htmlFor='cbd' className='bud__form-label'>
                        CBD-Menge
                      </label>
                      <input
                        type='text'
                        className={`bud__form-input ${
                          errors.cbd && 'border-error'
                        }`}
                        id='cbd'
                        value={dataItem.cbd}
                        onChange={(event) => {
                          setErrors({ ...errors, cbd: false });

                          let { value } = event.target;
                          value = value.trim();
                          if (!Number.isNaN(+value))
                            setDataItem({ ...dataItem, cbd: value });
                        }}
                      />
                      {errors.cbd && (
                        <p className='field-error'>CBD darf nicht leer sein</p>
                      )}
                    </div>

                    <div className='bud__form-field'>
                      <label htmlFor='thc' className='bud__form-label'>
                        THC-Menge (%)
                      </label>
                      <input
                        type='text'
                        className={`bud__form-input ${
                          errors.thc && 'border-error'
                        }`}
                        id='thc'
                        value={dataItem.thc}
                        onChange={(event) => {
                          setErrors({ ...errors, thc: false });

                          let { value } = event.target;
                          value = value.trim();
                          if (!Number.isNaN(+value))
                            setDataItem({ ...dataItem, thc: value });
                        }}
                      />
                      {errors.thc && (
                        <p className='field-error'>THC darf nicht leer sein</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className='bud__form-radio'>
                  <p className='bud__form-radio-title'>Genetik</p>
                  <div className='bud__form-radio-items'>
                    {configGenetik.map((item: any) => (
                      <p className='bud__form-radio-item' key={item.key}>
                        <input
                          type='radio'
                          name='genetics'
                          id={item.key}
                          checked={dataItem.genetik === item.key}
                          onChange={() => {
                            setDataItem({ ...dataItem, genetik: item.key });
                          }}
                        />
                        <label
                          htmlFor={item.key}
                          className='bud__form-radio-label'
                        >
                          {item.name}
                        </label>
                      </p>
                    ))}
                  </div>
                </div>

                <div className='bud__form-buttons'>
                  <button
                    className='button bud__form-button bud__form-button--cancel'
                    type='button'
                    onClick={() => {
                      history.push('/admin/buds');
                    }}
                  >
                    Abbrechen
                  </button>
                  <button
                    className='button bud__form-button bud__form-button--save'
                    type='button'
                    onClick={handleSubmit}
                  >
                    Speichern
                  </button>
                </div>
              </form>
            </div>
            <div className='bud__right'>
              <h2 className='bud__right-title'>Vorschau</h2>
              <div className='bud__right-box'>
                <div className='bud__right-box-head'>
                  {dataItem.companyImage ? (
                    <img
                      src={getImageUrl(dataItem.companyImage)}
                      alt='logo'
                      className='bud__right-box-logo'
                    />
                  ) : (
                    <div className='bud__right-box-logo' />
                  )}
                  <div className='bud__right-box-description'>
                    <p className='bud__right-box-title'>
                      {dataItem.name || 'Name'}
                    </p>
                    <p className='bud__right-box-paragraph'>
                      {dataItem.companyName || 'Unternehmensname'}
                    </p>
                  </div>
                </div>
                <div className='bud__right-box-image'>
                  {dataItem.image ? (
                    <img src={getImageUrl(dataItem.image)} alt='image' />
                  ) : (
                    <img src={BudsIcon} alt='image' />
                  )}
                </div>
                <div className='bud__right-box-text'>
                  <p className='bud__right-box-text-title'>
                    {dataItem.kultivar || 'Kultivar'}
                  </p>
                  <div className='bud__right-box-text-paragraph'>
                    {configGenetik.find(
                      (genetik) => genetik.key === dataItem.genetik,
                    )?.name || 'Die Genetik der Sorte.'}
                  </div>
                </div>
              </div>
              {isNewPage && (
                <p className='bud__right-description'>
                  Customers use this Bud: <span>{dataItem.used} times</span>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      {isImageOpen.isOpen && (
        <Modal>
          <div className='bud__upload'>
            <h1 className='bud__upload-title'>Bild hochladen:</h1>
            <div className='bud__upload-buttons'>
              <button
                className='button button--dark bud__upload-button'
                type='button'
                onClick={() => {
                  setIsImageOpen({ ...isImageOpen, isOpen: false });
                  setIsGalleryOpen(true);
                }}
              >
                Von Galerie wählen
              </button>
              <input
                type='file'
                className='bud__upload-button bud__upload-button--input'
                onChange={onUploadImage}
              />
            </div>
            <button
              className='bud__upload-close'
              type='button'
              onClick={() => setIsImageOpen({ name: '', isOpen: false })}
            >
              <img src={CloseIcon} alt='close' />
            </button>
          </div>
        </Modal>
      )}

      {isGalleryOpen && (
        <Modal mode='gallery'>
          <div className='bud__gallery'>
            <h1 className='bud__gallery-title'>Bild von Galerie auswählen</h1>
            <div className='bud__gallery-list'>
              {uploads.map((img: string) => (
                <div
                  className={[
                    'bud__gallery-item',
                    selectedImg === img && 'selected',
                  ]
                    .filter(Boolean)
                    .join(' ')}
                  key={img}
                  onClick={() => setSelectedImg(img)}
                >
                  <img
                    src={getImageUrl(img)}
                    alt='gallery item'
                    className='bud__gallery-image'
                  />
                </div>
              ))}
            </div>
            <div className='bud__gallery-buttons'>
              <button
                className='bud__gallery-button button button--dark'
                type='button'
                onClick={() => {
                  setIsGalleryOpen(false);
                  setSelectedImg('');
                }}
              >
                Schließen
              </button>
              <button
                className='bud__gallery-button button button--green'
                type='button'
                onClick={() => {
                  setDataItem({ ...dataItem, [isImageOpen.name]: selectedImg });
                  setIsGalleryOpen(false);
                  setSelectedImg('');
                }}
              >
                Anwenden
              </button>
            </div>
            <button
              className='bud__gallery-close'
              type='button'
              onClick={() => {
                setIsGalleryOpen(false);
                setSelectedImg('');
              }}
            >
              <img src={CloseIcon} alt='close' />
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default BudDetails;
