import * as API from '../../../../../api';
import * as types from '../../../../types/request';

export const getExtractsAll = {
  get: function () {
    return (dispatch: any) => {
      dispatch(this.getPending());

      return API.adminApi.extractsApi
        .getExtractsAll()
        .then((response: any) => {
          if (response.status === 200) {
            return response
              .json()
              .then((json: any) => dispatch(this.getSuccess(json)));
          } else {
            return response
              .json()
              .then((json: any) => dispatch(this.getFailed(json)));
          }
        })
        .catch((response: any) => {
          return dispatch(this.getFailed(response));
        });
    };
  },

  getSuccess: (response: any) => ({
    type: types.adminTypes.extracts.GET_EXTRACTS_ALL_SUCCESS,
    payload: response,
  }),

  getFailed: (response: any) => ({
    type: types.adminTypes.extracts.GET_EXTRACTS_ALL_FAILED,
    payload: response,
  }),

  getPending: () => ({
    type: types.adminTypes.extracts.GET_EXTRACTS_ALL_PENDING,
  }),
};
