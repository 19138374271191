export const forms = {
  GET_FORMS_ALL_SUCCESS: 'GET_FORMS_ALL_SUCCESS',
  GET_FORMS_ALL_FAILED: 'GET_FORMS_ALL_FAILED',
  GET_FORMS_ALL_PENDING: 'GET_FORMS_ALL_PENDING',

  GET_FORMS_ID_SUCCESS: 'GET_FORMS_ID_SUCCESS',
  GET_FORMS_ID_FAILED: 'GET_FORMS_ID_FAILED',
  GET_FORMS_ID_PENDING: 'GET_FORMS_ID_PENDING',

  PUT_FORMS_ID_SUCCESS: 'PUT_FORMS_ID_SUCCESS',
  PUT_FORMS_ID_FAILED: 'PUT_FORMS_ID_FAILED',
  PUT_FORMS_ID_PENDING: 'PUT_FORMS_ID_PENDING',

  DELETE_FORMS_ID_SUCCESS: 'DELETE_FORMS_ID_SUCCESS',
  DELETE_FORMS_ID_FAILED: 'DELETE_FORMS_ID_FAILED',
  DELETE_FORMS_ID_PENDING: 'DELETE_FORMS_ID_PENDING',

  GET_FORMS_SUCCESS: 'GET_FORMS_SUCCESS',
  GET_FORMS_FAILED: 'GET_FORMS_FAILED',
  GET_FORMS_PENDING: 'GET_FORMS_PENDING',

  POST_FORMS_SUCCESS: 'POST_FORMS_SUCCESS',
  POST_FORMS_FAILED: 'POST_FORMS_FAILED',
  POST_FORMS_PENDING: 'POST_FORMS_PENDING',
};
