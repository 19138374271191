import * as types from '../../types/request';

const initialState = {
  login: {
    value: {},
    status: null,
    error: null,
  },

  refresh: {
    value: {},
    status: null,
    error: null,
  },

  logout: {
    status: null,
    error: null,
  },

  change: {
    status: null,
    error: null,
  },

  forgot: {
    status: null,
    error: null,
  },

  reset: {
    status: null,
    error: null,
  },
};

export const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    // ----- AUTH_LOGIN_SUCCESS
    case types.authTypes.AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        login: {
          ...state.login,
          value: action.payload,
          status: 'success',
          error: null,
        },
      };
    case types.authTypes.AUTH_LOGIN_FAILED:
      return {
        ...state,
        login: {
          ...state.login,
          status: 'failed',
          error: action.payload.message,
        },
      };
    case types.authTypes.AUTH_LOGIN_PENDING:
      return {
        ...state,
        login: { ...state.login, status: 'pending', error: null },
      };

    // ----- AUTH_REFRESH_SUCCESS
    case types.authTypes.AUTH_REFRESH_SUCCESS:
      return {
        ...state,
        refresh: {
          ...state.refresh,
          value: action.payload,
          status: 'success',
          error: null,
        },
        login: { ...state.login, value: action.payload, status: 'success' },
      };
    case types.authTypes.AUTH_REFRESH_FAILED:
      return {
        ...state,
        refresh: {
          ...state.refresh,
          status: 'failed',
          error: action.payload.message,
        },
      };
    case types.authTypes.AUTH_REFRESH_PENDING:
      return {
        ...state,
        refresh: { ...state.refresh, status: 'pending', error: null },
      };

    // ----- AUTH_LOGOUT_SUCCESS
    case types.authTypes.AUTH_LOGOUT_SUCCESS:
      return {
        ...state,
        logout: { ...state.logout, status: 'success', error: null },
      };
    case types.authTypes.AUTH_LOGOUT_FAILED:
      return {
        ...state,
        logout: {
          ...state.logout,
          status: 'failed',
          error: action.payload.message,
        },
      };
    case types.authTypes.AUTH_LOGOUT_PENDING:
      return {
        ...state,
        logout: { ...state.logout, status: 'pending', error: null },
      };

    // ----- AUTH_CHANGE_SUCCESS
    case types.authTypes.AUTH_CHANGE_SUCCESS:
      return {
        ...state,
        change: { ...state.change, status: 'success', error: null },
      };
    case types.authTypes.AUTH_CHANGE_FAILED:
      return {
        ...state,
        change: {
          ...state.change,
          status: 'failed',
          error: action.payload.message,
        },
      };
    case types.authTypes.AUTH_CHANGE_PENDING:
      return {
        ...state,
        change: { ...state.change, status: 'pending', error: null },
      };

    // ----- AUTH_FORGOT_SUCCESS
    case types.authTypes.AUTH_FORGOT_SUCCESS:
      return {
        ...state,
        forgot: { ...state.forgot, status: 'success', error: null },
      };
    case types.authTypes.AUTH_FORGOT_FAILED:
      return {
        ...state,
        forgot: {
          ...state.forgot,
          status: 'failed',
          error: action.payload.message,
        },
      };
    case types.authTypes.AUTH_FORGOT_PENDING:
      return {
        ...state,
        forgot: { ...state.forgot, status: 'pending', error: null },
      };

    // ----- AUTH_RESET_SUCCESS
    case types.authTypes.AUTH_RESET_SUCCESS:
      return {
        ...state,
        reset: { ...state.reset, status: 'success', error: null },
      };
    case types.authTypes.AUTH_RESET_FAILED:
      return {
        ...state,
        reset: {
          ...state.reset,
          status: 'failed',
          error: action.payload.message,
        },
      };
    case types.authTypes.AUTH_RESET_PENDING:
      return {
        ...state,
        reset: { ...state.reset, status: 'pending', error: null },
      };

    default: {
      return state;
    }
  }
};

export const authState = {
  login: {
    value: (state: any) => state.requestData.auth.login.value,
    status: (state: any) => state.requestData.auth.login.status,
    error: (state: any) => state.requestData.auth.login.error,
  },

  refresh: {
    value: (state: any) => state.requestData.auth.refresh.value,
    status: (state: any) => state.requestData.auth.refresh.status,
    error: (state: any) => state.requestData.auth.refresh.error,
  },

  logout: {
    status: (state: any) => state.requestData.auth.logout.status,
    error: (state: any) => state.requestData.auth.logout.error,
  },

  change: {
    status: (state: any) => state.requestData.auth.change.status,
    error: (state: any) => state.requestData.auth.change.error,
  },

  forgot: {
    status: (state: any) => state.requestData.auth.forgot.status,
    error: (state: any) => state.requestData.auth.forgot.error,
  },

  reset: {
    status: (state: any) => state.requestData.auth.reset.status,
    error: (state: any) => state.requestData.auth.reset.error,
  },
};
