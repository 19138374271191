import React from 'react';
import ReactDom from 'react-dom';

import './Modal.scss';

interface Props {
  children?: any;
  mode?: string;
}

const Modal: React.FC<Props> = ({ children, mode }): JSX.Element => {
  const className = mode
    ? `modal__content modal__content--${mode}`
    : 'modal__content';

  return ReactDom.createPortal(
    <div className='modal'>
      <div className='modal__overlay' />
      <div className={className}>{children}</div>
    </div>,
    document.body,
  );
};

export default Modal;
