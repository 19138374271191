import * as types from '../../types/request';

const initialState = {
  getUploads: {
    value: [],
    status: null,
    error: null,
  },

  getUploadsPath: {
    value: '',
    status: null,
    error: null,
  },

  postUploads: {
    value: '',
    status: null,
    error: null,
  },
};

export const uploadsReducers = (state = initialState, action: any) => {
  switch (action.type) {
    // ----- GET_UPLOADS -----
    case types.uploadsTypes.GET_UPLOADS_SUCCESS:
      return {
        ...state,
        getUploads: {
          ...state.getUploads,
          value: action.payload,
          status: 'success',
          error: null,
        },
      };
    case types.uploadsTypes.GET_UPLOADS_FAILED:
      return {
        ...state,
        getUploads: {
          ...state.getUploads,
          status: 'failed',
          error: action.payload.message,
        },
      };
    case types.uploadsTypes.GET_UPLOADS_PENDING:
      return {
        ...state,
        getUploads: { ...state.getUploads, status: 'pending', error: null },
      };

    // ----- GET_UPLOADS_PATH -----
    case types.uploadsTypes.GET_UPLOADS_PATH_SUCCESS:
      return {
        ...state,
        getUploadsPath: {
          ...state.getUploadsPath,
          value: action.payload,
          status: 'success',
        },
      };
    case types.uploadsTypes.GET_UPLOADS_PATH_FAILED:
      return {
        ...state,
        getUploadsPath: {
          ...state.getUploadsPath,
          status: 'failed',
          error: action.payload.message,
        },
      };
    case types.uploadsTypes.GET_UPLOADS_PATH_PENDING:
      return {
        ...state,
        getUploadsPath: {
          ...state.getUploadsPath,
          status: 'pending',
          error: null,
        },
      };

    // ----- POST_UPLOADS -----
    case types.uploadsTypes.POST_UPLOADS_SUCCESS:
      return {
        ...state,
        postUploads: {
          ...state.postUploads,
          value: action.payload,
          status: 'success',
          error: null,
        },
      };
    case types.uploadsTypes.POST_UPLOADS_FAILED:
      return {
        ...state,
        postUploads: {
          ...state.postUploads,
          status: 'failed',
          error: action.payload.message,
        },
      };
    case types.uploadsTypes.POST_UPLOADS_PENDING:
      return {
        ...state,
        postUploads: { ...state.postUploads, status: 'pending', error: null },
      };

    default: {
      return state;
    }
  }
};

export const uploadsState = {
  getUploads: {
    value: (state: any) => state.requestData.uploads.getUploads.value,
    status: (state: any) => state.requestData.uploads.getUploads.status,
    error: (state: any) => state.requestData.uploads.getUploads.error,
  },

  getUploadsPath: {
    value: (state: any) => state.requestData.uploads.getUploadsPath.value,
    status: (state: any) => state.requestData.uploads.getUploadsPath.status,
    error: (state: any) => state.requestData.uploads.getUploadsPath.error,
  },

  postUploads: {
    value: (state: any) => state.requestData.uploads.postUploads.value,
    status: (state: any) => state.requestData.uploads.postUploads.status,
    error: (state: any) => state.requestData.uploads.postUploads.error,
  },
};
