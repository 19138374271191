import React, { useCallback, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import * as actions from './store/actions';

import { PrivateRoute } from './HOC/PrivateRoute';

import AdminBuds from './pages/admin/Buds';
import AdminBud from './pages/admin/Bud';
import AdminBudsDosageEdit from './pages/admin/BudsDosageEdit';
import AdminBudsFormEdit from './pages/admin/BudsFormEdit';
import AdminCustomers from './pages/admin/Customers';
import AdminCustomerInfo from './pages/admin/CustomerInfo';
import AdminCustomerInfoEdit from './pages/admin/CustomerInfoEdit';
import AdminExtracts from './pages/admin/Extracts';
import AdminExtract from './pages/admin/Extract';
import AdminExtractsDosageEdit from './pages/admin/ExtractsDosageEdit';

import Login from './pages/Login';

import Dashboard from './pages/customer/Dashboard';
import CustomerBuds from './pages/customer/Buds';
import CustomerBud from './pages/customer/Bud';
import CustomerBudEdit from './pages/customer/BudEdit';
import CustomerExtracts from './pages/customer/Extracts';
import CustomerExtract from './pages/customer/Extract';
import CustomerExtractEdit from './pages/customer/ExtractEdit';
import CustomerSettings from './pages/customer/Settings';
import CustomerSettingsEdit from './pages/customer/SettingsEdit';
import CustomerReset from './pages/customer/Change';
import Forgot from './pages/Forgot';
import Reset from './pages/Reset';

const Router: React.FC = (): JSX.Element => {
  const dispatch: any = useDispatch();

  const getRefresh = useCallback(
    () => dispatch(actions.request.authActions.refresh.get()),
    [dispatch],
  );

  // run refresh token when open or update page
  useEffect(() => {
    if (localStorage.getItem('refreshToken')) getRefresh();
  }, []);

  useEffect(() => {
    setInterval(() => {
      if (localStorage.getItem('refreshToken')) getRefresh();
    }, 50000);
  }, []);

  return (
    <Switch>
      <PrivateRoute path='/admin' exact role='admin'>
        <Redirect to='/admin/buds' />
      </PrivateRoute>
      <PrivateRoute
        path='/admin/buds'
        component={AdminBuds}
        exact
        role='admin'
      />
      <PrivateRoute
        path='/admin/buds/new'
        component={AdminBud}
        exact
        role='admin'
      />
      <PrivateRoute
        path='/admin/buds/:id'
        component={AdminBud}
        exact
        role='admin'
      />

      <PrivateRoute
        path='/admin/buds/dosage/new'
        component={AdminBudsDosageEdit}
        exact
        role='admin'
      />
      <PrivateRoute
        path='/admin/buds/dosage/:id'
        component={AdminBudsDosageEdit}
        exact
        role='admin'
      />

      <PrivateRoute
        path='/admin/buds/form/new'
        component={AdminBudsFormEdit}
        exact
        role='admin'
      />

      <PrivateRoute
        path='/admin/buds/form/:id'
        component={AdminBudsFormEdit}
        exact
        role='admin'
      />

      <PrivateRoute
        path='/admin/extracts'
        component={AdminExtracts}
        exact
        role='admin'
      />
      <PrivateRoute
        path='/admin/extracts/new'
        component={AdminExtract}
        exact
        role='admin'
      />
      <PrivateRoute
        path='/admin/extracts/:id'
        component={AdminExtract}
        exact
        role='admin'
      />

      <PrivateRoute
        path='/admin/extracts/dosage/new'
        component={AdminExtractsDosageEdit}
        exact
        role='admin'
      />
      <PrivateRoute
        path='/admin/extracts/dosage/:id'
        component={AdminExtractsDosageEdit}
        exact
        role='admin'
      />

      <PrivateRoute
        path='/admin/customers'
        component={AdminCustomers}
        exact
        role='admin'
      />
      <PrivateRoute
        path='/admin/customers/new'
        component={AdminCustomerInfoEdit}
        exact
        role='admin'
      />

      <PrivateRoute
        path='/admin/customers/:id'
        component={AdminCustomerInfo}
        exact
        role='admin'
      />
      <PrivateRoute
        path='/admin/customers/:id/edit'
        component={AdminCustomerInfoEdit}
        exact
        role='admin'
      />

      <PrivateRoute
        path='/customer'
        component={Dashboard}
        exact
        role='customer'
      />
      <PrivateRoute
        path='/customer/buds'
        component={CustomerBuds}
        exact
        role='customer'
      />
      <PrivateRoute
        path='/customer/buds/:id'
        component={CustomerBud}
        exact
        role='customer'
      />
      <PrivateRoute
        path='/customer/buds/:id/edit'
        component={CustomerBudEdit}
        exact
        role='customer'
      />
      <PrivateRoute
        path='/customer/extracts'
        component={CustomerExtracts}
        exact
        role='customer'
      />
      <PrivateRoute
        path='/customer/extracts/:id'
        component={CustomerExtract}
        exact
        role='customer'
      />
      <PrivateRoute
        path='/customer/extracts/:id/edit'
        component={CustomerExtractEdit}
        exact
        role='customer'
      />
      <PrivateRoute
        path='/customer/settings'
        component={CustomerSettings}
        exact
        role='customer'
      />
      <PrivateRoute
        path='/customer/settings/edit'
        component={CustomerSettingsEdit}
        exact
        role='customer'
      />
      <PrivateRoute
        path='/customer/change'
        component={CustomerReset}
        exact
        role='customer'
      />

      <Route path='/' component={Login} exact />
      <Redirect from='/login' to='/' />
      <Route path='/forgot' component={Forgot} exact />
      <Route path='/reset' component={Reset} exact />
    </Switch>
  );
};

export default Router;
