import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../../store/actions';
import * as reducers from '../../../store/reducers';

import './CustomersTable.scss';

import DeleteIcon from '../../../images/symbols/delete.svg';
import CloseIcon from '../../../images/symbols/close.svg';
import Pagination from '../Pagination';
import Modal from '../Modal';

const CustomersTable: React.FC = (): JSX.Element => {
  const dispatch: any = useDispatch();
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);

  const [searchQuery, setSearchQuery] = useState('');

  const [removeCustomerPopupInfo, setRemoveCustomerPopupInfo] = useState({
    id: 0,
    item: '',
    isOpen: false,
  });
  const [removeCustomersPopupInfo, setRemoveCustomersPopupInfo] =
    useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState<number[]>([]);

  const getCustomers = useCallback(
    (start, limit, search) =>
      dispatch(
        actions.request.adminActions.customers.getCustomers.get(
          start,
          limit,
          search,
        ),
      ),
    [dispatch],
  );
  const deleteCustomersID = useCallback(
    (id) =>
      dispatch(
        actions.request.adminActions.customers.deleteCustomersID.delete(id),
      ),
    [dispatch],
  );
  const deleteCustomers = useCallback(
    (data) =>
      dispatch(
        actions.request.adminActions.customers.deleteCustomers.delete(data),
      ),
    [dispatch],
  );

  const customers = useSelector(
    reducers.request.adminReducers.customersState.get.value,
  );
  const customersTotal = useSelector(
    reducers.request.adminReducers.customersState.get.total,
  );

  useEffect(() => {
    getCustomers(start, limit, searchQuery);
  }, [start, limit, searchQuery]);

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setStart(currentPage * limit - limit);
  }, [currentPage]);

  const handleSelect = (event: any) => {
    setLimit(event.target.value);
  };

  const handleInput = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const onToggleSelectedCustomers = (id: number) => {
    if (selectedCustomers.includes(id)) {
      setSelectedCustomers(selectedCustomers.filter((d: number) => d !== id));
    } else {
      setSelectedCustomers([id, ...selectedCustomers]);
    }
  };

  const redirect = (event: any) => {
    if (event.target.tagName === 'TD') {
      const id = event.target.closest('tr').dataset.url;
      const path = `/admin/customers/${id}`;

      history.push(path, { id });
    }
  };

  return (
    <div className='customers__table-wrapper'>
      <div className='customers__table-filter'>
        <div className='customers__table-select-wrapper'>
          <label
            htmlFor='customers-number'
            className='customers__table-select-label'
          >
            Einträge anzeigen:
          </label>
          <select
            name='customers-number'
            id='customers-number'
            className='customers__table-select'
            value={limit}
            onChange={handleSelect}
          >
            <option value='5'>5</option>
            <option value='10'>10</option>
            <option value='15'>15</option>
            <option value='20'>20</option>
          </select>
        </div>
        <form action='#' className='customers__table-search'>
          <div className='customers__table-field'>
            <input
              type='text'
              placeholder='Suchen'
              className='customers__table-input'
              value={searchQuery}
              onChange={handleInput}
            />
          </div>
        </form>
      </div>

      <table className='customers__table'>
        <thead>
          <tr>
            <th />
            <th className='customers__table-title'>Unternehmen</th>
            <th className='customers__table-title'>Email</th>
            <th className='customers__table-title'>Status</th>
            <th />
          </tr>
        </thead>

        <tbody>
          {customers.map((customer: any, index: number) => (
            <tr
              key={customer.id}
              className='customers__table-row'
              onClick={redirect}
              data-url={customer.id}
            >
              <td className='customers__table-row-index'>
                <input
                  checked={selectedCustomers.includes(customer.id)}
                  type='checkbox'
                  className='customers__table-row-checkbox'
                  onChange={() => onToggleSelectedCustomers(customer.id)}
                />
                {index + 1 + start}.
              </td>
              <td className='customers__table-row-item'>{customer.company}</td>
              <td className='customers__table-row-item'>{customer.email}</td>
              <td
                className={
                  customer.active
                    ? 'customers__table-row-item customers__table-row-item--active'
                    : 'customers__table-row-item customers__table-row-item--inactive'
                }
              >
                {customer.active ? 'Aktiv' : 'Inaktiv'}
              </td>
              <td className='customers__table-row-button'>
                <button
                  className='button'
                  onClick={() => {
                    setRemoveCustomerPopupInfo({
                      id: customer.id,
                      item: customer.name,
                      isOpen: true,
                    });
                  }}
                >
                  <img src={DeleteIcon} alt='delete' />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Pagination
        itemsPerPage={limit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalItems={customersTotal}
        paginate={paginate}
      />

      {!!selectedCustomers.length && (
        <div className='customers__table-buttons'>
          <button
            className='button button--dark customers__table-button'
            onClick={() => setRemoveCustomersPopupInfo(true)}
          >
            Ausgewählte löschen {selectedCustomers.length}
          </button>
        </div>
      )}

      {removeCustomerPopupInfo.isOpen && (
        <Modal>
          <div className='customers__table-modal'>
            <button
              className='extracts__table-modal-close'
              onClick={() =>
                setRemoveCustomerPopupInfo({ id: 0, item: '', isOpen: false })
              }
            >
              <img src={CloseIcon} alt='close' />
            </button>
            <p className='customers__table-modal-title'>Sorte löschen?</p>
            <p className='customers__table-modal-paragraph'>
              Sie haben vor, den Bud "{removeCustomerPopupInfo.item}" zu
              löschen.
            </p>
            <p className='customers__table-modal-paragraph'>
              Diese Änderungen kann nicht rückgängig gemacht werden.
            </p>
            <div className='customers__table-modal-buttons'>
              <button
                className='button customers__table-modal-button customers__table-modal-button--delete'
                onClick={() =>
                  deleteCustomersID(removeCustomerPopupInfo.id).then(() => {
                    setRemoveCustomerPopupInfo({
                      id: 0,
                      item: '',
                      isOpen: false,
                    });
                    getCustomers(start, limit, searchQuery);
                  })
                }
              >
                Löschen
              </button>
              <button
                className='button customers__table-modal-button customers__table-modal-button--cancel'
                onClick={() =>
                  setRemoveCustomerPopupInfo({ id: 0, item: '', isOpen: false })
                }
              >
                Abbrechen
              </button>
            </div>
          </div>
        </Modal>
      )}

      {removeCustomersPopupInfo && (
        <Modal>
          <div className='customers__table-modal'>
            <button
              className='customers__table-modal-close'
              onClick={() => setRemoveCustomersPopupInfo(false)}
            >
              <img src={CloseIcon} alt='close' />
            </button>
            <p className='customers__table-modal-title'>
              {selectedCustomers.length} Blüten löschen?
            </p>
            <p className='customers__table-modal-paragraph'>
              Sie haben vor, {selectedCustomers.length} ausgewählte Blüten zu
              löschen.
            </p>
            <p className='customers__table-modal-paragraph'>
              Nach dem Entfernen können Sie diese Änderungen nicht mehr
              rückgängig machen.
            </p>
            <div className='customers__table-modal-buttons'>
              <button
                className='button customers__table-modal-button customers__table-modal-button--delete'
                onClick={() =>
                  deleteCustomers({ customersIds: selectedCustomers }).then(
                    () => {
                      setRemoveCustomersPopupInfo(false);
                      setSelectedCustomers([]);
                      getCustomers(start, limit, searchQuery);
                    },
                  )
                }
              >
                Löschen
              </button>
              <button
                className='button customers__table-modal-button customers__table-modal-button--cancel'
                onClick={() => setRemoveCustomersPopupInfo(false)}
              >
                Abbrechen
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default CustomersTable;
