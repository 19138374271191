import * as types from '../../../types/request';

const initialState: any = {
  getAll: {
    value: [],
    status: null,
    error: null,
  },

  getID: {
    value: {},
    status: null,
    error: null,
  },

  putID: {
    value: {},
    status: null,
    error: null,
  },

  deleteID: {
    status: null,
    error: null,
  },

  get: {
    value: [],
    total: 0,
    status: null,
    error: null,
  },

  post: {
    status: null,
    error: null,
  },
};

export const formReducer = (state = initialState, action: any) => {
  switch (action.type) {
    // ----- GET_FORMS_ALL_SUCCESS
    case types.adminTypes.forms.GET_FORMS_ALL_SUCCESS:
      return {
        ...state,
        getAll: { ...state.getAll, status: 'success', value: action.payload },
      };
    case types.adminTypes.forms.GET_FORMS_ALL_FAILED:
      return {
        ...state,
        getAll: { ...state.getAll, status: 'failed', error: action.payload },
      };
    case types.adminTypes.forms.GET_FORMS_ALL_PENDING:
      return {
        ...state,
        getAll: { ...state.getAll, status: 'pending', error: null },
      };

    // ----- GET_FORMS_ID_SUCCESS
    case types.adminTypes.forms.GET_FORMS_ID_SUCCESS:
      return {
        ...state,
        getID: { ...state.getID, status: 'success', value: action.payload },
      };
    case types.adminTypes.forms.GET_FORMS_ID_FAILED:
      return {
        ...state,
        getID: { ...state.getID, status: 'failed', error: action.payload },
      };
    case types.adminTypes.forms.GET_FORMS_ID_PENDING:
      return {
        ...state,
        getID: { ...state.getID, status: 'pending', error: null },
      };

    // ----- PUT_FORMS_ID_SUCCESS
    case types.adminTypes.forms.PUT_FORMS_ID_SUCCESS:
      return {
        ...state,
        putID: { ...state.putID, status: 'success', value: action.payload },
      };
    case types.adminTypes.forms.PUT_FORMS_ID_FAILED:
      return {
        ...state,
        putID: { ...state.putID, status: 'failed', error: action.payload },
      };
    case types.adminTypes.forms.PUT_FORMS_ID_PENDING:
      return {
        ...state,
        putID: { ...state.putID, status: 'pending', error: null },
      };

    // ----- DELETE_FORMS_ID_SUCCESS
    case types.adminTypes.forms.DELETE_FORMS_ID_SUCCESS:
      return { ...state, deleteID: { ...state.deleteID, status: 'success' } };
    case types.adminTypes.forms.DELETE_FORMS_ID_FAILED:
      return {
        ...state,
        deleteID: {
          ...state.deleteID,
          status: 'failed',
          error: action.payload,
        },
      };
    case types.adminTypes.forms.DELETE_FORMS_ID_PENDING:
      return {
        ...state,
        deleteID: { ...state.deleteID, status: 'pending', error: null },
      };

    // ----- GET_FORMS_SUCCESS
    case types.adminTypes.forms.GET_FORMS_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          status: 'success',
          value: action.payload.forms,
          total: action.payload.total,
        },
      };
    case types.adminTypes.forms.GET_FORMS_FAILED:
      return {
        ...state,
        get: { ...state.get, status: 'failed', error: action.payload },
      };
    case types.adminTypes.forms.GET_FORMS_PENDING:
      return {
        ...state,
        get: { ...state.get, status: 'pending', error: null },
      };

    // ----- POST_FORMS_SUCCESS
    case types.adminTypes.forms.POST_FORMS_SUCCESS:
      return { ...state, post: { ...state.post, status: 'success' } };
    case types.adminTypes.forms.POST_FORMS_FAILED:
      return {
        ...state,
        post: { ...state.post, status: 'failed', error: action.payload },
      };
    case types.adminTypes.forms.POST_FORMS_PENDING:
      return {
        ...state,
        post: { ...state.post, status: 'pending', error: null },
      };

    default: {
      return state;
    }
  }
};

export const formsState = {
  getAll: {
    value: (state: any) => state.requestData.admin.forms.getAll.value,
    status: (state: any) => state.requestData.admin.forms.getAll.status,
    error: (state: any) => state.requestData.admin.forms.getAll.error,
  },

  getID: {
    value: (state: any) => state.requestData.admin.forms.getID.value,
    status: (state: any) => state.requestData.admin.forms.getID.status,
    error: (state: any) => state.requestData.admin.forms.getID.error,
  },

  putID: {
    value: (state: any) => state.requestData.admin.forms.putID.value,
    status: (state: any) => state.requestData.admin.forms.putID.status,
    error: (state: any) => state.requestData.admin.forms.putID.error,
  },

  deleteID: {
    status: (state: any) => state.requestData.admin.forms.deleteID.status,
    error: (state: any) => state.requestData.admin.forms.deleteID.error,
  },

  get: {
    value: (state: any) => state.requestData.admin.forms.get.value,
    total: (state: any) => state.requestData.admin.forms.get.total,
    status: (state: any) => state.requestData.admin.forms.get.status,
    error: (state: any) => state.requestData.admin.forms.get.error,
  },

  post: {
    status: (state: any) => state.requestData.admin.forms.post.status,
    error: (state: any) => state.requestData.admin.forms.post.error,
  },
};
