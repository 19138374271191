import React from 'react';
import Header from '../../components/customer/Header';
import { default as SettingsComponent } from '../../components/customer/Settings';

const Settings: React.FC = (): JSX.Element => {
  return (
    <>
      <Header />
      <SettingsComponent />
    </>
  );
};

export default Settings;
