import React, { useEffect, useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import * as actions from '../../store/actions';

import BudDetails from '../../components/admin/BudDetails';
import Header from '../../components/admin/Header';

const Bud: React.FC = (): JSX.Element => {
  const location: any = useLocation();
  const params: any = useParams();
  const dispatch: any = useDispatch();

  const getBudsID = useCallback(
    (id) => dispatch(actions.request.adminActions.buds.getBudsID.get(id)),
    [dispatch],
  );

  let isNewPage = true;

  if (location.state) {
    isNewPage = false;
  }

  useEffect(() => {
    params.id && getBudsID(params.id);
  }, []);

  return (
    <>
      <Header />
      <BudDetails isNewPage={isNewPage} id={params.id} />
    </>
  );
};

export default Bud;
