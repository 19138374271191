import React from 'react';
import { useParams } from 'react-router-dom';

import ExtractDetails from '../../components/customer/ExtractDetails';
import Header from '../../components/customer/Header';

const Extract: React.FC = (): JSX.Element => {
  const { id } = useParams<any>();

  return (
    <>
      <Header />
      <ExtractDetails id={Number(id)} />
    </>
  );
};

export default Extract;
