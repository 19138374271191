import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './Charts.scss';

import * as actions from '../../../store/actions';
import * as reducer from '../../../store/reducers';

import * as config from './chartConfig';
import LineChart from './lineChart';
import PieChart from './pieChart';

const Charts: React.FC = (): JSX.Element => {
  const dispatch: any = useDispatch();

  const prescriptionsByAmount = useSelector(
    reducer.request.customersReducers.statisticsState.getPrescriptions.amount
      .data,
  );
  const prescriptionsByOutput = useSelector(
    reducer.request.customersReducers.statisticsState.getPrescriptions.output
      .data,
  );
  const productsAndCount = useSelector(
    reducer.request.customersReducers.statisticsState.getProducts.count.data,
  );
  const productsAndAmount = useSelector(
    reducer.request.customersReducers.statisticsState.getProducts.amount.data,
  );

  const getPrescriptionsByAmount = (interval: string) =>
    dispatch(
      actions.request.customersActions.statistics.getPrescriptionsByAmount.get(
        interval,
      ),
    );
  const getPrescriptionsByOutput = (interval: string) =>
    dispatch(
      actions.request.customersActions.statistics.getPrescriptionsByOutput.get(
        interval,
      ),
    );
  const getProductsAndCount = (interval: string, type: string) =>
    dispatch(
      actions.request.customersActions.statistics.getProductsAndCount.get(
        interval,
        type,
      ),
    );
  const getProductsAndAmount = (interval: string, type: string) =>
    dispatch(
      actions.request.customersActions.statistics.getProductsAndAmount.get(
        interval,
        type,
      ),
    );

  return (
    <div className='charts'>
      <h2 className='charts__title'>Auswertungen</h2>

      <div className='charts__list'>
        <LineChart
          title='Anzahl der Verordnungen in den letzten'
          configData={config.configPrescriptionsByOutput}
          requestData={prescriptionsByOutput}
          options={config.chartConfig.optionsLineChart}
          settingsCheckbox={config.chartConfig.lineChartOutput}
          getData={getPrescriptionsByOutput}
        />

        <LineChart
          title='Gesamtmenge verschrieben'
          configData={config.configPrescriptionsByAmount}
          requestData={prescriptionsByAmount}
          options={config.chartConfig.optionsLineChart}
          settingsCheckbox={config.chartConfig.lineChartAmount}
          getData={getPrescriptionsByAmount}
        />

        <PieChart
          title='Häufigste Verordnungen'
          configData={config.configProductsAndCount}
          requestData={productsAndCount}
          options={config.chartConfig.optionsPieChart}
          settingsRadio={config.chartConfig.lineChartAmount}
          getData={getProductsAndCount}
        />

        <PieChart
          title='Verordnete Menge per Produkt'
          configData={config.configProductsAndAmount}
          requestData={productsAndAmount}
          options={config.chartConfig.optionsPieChart}
          settingsRadio={config.chartConfig.lineChartAmount}
          getData={getProductsAndAmount}
        />
      </div>
    </div>
  );
};

export default Charts;
