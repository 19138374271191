import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../../store/actions';
import * as reducers from '../../../store/reducers';

import './ExtractsDosages.scss';

import DeleteIcon from '../../../images/symbols/delete.svg';
import CloseIcon from '../../../images/symbols/close.svg';
import Pagination from '../Pagination';
import Modal from '../Modal';

const ExtractsDosages: React.FC = (): JSX.Element => {
  const dispatch: any = useDispatch();
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(9);

  const [searchQuery, setSearchQuery] = useState('');

  const [removeDosagePopupInfo, setRemoveDosagePopupInfo] = useState({
    id: 0,
    item: '',
    isOpen: false,
  });

  const getDosages = useCallback(
    (start, limit, search) =>
      dispatch(
        actions.request.adminActions.dosages.getDosages.get(
          start,
          limit,
          'extract',
          search,
        ),
      ),
    [dispatch],
  );
  const deleteDosagesID = useCallback(
    (id) =>
      dispatch(actions.request.adminActions.dosages.deleteDosagesID.delete(id)),
    [dispatch],
  );

  const dosages = useSelector(
    reducers.request.adminReducers.dosagesState.get.value,
  );
  const dosagesTotal = useSelector(
    reducers.request.adminReducers.dosagesState.get.total,
  );

  useEffect(() => {
    getDosages(start, limit, searchQuery);
  }, [start, limit, searchQuery]);

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setStart(currentPage * limit - limit);
  }, [currentPage]);

  const handleSelect = (event: any) => {
    setLimit(event.target.value);
  };

  const handleInput = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const redirect = (event: any) => {
    if (event.target.tagName !== 'IMG') {
      const path = `/admin/extracts/dosage/${
        event.target.closest('div').dataset.url
      }`;
      history.push(path);
    }
  };

  return (
    <>
      <section className='extracts__dosage'>
        <div className='extracts__dosage-filter'>
          <div className='extracts__dosage-select-wrapper'>
            <label
              htmlFor='extracts-number'
              className='extracts__dosage-select-label'
            >
              Einträge anzeigen:
            </label>
            <select
              name='extracts-number'
              id='extracts-number'
              className='extracts__dosage-select'
              value={limit}
              onChange={handleSelect}
            >
              <option value='6'>6</option>
              <option value='9'>9</option>
              <option value='12'>12</option>
              <option value='15'>15</option>
            </select>
          </div>
          <form action='#' className='extracts__dosage-search'>
            <div className='extracts__dosage-field'>
              <input
                type='text'
                placeholder='Suchen'
                className='extracts__dosage-input'
                value={searchQuery}
                onChange={handleInput}
              />
            </div>
          </form>
        </div>
        {dosages.map((dosage: any) => (
          <div
            className='extracts__dosage-item'
            key={dosage.id}
            onClick={redirect}
            data-url={dosage.id}
          >
            <button
              className='button extracts__dosage-item-delete'
              onClick={() =>
                setRemoveDosagePopupInfo({
                  id: dosage.id,
                  item: '',
                  isOpen: true,
                })
              }
            >
              <img src={DeleteIcon} alt='delete item' />
            </button>
            <p className='extracts__dosage-item-title'>{dosage.name}</p>
            <p className='extracts__dosage-item-description'>
              {dosage.dosageFields
                .map((field: any) =>
                  [field.prefix, field.value, field.sufix].join(''),
                )
                .join('')}
            </p>
          </div>
        ))}
      </section>

      <Pagination
        itemsPerPage={limit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalItems={dosagesTotal}
        paginate={paginate}
      />

      {removeDosagePopupInfo.isOpen && (
        <Modal>
          <div className='extracts__table-modal'>
            <button
              className='extracts__table-modal-close'
              onClick={() =>
                setRemoveDosagePopupInfo({ id: 0, item: '', isOpen: false })
              }
            >
              <img src={CloseIcon} alt='close' />
            </button>
            <p className='extracts__table-modal-title'>
              Anleitung löschen?
            </p>
            <p className='extracts__table-modal-paragraph'>
              Sie haben vor, die Dosieranleitung "Dosiername" zu löschen.
            </p>
            <p className='extracts__table-modal-paragraph'>
              Diese Änderungen kann nicht rückgängig gemacht werden.
            </p>
            <div className='extracts__table-modal-buttons'>
              <button
                className='button extracts__table-modal-button extracts__table-modal-button--delete'
                onClick={() =>
                  deleteDosagesID(removeDosagePopupInfo.id).then(() => {
                    setRemoveDosagePopupInfo({
                      id: 0,
                      item: '',
                      isOpen: false,
                    });
                    getDosages(start, limit, searchQuery);
                  })
                }
              >
                Löschen
              </button>
              <button
                className='button extracts__table-modal-button extracts__table-modal-button--cancel'
                onClick={() =>
                  setRemoveDosagePopupInfo({ id: 0, item: '', isOpen: false })
                }
              >
                Abbrechen
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ExtractsDosages;
