import React from 'react';
import ReactDOM from 'react-dom';
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import Router from './Router';
import { combineReducer } from './store/combineReducers';

import { Chart } from 'chart.js';
import { LabelsPlugin } from './chart/labels-plugin';

import 'normalize.css';
import './styles/global.scss';

const store = createStore(
  combineReducer,
  composeWithDevTools(applyMiddleware(thunk)),
);

Chart.register(new LabelsPlugin());

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);
