export const chartConfig = {
  lineChartOutput: [
    {
      name: 'print',
      title: 'Ausdrücke',
      color: '#0eb487',
    },
    {
      name: 'copy',
      title: 'kopierte Angaben',
      color: '#ffbf4f',
    },
    /*
    {
      name: 'erixa',
      title: 'eRiXa',
      color: '#49424a',
    },
    */
  ],

  lineChartAmount: [
    {
      name: 'bud',
      title: 'Blüten',
      color: '#0eb487',
    },
    {
      name: 'extract',
      title: 'Extrakte',
      color: '#ffbf4f',
    },
  ],

  optionsLineChart: {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
    },
  },

  optionsPieChart: {
    maintainAspectRatio: false,
    layout: {
      padding: 40,
    },
    plugins: {
      legend: {
        display: false,
      },
      labels: {
        render: 'label',
        fontColor: '#000',
        position: 'outside',
        segment: true,
      },
    },
  },
};

export const configPrescriptionsByOutput = {
  labels: [],
  datasets: [
    {
      label: '',
      data: [],
      fill: true,
      borderColor: '#ffbf4f',
      pointRadius: 3,
      pointBackgroundColor: '#ffbf4f',
      pointStyle: 'rectRot',
      backgroundColor: 'rgba(255, 191, 79, 0.1)',
      tension: 0.3,
    },

    {
      label: '',
      data: [],
      fill: true,
      borderColor: '#49424a',
      pointRadius: 3,
      pointBackgroundColor: '#49424a',
      backgroundColor: 'rgba(73, 66, 74, 0.1)',
      tension: 0.3,
    },

    {
      label: '',
      data: [],
      fill: true,
      borderColor: '#0eb487',
      pointRadius: 3,
      pointBackgroundColor: '#0eb487',
      backgroundColor: 'rgba(14, 180, 135, 0.1)',
      tension: 0.3,
    },
  ],
};

export const configPrescriptionsByAmount = {
  labels: [],
  datasets: [
    {
      label: '',
      data: [],
      fill: true,
      borderColor: '#0eb487',
      pointRadius: 3,
      pointBackgroundColor: '#0eb487',
      backgroundColor: 'rgba(14, 180, 135, 0.1)',
      tension: 0.3,
    },
    {
      label: '',
      data: [],
      fill: true,
      borderColor: '#ffbf4f',
      pointRadius: 3,
      pointBackgroundColor: '#ffbf4f',
      backgroundColor: 'rgba(255, 191, 79, 0.1)',
      tension: 0.3,
    },
  ],
};

export const configProductsAndCount = {
  labels: [],
  datasets: [
    {
      label: '',
      data: [],
      backgroundColor: [
        'rgba(213, 0, 182, 1)',
        'rgba(41, 108, 205, 1)',
        'rgba(0, 222, 167, 1)',
        'rgba(0, 221, 116, 1)',
        'rgba(0, 182, 206, 1)',
      ],
      borderColor: [
        'rgba(255, 255, 255, 1)',
        'rgba(255, 255, 255, 1)',
        'rgba(255, 255, 255, 1)',
        'rgba(255, 255, 255, 1)',
        'rgba(255, 255, 255, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

export const configProductsAndAmount = {
  labels: [],
  datasets: [
    {
      label: '',
      data: [],
      backgroundColor: [
        'rgba(60, 229, 219, 1)',
        'rgba(184, 34, 143, 1)',
        'rgba(225, 25, 122, 1)',
        'rgba(0, 179, 112, 1)',
        'rgba(213, 127, 61, 1)',
      ],
      borderColor: [
        'rgba(255, 255, 255, 1)',
        'rgba(255, 255, 255, 1)',
        'rgba(255, 255, 255, 1)',
        'rgba(255, 255, 255, 1)',
        'rgba(255, 255, 255, 1)',
      ],
      borderWidth: 1,
    },
  ],
};
