import React from 'react';
import { NavLink } from 'react-router-dom';

import CannabisLogo from '../../../images/header/logo-white.png';

import './style.scss';

interface Props {
  page?: string;
}

const Header: React.FC<Props> = ({ page }): JSX.Element => {
  return (
    <header className='login-header'>
      <div className='container'>
        <NavLink to='/' className='logo-link'>
          <img src={CannabisLogo} alt='Cannabis apotheke logo' />
        </NavLink>
      </div>
    </header>
  );
};

export default Header;
