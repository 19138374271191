import * as types from '../../../types/request';

const initialState = {
  getInfo: {
    value: {},
    status: null,
    error: null,
  },

  put: {
    value: {},
    status: null,
    error: null,
  },

  get: {
    value: {},
    status: null,
    error: null,
  },

  putDescriptions: {
    status: null,
    error: null,
  },

  putSettings: {
    status: null,
    error: null,
  },

  postMail: {
    status: null,
    error: null,
  },
};

export const customersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    // ----- GET_CUSTOMERS_INFO_SUCCESS
    case types.customersTypes.customers.GET_CUSTOMERS_INFO_SUCCESS:
      return {
        ...state,
        getInfo: { ...state.getInfo, status: 'success', value: action.payload },
      };
    case types.customersTypes.customers.GET_CUSTOMERS_INFO_FAILED:
      return {
        ...state,
        getInfo: { ...state.getInfo, status: 'failed', error: action.payload },
      };
    case types.customersTypes.customers.GET_CUSTOMERS_INFO_PENDING:
      return {
        ...state,
        getInfo: { ...state.getInfo, status: 'pending', error: null },
      };

    // ----- PUT_CUSTOMERS_SUCCESS
    case types.customersTypes.customers.PUT_CUSTOMERS_SUCCESS:
      return {
        ...state,
        put: { ...state.put, status: 'success', value: action.payload },
      };
    case types.customersTypes.customers.PUT_CUSTOMERS_FAILED:
      return {
        ...state,
        put: { ...state.put, status: 'failed', error: action.payload },
      };
    case types.customersTypes.customers.PUT_CUSTOMERS_PENDING:
      return {
        ...state,
        put: { ...state.put, status: 'pending', error: null },
      };

    // ----- PUT_CUSTOMERS_DESCRIPTIONS_SUCCESS
    case types.customersTypes.customers.PUT_CUSTOMERS_DESCRIPTIONS_SUCCESS:
      return {
        ...state,
        putDescriptions: { ...state.putDescriptions, status: 'success' },
      };
    case types.customersTypes.customers.PUT_CUSTOMERS_DESCRIPTIONS_FAILED:
      return {
        ...state,
        putDescriptions: {
          ...state.putDescriptions,
          status: 'failed',
          error: action.payload,
        },
      };
    case types.customersTypes.customers.PUT_CUSTOMERS_DESCRIPTIONS_PENDING:
      return {
        ...state,
        putDescriptions: {
          ...state.putDescriptions,
          status: 'pending',
          error: null,
        },
      };

    // ----- PUT_CUSTOMERS_SETTINGS_SUCCESS
    case types.customersTypes.customers.PUT_CUSTOMERS_SETTINGS_SUCCESS:
      return {
        ...state,
        putSettings: {
          ...state.putSettings,
          status: 'success',
        },
      };
    case types.customersTypes.customers.PUT_CUSTOMERS_SETTINGS_FAILED:
      return {
        ...state,
        putSettings: {
          ...state.putSettings,
          status: 'failed',
          error: action.payload,
        },
      };
    case types.customersTypes.customers.PUT_CUSTOMERS_SETTINGS_PENDING:
      return {
        ...state,
        putSettings: {
          ...state.putSettings,
          status: 'pending',
          error: null,
        },
      };

    // ----- GET_CUSTOMERS_SUCCESS
    case types.customersTypes.customers.GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        get: { ...state.get, status: 'success', value: action.payload },
      };
    case types.customersTypes.customers.GET_CUSTOMERS_FAILED:
      return {
        ...state,
        get: { ...state.get, status: 'failed', error: action.payload },
      };
    case types.customersTypes.customers.GET_CUSTOMERS_PENDING:
      return {
        ...state,
        get: { ...state.get, status: 'pending', error: null },
      };

    // ----- POST_CUSTOMERS_MAIL_SUCCESS
    case types.customersTypes.customers.POST_CUSTOMERS_MAIL_SUCCESS:
      return { ...state, post: { ...state.postMail, status: 'success' } };
    case types.customersTypes.customers.POST_CUSTOMERS_MAIL_FAILED:
      return {
        ...state,
        post: { ...state.postMail, status: 'failed', error: action.payload },
      };
    case types.customersTypes.customers.POST_CUSTOMERS_MAIL_PENDING:
      return {
        ...state,
        post: { ...state.postMail, status: 'pending', error: null },
      };

    default: {
      return state;
    }
  }
};

export const customersState = {
  getInfo: {
    value: (state: any) => state.requestData.customers.customers.getInfo.value,
    status: (state: any) =>
      state.requestData.customers.customers.getInfo.status,
    error: (state: any) => state.requestData.customers.customers.getInfo.error,
  },

  put: {
    value: (state: any) => state.requestData.customers.customers.put.value,
    status: (state: any) => state.requestData.customers.customers.put.status,
    error: (state: any) => state.requestData.customers.customers.put.error,
  },

  putDescriptions: {
    status: (state: any) =>
      state.requestData.customers.customers.putDescriptions.status,
    error: (state: any) =>
      state.requestData.customers.customers.putDescriptions.error,
  },

  get: {
    value: (state: any) => state.requestData.customers.customers.get.value,
    status: (state: any) => state.requestData.customers.customers.get.status,
    error: (state: any) => state.requestData.customers.customers.get.error,
  },

  postMail: {
    status: (state: any) =>
      state.requestData.customers.customers.postMail.status,
    error: (state: any) => state.requestData.customers.customers.postMail.error,
  },
};
