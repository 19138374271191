import React from 'react';

import './BudsTabs.scss';

interface Props {
  tabNumber: number;
  setTabNumber: any;
}

const BudsTabs: React.FC<Props> = ({
  tabNumber,
  setTabNumber,
}): JSX.Element => {
  return (
    <div className='buds__tabs'>
      <div
        className={tabNumber !== 0 ? 'bud__tab' : 'bud__tab bud__tab--active'}
        onClick={(event) => {
          setTabNumber(0);
        }}
      >
        Blüten
      </div>
      <div
        className={tabNumber !== 1 ? 'bud__tab' : 'bud__tab bud__tab--active'}
        onClick={(event) => {
          setTabNumber(1);
        }}
      >
        Dosierung
      </div>
      <div
        className={tabNumber !== 2 ? 'bud__tab' : 'bud__tab bud__tab--active'}
        onClick={(event) => {
          setTabNumber(2);
        }}
      >
        Abgabeformen
      </div>
    </div>
  );
};

export default BudsTabs;
