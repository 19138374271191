import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import * as actions from '../../store/actions';

import Header from '../../components/admin/Header';
import CustomerEdit from '../../components/admin/CustomerInfoEdit';
import { useState } from 'react';

const CustomerInfoEdit: React.FC = (): JSX.Element => {
  const [isShowContent, setShowContent] = useState(false);
  const params: any = useParams();
  const dispatch: any = useDispatch();

  const getCustomersID = useCallback(
    (id) =>
      dispatch(actions.request.adminActions.customers.getCustomersID.get(id)),
    [dispatch],
  );
  const getBudsAll = useCallback(
    () => dispatch(actions.request.adminActions.buds.getBudsAll.get()),
    [dispatch],
  );
  const getExtractsAll = useCallback(
    () => dispatch(actions.request.adminActions.extracts.getExtractsAll.get()),
    [dispatch],
  );
  const getDosagesAll = useCallback(
    () => dispatch(actions.request.adminActions.dosages.getDosagesAll.get()),
    [dispatch],
  );
  const getFormsAll = useCallback(
    () => dispatch(actions.request.adminActions.forms.getFormsAll.get()),
    [dispatch],
  );

  useEffect(() => {
    Promise.all([
      params.id ? getCustomersID(params.id) : null,
      getBudsAll(),
      getExtractsAll(),
      getDosagesAll(),
      getFormsAll(),
    ]).then(() => {
      setShowContent(true);
    });
  }, []);

  return (
    <>
      <Header />
      <CustomerEdit isShowContent={isShowContent} />
    </>
  );
};

export default CustomerInfoEdit;
