import * as api from '../../../../api';
import * as types from '../../../types/request';

export const logout = {
  get: function () {
    return (dispatch: any) => {
      dispatch(this.getPending());

      return api.authApi
        .logout()
        .then((response) => {
          if (response.status === 200) {
            localStorage.clear();
            window.location.reload();
            return response.json().then((json) => {
              dispatch(this.getSuccess(json));
            });
          } else {
            return response
              .json()
              .then((json) => dispatch(this.getFailed(json)));
          }
        })
        .catch((error) => {
          dispatch(this.getFailed(error));
        });
    };
  },

  getSuccess: (response: any) => ({
    type: types.authTypes.AUTH_LOGOUT_SUCCESS,
    payload: response,
  }),

  getFailed: (error: any) => ({
    type: types.authTypes.AUTH_LOGOUT_FAILED,
    payload: error,
  }),

  getPending: () => ({
    type: types.authTypes.AUTH_LOGOUT_PENDING,
  }),
};
