import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../../store/actions';
import * as reducers from '../../../store/reducers';
import { FormsType } from '../../../types';

import './BudsForm.scss';

import DeleteIcon from '../../../images/symbols/delete.svg';
import CloseIcon from '../../../images/symbols/close.svg';
import Pagination from '../Pagination';
import Modal from '../Modal';

const BudsForm: React.FC = (): JSX.Element => {
  const dispatch: any = useDispatch();
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(9);
  const [searchQuery, setSearchQuery] = useState('');

  const [removeFormPopupInfo, setRemoveFormPopupInfo] = useState({
    id: 0,
    item: '',
    isOpen: false,
  });

  const getForms = useCallback(
    (start, limit, search) =>
      dispatch(
        actions.request.adminActions.forms.getForms.get(start, limit, search),
      ),
    [dispatch],
  );

  const deleteFormsID = useCallback(
    (id) =>
      dispatch(actions.request.adminActions.forms.deleteFormsID.delete(id)),
    [dispatch],
  );

  const forms: FormsType[] = useSelector(
    reducers.request.adminReducers.formsState.get.value,
  );

  const formsTotal = useSelector(
    reducers.request.adminReducers.formsState.get.total,
  );

  useEffect(() => {
    getForms(start, limit, searchQuery);
  }, [start, limit, searchQuery]);

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSelect = (event: any) => {
    setLimit(event.target.value);
  };

  const handleInput = (event: any) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    setStart(currentPage * limit - limit);
  }, [currentPage]);

  const redirect = (event: any) => {
    if (event.target.tagName !== 'IMG') {
      const path = `/admin/buds/form/${
        event.target.closest('div').dataset.url
      }`;
      history.push(path);
    }
  };

  return (
    <>
      <section className='buds__form'>
        <div className='buds__form-filter'>
          <div className='buds__form-select-wrapper'>
            <label htmlFor='buds-number' className='buds__form-select-label'>
              Einträge anzeigen:
            </label>
            <select
              name='buds-number'
              id='buds-number'
              className='buds__form-select'
              value={limit}
              onChange={handleSelect}
            >
              <option value='6'>6</option>
              <option value='9'>9</option>
              <option value='12'>12</option>
              <option value='15'>15</option>
            </select>
          </div>
          <form action='#' className='buds__form-search'>
            <div className='buds__form-field'>
              <input
                type='text'
                placeholder='Suchen'
                className='buds__form-input'
                value={searchQuery}
                onChange={handleInput}
              />
            </div>
          </form>
        </div>
        {forms.map((form) => (
          <div
            className='buds__form-item'
            key={form.id}
            onClick={redirect}
            data-url={form.id}
          >
            <button
              className='button buds__form-item-delete'
              onClick={() =>
                setRemoveFormPopupInfo({
                  id: form.id,
                  item: form.name,
                  isOpen: true,
                })
              }
            >
              <img src={DeleteIcon} alt='delete item' />
            </button>
            <p className='buds__form-item-title'>{form.name}</p>
            <p className='buds__form-item-description'>{form.receipe}</p>
          </div>
        ))}
      </section>

      <Pagination
        itemsPerPage={limit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalItems={formsTotal}
        paginate={paginate}
      />

      {removeFormPopupInfo.isOpen && (
        <Modal>
          <div className='buds__table-modal'>
            <button
              className='buds__table-modal-close'
              onClick={() =>
                setRemoveFormPopupInfo({ id: 0, item: '', isOpen: false })
              }
            >
              <img src={CloseIcon} alt='close' />
            </button>
            <p className='buds__table-modal-title'>Abgabeformen löschen?</p>
            <p className='buds__table-modal-paragraph'>
              Sie haben vor, die Abgabeformen "{removeFormPopupInfo.item}" zu
              löschen.
            </p>
            <p className='buds__table-modal-paragraph'>
              Diese Änderungen kann nicht rückgängig gemacht werden.
            </p>
            <div className='buds__table-modal-buttons'>
              <button
                className='button buds__table-modal-button buds__table-modal-button--delete'
                onClick={() =>
                  deleteFormsID(removeFormPopupInfo.id).then(() => {
                    setRemoveFormPopupInfo({
                      id: 0,
                      item: '',
                      isOpen: false,
                    });
                    getForms(start, limit, searchQuery);
                  })
                }
              >
                Löschen
              </button>
              <button
                className='button buds__table-modal-button buds__table-modal-button--cancel'
                onClick={() =>
                  setRemoveFormPopupInfo({ id: 0, item: '', isOpen: false })
                }
              >
                Abbrechen
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default BudsForm;
