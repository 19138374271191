import React from 'react';
import { useHistory } from 'react-router-dom';

import './CustomersHead.scss';

const CustomersHead: React.FC = (): JSX.Element => {
  const history = useHistory();

  return (
    <div className='customers__head'>
      <h1 className='customers__title'>Kunden</h1>
      <button
        className='button button--green customers__create'
        onClick={() => {
          history.push('/admin/customers/new');
        }}
      >
        Kunden anlegen
      </button>
    </div>
  );
};

export default CustomersHead;
